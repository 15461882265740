import React, { useEffect, useState } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { UseLoggedUser } from "@States";

import {
    UseSetPopupMessageAction,
    UseLogUserInAction
} from "@StatesActions";


import UserModel from "@Models/Users/User";

import ApplicationUserTypeInterface from "@Models/Users/User/UserType/Interface";

import { UseUserTypeName } from "@Hooks/GUI/Common/Users";

import { ApplicationUserTypes } from "@GuiData/Common/Users";


import { UseRoute } from "@Hooks/Routes";


import {
    UseCommonDictionaryPhrasesSet,
    UseErrorMessage,
    UseFormFieldErrorMessage,
    UseFormsDictionaryPhrasesSet,
    UseNotAuthenticatedUserDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Redirect, NavLink } from "react-router-dom";



import {
    Col, Row
} from "react-bootstrap";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import {
    FormComponent,
    FormTextFieldComponent, FormSelectFieldComponent, FormSubmitFieldComponent
} from "@FormsElements";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


import SetNewPasswordMessageComponent from "@Components/Views/Pages/User/NotAuthenticated/Login/Messages/SetNewPassword";


import { isEmail } from "@Services/Utilities/Tools";


import "./index.scss";
import {API_PLAYER_LOGIN_PUBLIC_ENDPOINT_URL} from "@Services/API/Endpoints";
import ApplicationStore, {AuthenticationActionTypes} from "@Store";


interface LoginFormErrorsInterface {
    userType?: FormFieldValueErrorInterface | null;
    userPassword?: FormFieldValueErrorInterface | null;
    userEmail?: FormFieldValueErrorInterface | null;
}


export interface LoginPageComponentProps {}


export const LoginPageComponent: React.FC<LoginPageComponentProps> = () : JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();
    const UseLogUserIn = UseLogUserInAction();


    const DEFAULT_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT");

    const LOGIN_PAGE_PAGE_TITLE = UseNotAuthenticatedUserDictionaryPhrasesSet("LOGIN_PAGE_PAGE_TITLE");
    const LOGIN_PAGE_BUTTON_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("LOGIN_PAGE_BUTTON_TEXT");
    const LOGIN_PAGE_RESET_PASSWORD_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("LOGIN_PAGE_RESET_PASSWORD_TEXT");

    const CREATE_ACCOUNT_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CREATE_ACCOUNT_PHRASE_TEXT");

    const USER_TYPE_LABEL_TEXT = UseFormsDictionaryPhrasesSet("USER_TYPE");
    const EMAIL_ADDRESS_LABEL_TEXT = UseFormsDictionaryPhrasesSet("EMAIL_ADDRESS");
    const PASSWORD_LABEL_TEXT = UseFormsDictionaryPhrasesSet("PASSWORD");

    const EMAIL_ADDRESS_ERROR = UseFormFieldErrorMessage("EMAIL_ADDRESS");

    const INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT");


    const [FormErrors, setFormErrors] = useState<LoginFormErrorsInterface>({});


    const [loginRequestStatus, setLoginRequestStatus] = useState<"OK" | "SET_PASSWORD" | null>(null);

    useEffect(() => {
        return () => {
            setFormErrors({});
            setLoginRequestStatus(null);
        };
    }, []);


    const [userEmail, setUserEmail] = useState<string>("");
    const [userPassword, setUserPassword] = useState<string>("");

    useEffect(() => {
        return () => {
            setUserEmail("");
            setUserPassword("");
        };
    }, []);

    const changeUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = e.target.value;
        if (isEmail(emailValue) && FormErrors.userEmail) {
            setFormErrors({ ...FormErrors, userEmail: null });
        }
        setUserEmail(emailValue);
    };

    const changeUserPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValue = e.target.value;
        setUserPassword(passwordValue);
    };


    const [UserLoginType, setUserLoginType] = useState<ApplicationUserTypeInterface>(ApplicationUserTypes.PLAYER);

    const userLoginTypeOptions = Object.entries(ApplicationUserTypes).map(([typeNameID, Type]) => {
        return { value: typeNameID, label: UseUserTypeName(Type) };
    });

    const changeUserLoginType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const loginTypeNameID = e.target.value;
        // @ts-ignore
        setUserLoginType(ApplicationUserTypes[loginTypeNameID]);
    };


    const ProfileRoute = UseRoute("PROFILE");


    const ApplicationUser = UseLoggedUser();

    if (ApplicationUser && !ApplicationUser.userToken) {
        ApplicationStore.dispatch({
            type: AuthenticationActionTypes.LOG_USER_OUT,
        });
    }

    if (!!ApplicationUser && ApplicationUser.userToken) {
        return <Redirect to={ProfileRoute.routeURL} />;
    }


    if (loginRequestStatus === "SET_PASSWORD") {
        return <SetNewPasswordMessageComponent />;
    }


    const userLoginSubmitHandler = async () => {

        const formErrors: LoginFormErrorsInterface = {};

        if (!isEmail(userEmail)) formErrors.userEmail = EMAIL_ADDRESS_ERROR;

        setFormErrors(formErrors);

        if (Object.entries(formErrors).length) return;

        await userLoginRequestHandler();

    };


    const userLoginRequestHandler = async () => {

        const formData = new FormData();

        formData.append("player_email_address", userEmail);
        formData.append("player_password", userPassword);
        formData.append("player_login_type", UserLoginType.typeID.toString());

        try {

            const response = await _restApiRequest(
                API_PLAYER_LOGIN_PUBLIC_ENDPOINT_URL, formData, "POST"
            );

            if (response.status === 200) {

                const responseContent = await response.json();

                if (responseContent.code && responseContent.code === 1) {
                    return setLoginRequestStatus("SET_PASSWORD");
                }

                const { token: userToken } = responseContent;

                if (userToken) {

                    const User: UserModel = {
                        userToken,
                        loginDate: new Date(),
                        lastActivityTime: new Date(),
                    }

                    UseLogUserIn(User);

                } else {
                    UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_TEXT });
                }

            } else {

                switch (response.status) {
                    default:
                        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_TEXT });
                }

            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_TEXT });
        }

    };


    return (
        <PageContainerComponent id={`login-page`} cssClasses={`login-page login-page-component`}>

            <DefaultPageHeaderComponent
                headerText={LOGIN_PAGE_PAGE_TITLE}
            />

            <PageContentComponent cssClasses={"login-page__content"}>

                <FormComponent cssClasses={"user-form login-form"}>

                    <FormSelectFieldComponent
                        fieldOptions={userLoginTypeOptions}
                        fieldValue={UserLoginType.typeID}
                        fieldValueHandler={changeUserLoginType}
                        fieldLabel={USER_TYPE_LABEL_TEXT}
                        fieldDisabledStatus={true}
                    />

                    <FormTextFieldComponent
                        fieldType={"email"}
                        fieldValue={userEmail}
                        fieldValueHandler={changeUserEmail}
                        fieldPlaceholder={EMAIL_ADDRESS_LABEL_TEXT}
                        fieldError={FormErrors.userEmail}
                    />

                    <FormTextFieldComponent
                        fieldType={"password"}
                        fieldValue={userPassword}
                        fieldValueHandler={changeUserPassword}
                        fieldPlaceholder={PASSWORD_LABEL_TEXT}
                        fieldError={FormErrors.userPassword}
                    />

                    <FormSubmitFieldComponent
                        buttonText={LOGIN_PAGE_BUTTON_TEXT}
                        buttonClickHandler={userLoginSubmitHandler}
                    />

                    <Col xs={12}>
                        <Row style={{ justifyContent: "right" }}>
                            <NavLink to={"/logowanie/rejestracja"}>
                                <span>{CREATE_ACCOUNT_PHRASE_TEXT}</span>
                            </NavLink>
                        </Row>
                    </Col>

                    <Col xs={12}>
                        <Row style={{ justifyContent: "right" }}>
                            <NavLink to={"/logowanie/zresetuj-haslo"}>
                                <span>{LOGIN_PAGE_RESET_PASSWORD_TEXT}</span>
                            </NavLink>
                        </Row>
                    </Col>

                </FormComponent>

            </PageContentComponent>

        </PageContainerComponent>
        );

};


export default LoginPageComponent;
