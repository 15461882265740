import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_FILES_GROUPS_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    FilesGroupInterface
} from "@Interfaces/2.0";


export const UseFilesGroups = () : [FilesGroupInterface[] | null, ErrorMessageInterface | null] => {


    const [FilesGroups, setFilesGroups] = useState<FilesGroupInterface[] | null>(null);
    const [FilesGroupsError, setFilesGroupsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const getFilesGroups = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_FILES_GROUPS_PUBLIC_ENDPOINT_URL}?get_only_active=1&get_groups_files=1`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setFilesGroups(responseContent);
                } else {
                    setFilesGroupsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setFilesGroupsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getFilesGroups();

    }, []);


    useEffect(() => {
        return () => {
            setFilesGroups(null);
            setFilesGroupsError(null);
        }
    }, []);


    return [FilesGroups, FilesGroupsError];


};


export default UseFilesGroups;
