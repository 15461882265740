import React, { useEffect, useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import PlayerBestScoresHeaderSelectedLeagueComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Header/SelectedLeague";
import PlayerBestScoresLeaguesListComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Header/LeaguesList";


import {
    PlayerBestScoresInterface,
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerBestScoresHeaderComponentProps {
    Leagues: PlayerCareerStatisticsLeagueInterface[];
    SelectedLeague: PlayerCareerStatisticsLeagueInterface | null;
    selectedLeagueID: number;
    selectedLeagueIDHandler: (leagueID: number) => void;
}

export const PlayerBestScoresHeaderComponent: React.FC<PlayerBestScoresHeaderComponentProps> = ({
    Leagues, SelectedLeague,
    selectedLeagueID, selectedLeagueIDHandler,
}) : JSX.Element | null => {


    const BEST_SCORES_IN_LEAGUE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("BEST_SCORES_IN_LEAGUE_PHRASE_TEXT");


    const [showLeaguesList, setShowLeaguesList] = useState(false);

    useEffect(() => {
        return () => {
            setShowLeaguesList(false);
        };
    }, []);


    const handleSelectedLeagueID = (leagueID: number) => {
        setShowLeaguesList(false);
        selectedLeagueIDHandler(leagueID);
    };


    const [LeaguesList, setLeaguesList] = useState<PlayerCareerStatisticsLeagueInterface[]>([]);

    useEffect(() => {

        if (!Leagues) {
            return;
        }

        const LeagueList = Leagues.filter(
            (League) => League.id !== selectedLeagueID
        );

        setLeaguesList(LeagueList);

    }, [Leagues, selectedLeagueID]);


    useEffect(() => {
        const leaguesListStatusObserver = (e: MouseEvent) => {

            let targetParent = (e.target as Element).parentElement;

            if (targetParent?.tagName === "svg") {
                targetParent = targetParent.parentElement;
            }

            if (targetParent && targetParent.className.includes("selected-league")) {
                return;
            } else {
                setShowLeaguesList(false);
            }
        }

        document.addEventListener("click", leaguesListStatusObserver);

        return () => {
            document.removeEventListener("click", leaguesListStatusObserver);
        };

    }, []);


    if (!Leagues?.length) {
        return null;
    }


    return (
        <Col xs={12} lg={"auto"} className={"player-details__details-data__statistics-data__content__best-scores__header"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__header__content"}>

                <Col xs={12} lg={"auto"} className={"player-details__details-data__statistics-data__content__best-scores__header__label"}>
                    <span>{BEST_SCORES_IN_LEAGUE_PHRASE_TEXT}:</span>
                </Col>

                <Col xs={12} lg={"auto"} className={"player-details__details-data__statistics-data__content__best-scores__header__leagues"}>
                    <Row className={"player-details__details-data__statistics-data__content__best-scores__header__leagues__content"}>

                        <PlayerBestScoresHeaderSelectedLeagueComponent
                            SelectedLeague={SelectedLeague}
                            leaguesNumber={LeaguesList.length}
                            showLeaguesListStatus={showLeaguesList}
                            showLeaguesListStatusHandler={setShowLeaguesList}
                        />

                        {showLeaguesList &&
                        <PlayerBestScoresLeaguesListComponent
                            Leagues={LeaguesList}
                            selectedLeagueIDHandler={handleSelectedLeagueID}
                        />
                        }

                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerBestScoresHeaderComponent;
