import { useEffect, useState } from "react";


import {
    API_PLAYER_ID_BY_SLUG_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _restApiRequest from "@Services/HTTP/Requests/Request";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


export const UsePlayerIdBySlug = (playerSlug: string | undefined) : [number | null, ErrorMessageInterface | null] => {


    const [playerID, setPlayerID] = useState<number | null>(null);
    const [playerIDError, setPlayerIDError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!playerSlug) {
            return;
        }

        const getPlayerIdBySLug = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_PLAYER_ID_BY_SLUG_PUBLIC_ENDPOINT_URL}?player_slug=${playerSlug}`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setPlayerID(responseContent);
                } else {
                    setPlayerIDError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setPlayerIDError({ errorCode: 500, errorMessage: "" });
            }

        };

        getPlayerIdBySLug();

    }, [playerSlug]);


    useEffect(() => {
        return () => {
            setPlayerID(null);
            setPlayerIDError(null);
        };
    }, []);


    return [playerID, playerIDError];

};


export default UsePlayerIdBySlug;
