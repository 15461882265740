import React, { useEffect, useState } from "react";


import {
    API_PLAYER_SEND_EMAIL_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";

import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Form, Row
} from "react-bootstrap";

import {
    AiFillDelete,
    AiFillEdit,
    AiFillSave,
    AiOutlineCheck,
    AiOutlineMail,
    BiMessageSquareX,
    ImCancelCircle
} from "react-icons/all";


import {
    NO_AVATAR_IMAGE
} from "@Images";


import {
    TeamPlayerInterface
} from "@Interfaces/Api";


import UseTeamPlayerStatus from "@ApiInterfaces/Teams/Team/TeamPlayer/TeamPlayerStatus/Hook";


import "./index.scss";


export interface TeamPlayersListTeamPlayersPlayerComponentProps {
    TeamPlayer: TeamPlayerInterface;
    updateTeamPlayersTrigger: (status: boolean) => void;
}

export const TeamPlayersListTeamPlayersPlayerComponent: React.FC<TeamPlayersListTeamPlayersPlayerComponentProps> = ({
    TeamPlayer, updateTeamPlayersTrigger
}) : JSX.Element | null => {


    const UsePopupMessage = UseSetPopupMessageAction();


    const DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE = UseCommonDictionaryPhrasesSet("DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE");
    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const TO_CONFIRM_CLICK_AGAIN_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT");

    const ACTIVATED_WORD_TEXT = UseCommonDictionaryPhrasesSet("ACTIVATED_WORD_TEXT");
    const FORMALS_AGREEMENTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("FORMALS_AGREEMENTS_PHRASE_TEXT");

    const EDIT_WORD_TEXT = UseCommonDictionaryPhrasesSet("EDIT_WORD_TEXT");
    const SAVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("SAVE_WORD_TEXT");
    const ABORT_WORD_TEXT = UseCommonDictionaryPhrasesSet("ABORT_WORD");
    const REMOVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("REMOVE_WORD_TEXT");

    // const S = UseCommonDictionaryPhrasesSet("");


    const PlayerRoute = UseRoute("PLAYERS");


    const [editPlayerDataStatus, setEditPlayerDataStatus] = useState(false);
    const [removePlayerFromTeamStatus, setRemovePlayerFromTeamStatus] = useState(false);
    const [sendEmailToPlayer, setSendEmailToPlayer] = useState(false);

    useEffect(() => {
        return () => {
            setEditPlayerDataStatus(false);
            setRemovePlayerFromTeamStatus(false);
            setSendEmailToPlayer(false);
        };
    }, []);


    const [teamPlayerEditableShirtNumber, setTeamPlayerEditableShirtNumber] = useState("")

    useEffect(() => {

        if (!TeamPlayer) {
            return;
        }

        const { team_player_player_shirt_number: teamPlayerShirtNumber } = TeamPlayer;

        if (!teamPlayerShirtNumber) {
            return;
        }

        setTeamPlayerEditableShirtNumber(teamPlayerShirtNumber.toString());

    }, [TeamPlayer]);

    const changeTeamPlayerEditableShirtNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedTeamPlayerShirtNumber = e.target.value;
        setTeamPlayerEditableShirtNumber(updatedTeamPlayerShirtNumber);
    }

    useEffect(() => {
        return () => {
            setTeamPlayerEditableShirtNumber("");
        };
    }, []);


    if (!TeamPlayer) {
        return null;
    }

    // @ts-ignore
    const {
        url: teamPlayerApiURL,
        team_player_status: teamPlayerStatus,
        team_player_data: {
            player_id: playerMainID,
            player_slug: teamPlayerSlug,
            player_profile_image: teamPlayerAvatar,
            // @ts-ignore
            player_name: playerName,
            player_first_name: teamPlayerFirstName, player_last_name: teamPlayerLastName,
            player_activated_status: teamPlayerActivatedStatus,
            player_privacy_acceptation_date: teamPlayerRegulationAcceptedStatus, player_regulations_acceptation_date: teamPlayerPrivacyAcceptedStatus
        }
    } = TeamPlayer;

    const teamPlayerTeamStatus = UseTeamPlayerStatus(teamPlayerStatus);


    const teamPlayerFormalsAcceptedStatus = !!teamPlayerRegulationAcceptedStatus && teamPlayerPrivacyAcceptedStatus;


    const removePlayerFromTeamHandler = () => {
        if (removePlayerFromTeamStatus) {
            updateTeamPlayerRequest("REMOVE");
        } else {
            setRemovePlayerFromTeamStatus(true);
            UsePopupMessage({ type: "DEFAULT", text: TO_CONFIRM_CLICK_AGAIN_MESSAGE_TEXT });
        }
    };


    const updateTeamPlayerRequest = async (updateType: "UPDATE" | "REMOVE") => {

        const teamPlayerShirtNumber = teamPlayerEditableShirtNumber.trim();

        const formData = new FormData();

        if (updateType === "UPDATE") {
            formData.append("team_player_player_shirt_number", teamPlayerShirtNumber)
        } else if (updateType === "REMOVE") {
            formData.append("team_player_status", "4");
        }

        try {

            const response = await _restApiRequest(
                teamPlayerApiURL, formData, "PATCH"
            );

            if (response.ok) {
                clearPlayerStateAfterUpdate(updateType);
            } else {
                UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
            }

        } catch (e) {
            UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
        }

    };

    const clearPlayerStateAfterUpdate = (updateType: "UPDATE" | "REMOVE") => {

        setEditPlayerDataStatus(false);
        setRemovePlayerFromTeamStatus(false);
        setSendEmailToPlayer(false);

        if (updateType === "REMOVE") {
            updateTeamPlayersTrigger(true);
            UsePopupMessage({ type: "SUCCESS", text: DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE });
        }

    };


    const sendRemindingEmailToPlayer = async (emailType: "1" | "2") => {

        if (!sendEmailToPlayer) {
            UsePopupMessage({ type: "SUCCESS", text: TO_CONFIRM_CLICK_AGAIN_MESSAGE_TEXT });
            return setSendEmailToPlayer(true);
        }

        const API_URL = API_PLAYER_SEND_EMAIL_ENDPOINT_URL.replace("{PLAYER_ID}", playerMainID.toString());

        const formData = new FormData();
        formData.append("email_type", emailType);

        try {
            const response = await _restApiRequest(API_URL, formData, "POST");
            if (response.ok) {
                UsePopupMessage({ type: "SUCCESS", text: "Wiadomość została wysłana" });
                return setSendEmailToPlayer(false);
            } else {
                UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
            }
        } catch (e) {
            UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
        }

    };


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__players-list__players__player"}>
            <Row className={"player-profile__captain-zone__team__players__players-list__players__player__content"}>

                <Col xs={4} lg={1} className={"player-profile__captain-zone__team__players__players-list__players__player__player-profile-image"}>
                    <NavLink to={`${PlayerRoute.routeURL}/${teamPlayerSlug}`} title={PlayerRoute.routeTitle}>
                        <img src={teamPlayerAvatar || NO_AVATAR_IMAGE} alt={``} />
                    </NavLink>
                </Col>

                <Col xs={8} lg={3} className={"player-profile__captain-zone__team__players__players-list__players__player__player-name"}>
                    <NavLink to={`${PlayerRoute.routeURL}/${teamPlayerSlug}`} title={PlayerRoute.routeTitle}>
                        <span>{playerName}</span>
                    </NavLink>
                </Col>

                <Col xs={6} lg={2} className={"player-profile__captain-zone__team__players__players-list__players__player__team-status" + (" status-" + teamPlayerStatus)}>
                    <span>{teamPlayerTeamStatus}</span>
                </Col>

                <Col xs={6} lg={1} className={"player-profile__captain-zone__team__players__players-list__players__player__shirt-number"}>
                    <Form.Control
                        type={"number"} min={0} max={99}
                        value={teamPlayerEditableShirtNumber}
                        onChange={changeTeamPlayerEditableShirtNumber}
                        disabled={!editPlayerDataStatus}
                    />
                </Col>

                <Col xs={10} lg={2} className={"player-profile__captain-zone__team__players__players-list__players__player__system-status"}>
                    <span className={"player-profile__captain-zone__team__players__players-list__players__player__system-status__activated-status " + (teamPlayerActivatedStatus ? "activated" : "not-activated")}>
                        <span>{ACTIVATED_WORD_TEXT}</span>
                        {teamPlayerActivatedStatus ? <AiOutlineCheck /> : <BiMessageSquareX />}
                    </span>
                    <span className={"player-profile__captain-zone__team__players__players-list__players__player__system-status__formals-accepted-status " + (teamPlayerFormalsAcceptedStatus ? "accepted" : "not-accepted")}>
                        <span>{FORMALS_AGREEMENTS_PHRASE_TEXT}</span>
                        {teamPlayerFormalsAcceptedStatus ? <AiOutlineCheck /> : <BiMessageSquareX />}
                    </span>
                </Col>

                <Col xs={2} lg={1} className={"player-profile__captain-zone__team__players__players-list__players__player__send-message-to-player"}>
                    {(!teamPlayerActivatedStatus || !teamPlayerFormalsAcceptedStatus) &&
                    <span onClick={() => sendRemindingEmailToPlayer(teamPlayerActivatedStatus ? "2" : "1")} className={"send-email action-item-button" + (sendEmailToPlayer ? " active" : "")} title={"Wyślij wiadomość przypominającą"}>
                        <AiOutlineMail />
                    </span>
                    }
                </Col>

                {teamPlayerStatus === 1 &&
                <Col xs={2} lg={1} className={"player-profile__captain-zone__team__players__players-list__players__player__edit-player"}>
                    {!editPlayerDataStatus &&
                    <span onClick={() => setEditPlayerDataStatus(true)} className={"action-item-button edit-player-data"} title={EDIT_WORD_TEXT}>
                        <AiFillEdit />
                    </span>
                    }
                    {editPlayerDataStatus &&
                    <span onClick={() => updateTeamPlayerRequest("UPDATE")} className={"action-item-button save-player-data"} title={SAVE_WORD_TEXT}>
                        <AiFillSave />
                    </span>
                    }
                    {editPlayerDataStatus &&
                    <span onClick={() => setEditPlayerDataStatus(false)} className={"action-item-button cancel-edit-player-data"} title={ABORT_WORD_TEXT}>
                        <ImCancelCircle/>
                    </span>
                    }
                </Col>
                }

                {teamPlayerStatus === 1 &&
                <Col xs={2} lg={1} className={"player-profile__captain-zone__team__players__players-list__players__player__remove-player"} title={REMOVE_WORD_TEXT}>
                    <span onClick={() => removePlayerFromTeamHandler()} className={"action-item-button remove-player" + (removePlayerFromTeamStatus ? " active" : "")}>
                        <AiFillDelete />
                    </span>
                    {removePlayerFromTeamStatus &&
                    <span onClick={() => setRemovePlayerFromTeamStatus(false)} className={"action-item-button cancel-remove-player"} title={ABORT_WORD_TEXT}>
                        <ImCancelCircle/>
                    </span>
                    }
                </Col>
                }

            </Row>
        </Col>
    );


};


export default TeamPlayersListTeamPlayersPlayerComponent;
