import {
    PlayerGlobalStatusInterface
} from "@Services/Consts";


export const PlayerGlobalStatusNames: PlayerGlobalStatusInterface = {
    PL: {
        1: "Aktywny",
        2: "Zawieszony",
        3: "Wykluczony"
    },
    EN: {
        1: "Active",
        2: "Suspended",
        3: "Excluded"
    }
};


export default PlayerGlobalStatusNames;



