import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_SEASONS_WITH_LEAGUES_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


export const UseSeasonsWithLeagues = (
    onlyActiveSeason = false
) : [SeasonWithLeaguesShortInformationType[] | null, ErrorMessageInterface | null] => {


    const [SeasonsWithLeagues, setSeasonsWithLeagues] = useState<SeasonWithLeaguesShortInformationType[] | null>(null);
    const [SeasonsWithLeaguesError, setSeasonsWithLeaguesError] = useState<ErrorMessageInterface | null>(null);


    let API_ENDPOINT_URL = API_SEASONS_WITH_LEAGUES_PUBLIC_ENDPOINT_URL;

    if (onlyActiveSeason) {
        API_ENDPOINT_URL += "?only_active_season=1";
    }


    useEffect(() => {

        const getSeasonsWithLeagues = async () => {

            try {

                const response = await _restApiRequest(API_ENDPOINT_URL);

                if (response.ok) {
                    const responseContent = await response.json();
                    setSeasonsWithLeagues(responseContent);
                } else {
                    setSeasonsWithLeaguesError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setSeasonsWithLeaguesError({ errorCode: 500, errorMessage: "" });
            }

        };

        getSeasonsWithLeagues();

    }, []);


    useEffect(() => {
        return () => {
            setSeasonsWithLeagues(null);
            setSeasonsWithLeaguesError(null);
        }
    }, []);


    return [SeasonsWithLeagues, SeasonsWithLeaguesError];

};


export default UseSeasonsWithLeagues;
