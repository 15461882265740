import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayerAvatarComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player/Avatar";
import PlayerInformationComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player/Information";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerDataComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerDataComponent: React.FC<PlayerDataComponentProps> = ({
    PlayerDescriptionData,
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_active_season_team_data: PlayerTeam
    } = PlayerDescriptionData;


    return (
        <Col xs={12} lg={{ span: 8, offset: PlayerTeam ? 0 : 3 }} className={"player-main-data"}>
            <Row className={"main-data-content"}>

                <PlayerAvatarComponent
                    PlayerDescriptionData={PlayerDescriptionData}
                />

                <PlayerInformationComponent
                    PlayerDescriptionData={PlayerDescriptionData}
                />

            </Row>
        </Col>
    );


};


export default PlayerDataComponent;
