import React from "react";


import {
    Col
} from "react-bootstrap";


import {
    LeagueShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersLeaguesLeagueComponentProps {
    League: LeagueShortInformationType;
    selectedLeagueID: number;
    selectedLeagueIDHandler: (leagueID: number) => void;
}


export const TeamsAndPlayersLeaguesLeagueComponent: React.FC<TeamsAndPlayersLeaguesLeagueComponentProps> = ({
    League,
    selectedLeagueID, selectedLeagueIDHandler,
}) : JSX.Element | null => {


    if (!League) {
        return null;
    }


    const {
        id: leagueID, league_name: leagueName
    } = League;


    return (
        <Col
            xs={12} lg={"auto"}
            onClick={() => selectedLeagueIDHandler(leagueID === selectedLeagueID ? 0 : leagueID)}
            className={"teams-and-players__content__seasons-and-leagues__leagues__list__league" + (leagueID === selectedLeagueID ? " selected" : "")}
        >
            <span>{leagueName}</span>
        </Col>
    )

};


export default TeamsAndPlayersLeaguesLeagueComponent;
