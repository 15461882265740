import React from "react";


import _ from "lodash";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import {
    TeamLeaguePlayerBasicInformationType
} from "@Interfaces/2.0";


import TeamsAndPlayersPlayersListLetterComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/List/Letter";


import {
    sortLeaguePlayersListByPlayerName
} from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/List/_Tools";


import "./index.scss";


export interface TeamsAndPlayersPlayersListComponentProps {
    LeaguePlayers: TeamLeaguePlayerBasicInformationType[];
    filtersActiveStatus: boolean;
}


export const TeamsAndPlayersPlayersListComponent: React.FC<TeamsAndPlayersPlayersListComponentProps> = ({
    LeaguePlayers, filtersActiveStatus
}) : JSX.Element | null => {


    const NO_LEAGUE_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_PLAYERS_PHRASE_TEXT");
    const NO_LEAGUE_PLAYERS_MATCHING_CRITERIA_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_PLAYERS_MATCHING_CRITERIA_PHRASE_TEXT");


    if (!LeaguePlayers.length) {
        return <MessageComponent messageText={filtersActiveStatus ? NO_LEAGUE_PLAYERS_MATCHING_CRITERIA_PHRASE_TEXT : NO_LEAGUE_PLAYERS_PHRASE_TEXT} cssClasses={"margin-top-20"} />;
    }


    const SortedPlayers = sortLeaguePlayersListByPlayerName(LeaguePlayers);


    const PlayersList = Object.entries(SortedPlayers).map(([letter, Players]) =>
        <TeamsAndPlayersPlayersListLetterComponent
            key={letter}
            letter={letter}
            Players={Players}
        />
    );


    const SortedPlayersList = _.sortBy(PlayersList, ["key"]);


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__players__list"}>
            <Row className={"teams-and-players__content__teams-and-players__players__list__content"}>
                {SortedPlayersList}
            </Row>
        </Col>
    )

};


export default TeamsAndPlayersPlayersListComponent;
