import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    FormTextFieldComponent
} from "@FormsElements";


import "./index.scss";


export interface TeamsAndPlayersTeamsSearchBarComponentProps {
    searchLeagueTeamsQuery: string;
    searchLeagueTeamsQueryHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TeamsAndPlayersTeamsSearchBarComponent: React.FC<TeamsAndPlayersTeamsSearchBarComponentProps> = ({
    searchLeagueTeamsQuery, searchLeagueTeamsQueryHandler,
}) => {

    const SEARCH_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEARCH_WORD_TEXT");

    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__teams__search-bar"}>
            <Row className={"teams-and-players__content__teams-and-players__teams__search-bar__content"}>

                <Col xs={12} lg={1} className={"teams-and-players__content__teams-and-players__teams__search-bar__label"}>
                    <span>{SEARCH_WORD_TEXT}</span>
                </Col>

                <Col xs={12} lg={3} className={"teams-and-players__content__teams-and-players__teams__search-bar__search-field"}>
                    <FormTextFieldComponent
                        fieldValue={searchLeagueTeamsQuery}
                        fieldValueHandler={searchLeagueTeamsQueryHandler}
                        fieldPlaceholder={`${SEARCH_WORD_TEXT}...`}
                    />
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersTeamsSearchBarComponent;
