import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface PlayerStatisticsTableLeaguesHeaderComponentProps {
    statisticsType: "M" | "S";
}


export const PlayerStatisticsTableLeaguesHeaderComponent: React.FC<PlayerStatisticsTableLeaguesHeaderComponentProps> = ({
    statisticsType
}) : JSX.Element | null => {


    const STATISTICS_SUMS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_SUMS_PHRASE_TEXT");
    const STATISTICS_MEDIUMS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MEDIUMS_PHRASE_TEXT");


    if (!statisticsType) {
        return null;
    }


    let STATISTICS_TABLE_HEADER_TEXT;

    if (statisticsType === "S") {
        STATISTICS_TABLE_HEADER_TEXT = STATISTICS_SUMS_PHRASE_TEXT;
    } else if (statisticsType === "M") {
        STATISTICS_TABLE_HEADER_TEXT = STATISTICS_MEDIUMS_PHRASE_TEXT;
    }


    if (!STATISTICS_TABLE_HEADER_TEXT) {
        return null;
    }

    return (
        <Col xs={12} lg={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__header"}>
            <span>{STATISTICS_TABLE_HEADER_TEXT}</span>
        </Col>
    );

};


export default PlayerStatisticsTableLeaguesHeaderComponent;
