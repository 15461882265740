import React from "react";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface PlayerPlayedGamesHeaderLeaguesListLeagueComponentProps {
    League: PlayerCareerStatisticsLeagueInterface;
    selectedLeagueIDHandler: (leagueID: number) => void;
}

export const PlayerPlayedGamesHeaderLeaguesListLeagueComponent: React.FC<PlayerPlayedGamesHeaderLeaguesListLeagueComponentProps> = ({
    League, selectedLeagueIDHandler
}) => {


    if (!League) {
        return null;
    }


    const {
        league_name: leagueName, league_season_name: leagueSeasonName
    } = League;


    return (
        <Col xs={12} onClick={() => selectedLeagueIDHandler(League.id)} className={"player-details__details-data__statistics-data__content__played-games__header__leagues__list__league"}>
            <span>{leagueName} ({leagueSeasonName})</span>
        </Col>
    );

};


export default PlayerPlayedGamesHeaderLeaguesListLeagueComponent;
