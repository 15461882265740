import React from "react";


import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ColumnProps from "@BootstrapInterfaces/Column";

import {
    UseSocials
} from "@Hooks/Api";

import SocialsItemComponent from "@Components/Views/Parts/Header/Socials/Element";


import "./index.scss";


export interface HeaderSocialsComponentProps extends ColumnProps {}


export const HeaderSocialsComponent: React.FC<HeaderSocialsComponentProps> = ({
    xs = 12, lg = 3,
    as = "aside"
}) : JSX.Element | null => {


    const [Socials, SocialsError] = UseSocials();

    if (SocialsError) {
        return null;
    }

    if (!Socials) {
        return <SingleContentLoaderComponent loaderColor={"#FFF"} xs={xs} lg={lg} />;
    }

    if (!Socials?.length) {
        return null;
    }


    const SocialsLinksList = Socials.map((Social, i) =>
        <SocialsItemComponent key={i} Social={Social} />
    );


    return (
        <Col as={as} xs={xs} lg={lg} className={"header-socials-component"}>
            <Row as={"ul"} className={"socials-links-list"}>
                {SocialsLinksList}
            </Row>
        </Col>
    );

};


export default HeaderSocialsComponent;
