import React, { useEffect, useState } from "react";


import { UseSetPopupMessageAction } from "@StatesActions";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { Col, Row } from "react-bootstrap";


import {
    FormComponent,
    FormTextFieldComponent, FormTextareaFieldComponent
} from "@FormsElements";


import {
    UseErrorMessage, UseSuccessMessage,
    UseAuthenticatedUserDictionaryPhrasesSet, UseFormsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {LeaguePlayerInterface, LeaguePlayerPositionType} from "@Interfaces/Api";


import { PlayerDescriptionFormErrorsDefaults } from "@Pages/User/Authenticated/Profile/Player/Content/Description/Consts";
import {LoggedPlayerProfileDataInterface} from "@Interfaces/2.0";
import {API_LOGGED_PLAYER_UPDATE_PROFILE_DATA_ENDPOINT_URL} from "@Services/API/Endpoints";
import {UseLoggedUserToken} from "@States";


export interface PlayerProfileDescriptionComponentProps {
    Player: LoggedPlayerProfileDataInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const PlayerProfileDescriptionComponent: React.FC<PlayerProfileDescriptionComponentProps> = ({
    Player, updatePlayerTrigger
}) : JSX.Element | null => {


    const playerApiToken = UseLoggedUserToken();


    const ADDITIONAL_DATA_PHRASE_TEXT = UseAuthenticatedUserDictionaryPhrasesSet("ADDITIONAL_DATA_PHRASE_TEXT");

    const NATIONALITY_FIELD_LABEL = UseFormsDictionaryPhrasesSet("NATIONALITY");
    const DESCRIPTION_FIELD_LABEL = UseFormsDictionaryPhrasesSet("DESCRIPTION");
    const BIRTHDATE_FIELD_LABEL = UseFormsDictionaryPhrasesSet("BIRTHDATE");
    const NUMBER_FIELD_LABEL = UseFormsDictionaryPhrasesSet("NUMBER");
    const POSITION_FIELD_LABEL = UseFormsDictionaryPhrasesSet("POSITION");
    const WEIGHT_FIELD_LABEL = UseFormsDictionaryPhrasesSet("WEIGHT");
    const HEIGHT_FIELD_LABEL = UseFormsDictionaryPhrasesSet("HEIGHT");


    const DEFAULT_ERROR_MESSAGE = UseErrorMessage("DEFAULT");
    const DEFAULT_UPDATE_SUCCESS_MESSAGE = UseSuccessMessage("DEFAULT_UPDATE_DATA_RESPONSE");


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const [FormErrors, setFormErrors] = useState(PlayerDescriptionFormErrorsDefaults);

    useEffect(() => {
        return () => {
            setFormErrors(PlayerDescriptionFormErrorsDefaults);
        };
    }, []);


    const [playerDescription, setPlayerDescription] = useState("");
    const [playerNationality, setPlayerNationality] = useState("");

    const [playerBirthDate, setPlayerBirthDate] = useState<string>("");
    const [playerNumber, setPlayerNumber] = useState("");
    const [playerPosition, setPlayerPosition] = useState<LeaguePlayerPositionType | "">("");

    const [playerWeight, setPlayerWeight] = useState<string>("");
    const [playerHeight, setPlayerHeight] = useState<string>("");


    useEffect(() => {

        if (!Player) return;

        const {
            player_nationality: playerNationality,
            player_description: playerDescription, player_birthdate: playerBirthdate,
            player_weight: playerWeight, player_height: playerHeight, player_number: playerNumber,
            player_position: playerPosition,
        } = Player;

        if (playerNationality) setPlayerNationality(playerNationality);
        if (playerDescription) setPlayerDescription(playerDescription);
        if (playerBirthdate) setPlayerBirthDate(playerBirthdate.toString());
        if (playerNumber || playerNumber === 0) setPlayerNumber(playerNumber.toString());
        if (playerPosition || playerPosition === 0) setPlayerPosition(playerPosition);
        if (playerWeight) setPlayerWeight(playerWeight.toString());
        if (playerHeight) setPlayerHeight(playerHeight.toString());

        return () => {
            setPlayerNationality("");
            setPlayerDescription("");
            setPlayerBirthDate("");
            setPlayerNumber("");
            setPlayerWeight("");
            setPlayerHeight("");
        };

    }, []);


    const changePlayerNationality = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPlayerNationality(value);
    };

    const changePlayerDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setPlayerDescription(value);
    };

    const changePlayerBirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (new Date() < new Date(value)) return;
        setPlayerBirthDate(value.toString());
    };

    const changePlayerNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isNaN(+value) || +value < 0 || +value > 999) return;
        setPlayerNumber(value);
    };

    const changePlayerPosition = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (![0, 1, 2, 3, 4, 5].includes(+value)) return;
        // @ts-ignore
        setPlayerPosition(+value);
    };

    const changePlayerWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if (!value || value < 0 || value > 200) return setPlayerWeight("0");
        setPlayerWeight(value.toString());
    };

    const changePlayerHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if (!value || value < 0 || value > 250) return setPlayerHeight("0");
        setPlayerHeight(value.toString());
    };


    if (!Player) return null;


    const saveFieldValueHandler = async (fieldName: string, fieldValue: string, fieldEditableStatusHandler: (status: boolean) => void) => {

        let error = false;

        switch (fieldName) {

            case "player_nationality":
                break;

            case "player_description":
                break;

            case "player_birth_date":
                break;

            case "player_number":
                break;

            case "player_weight":
                break;

            case "player_height":
                break;
        }

        if (error) return;

        if (await saveFieldValueRequestHandler(fieldName, fieldValue)) {
            UseSetPopupMessage({ type: "SUCCESS", text: DEFAULT_UPDATE_SUCCESS_MESSAGE });
            updatePlayerTrigger(true);
            return fieldEditableStatusHandler(false);
        }

        return UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });

    };


    const saveFieldValueRequestHandler = async (fieldName: string, fieldValue: string) => {

        if (!playerApiToken) {
            return false;
        }

        const formData = new FormData();
        formData.append(fieldName, fieldValue);

        try {

            const response = await _restApiRequest(
                API_LOGGED_PLAYER_UPDATE_PROFILE_DATA_ENDPOINT_URL,
                formData,
                "PATCH",
                playerApiToken
            );

            return response.ok;

        } catch (e) {
            return false;
        }

    };


    return (
        <Col xs={12} className={"user-profile-page-data section-data"}>
            <Row className={"user-profile-page-data-content section-data-content"}>

                <Col as={"h3"} xs={12} className={"formals-data-header section-data-header"}>
                    <span>{ADDITIONAL_DATA_PHRASE_TEXT}</span>
                </Col>

                <FormComponent xs={5} lg={6} cssClasses={"user-form strong-text-values"}>

                    <FormTextFieldComponent
                        fieldName={"player_nationality"}
                        fieldValue={playerNationality}
                        fieldValueHandler={changePlayerNationality}
                        fieldLabel={NATIONALITY_FIELD_LABEL}
                        fieldError={FormErrors.playerNationality}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                    <FormTextareaFieldComponent
                        fieldName={"player_description"}
                        fieldValue={playerDescription}
                        fieldValueHandler={changePlayerDescription}
                        fieldLabel={DESCRIPTION_FIELD_LABEL}
                        fieldError={FormErrors.playerDescription}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                </FormComponent>

                <FormComponent xs={{ span: 6, offset: 1 }} lg={{span: 4, offset: 2}} cssClasses={"user-form strong-text-values"}>

                    <FormTextFieldComponent
                        fieldType={"date"}
                        fieldName={"player_birthdate"}
                        fieldValue={playerBirthDate}
                        fieldValueHandler={changePlayerBirthdate}
                        fieldLabel={BIRTHDATE_FIELD_LABEL}
                        fieldError={FormErrors.playerBirthDate}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldName={"player_number"}
                        fieldValue={playerNumber}
                        fieldValueHandler={changePlayerNumber}
                        fieldLabel={NUMBER_FIELD_LABEL}
                        fieldError={FormErrors.playerNumber}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldName={"player_position"}
                        fieldValue={playerPosition.toString()}
                        fieldValueHandler={changePlayerPosition}
                        fieldLabel={POSITION_FIELD_LABEL}
                        fieldError={FormErrors.playerPosition}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldName={"player_weight"}
                        fieldValue={playerWeight} fieldValueHandler={changePlayerWeight}
                        fieldLabel={WEIGHT_FIELD_LABEL}
                        fieldError={FormErrors.playerWeight}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldName={"player_height"}
                        fieldValue={playerHeight} fieldValueHandler={changePlayerHeight}
                        fieldLabel={HEIGHT_FIELD_LABEL}
                        fieldError={FormErrors.playerHeight}
                        fieldEditableStatus={false}
                        fieldSaveDataHandler={saveFieldValueHandler}
                    />

                </FormComponent>

            </Row>
        </Col>
    );

};


export default PlayerProfileDescriptionComponent;
