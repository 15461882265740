import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import {
    NO_AVATAR_IMAGE
} from "@Images";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerAvatarComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerAvatarComponent: React.FC<PlayerAvatarComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_name: playerName, player_photo: playerPhoto
    } = PlayerDescriptionData;


    return (
        <Col xs={4} lg={3} className={"player-avatar"}>
            <Row as={"picture"} className={"player-avatar-picture"}>
                <img src={playerPhoto || NO_AVATAR_IMAGE} alt={playerName} />
            </Row>
        </Col>
    );

};


export default PlayerAvatarComponent;
