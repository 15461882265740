import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import ErrorMessageComponent from "@MessagesElements/Message/Error";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import {
    UsePlayerDescriptionInformation
} from "@Hooks/Api/Requests";



import PlayerMainDataComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData";
import PlayerInformationDataComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/InformationData";


export interface PlayerDescriptionComponentProps {
    playerID: number;
}

export const PlayerDescriptionComponent: React.FC<PlayerDescriptionComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const [PlayerDescriptionData, PlayerDescriptionDataError] = UsePlayerDescriptionInformation(playerID);


    if (!playerID) {
        return null;
    }

    if (PlayerDescriptionDataError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} cssClasses={"margin-top-40"} />;
    }

    if (!PlayerDescriptionData) {
        return <SingleContentLoaderComponent />;
    }


    return (
        <>

            <PlayerMainDataComponent
                PlayerDescriptionData={PlayerDescriptionData}
            />

            <PlayerInformationDataComponent
                PlayerDescriptionData={PlayerDescriptionData}
            />

        </>
    );


};


export default PlayerDescriptionComponent;
