import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    FormTextFieldComponent
} from "@FormsElements";


import "./index.scss";


export interface TeamsAndPlayersPlayersSearchBarComponentProps {
    searchLeaguePlayersQuery: string;
    searchLeaguePlayersQueryHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TeamsAndPlayersPlayersSearchBarComponent: React.FC<TeamsAndPlayersPlayersSearchBarComponentProps> = ({
    searchLeaguePlayersQuery, searchLeaguePlayersQueryHandler
}) => {

    const SEARCH_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEARCH_WORD_TEXT");

    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__players__search-bar"}>
            <Row className={"teams-and-players__content__teams-and-players__players__search-bar__content"}>

                <Col xs={12} lg={1} className={"teams-and-players__content__teams-and-players__players__search-bar__label"}>
                    <span>{SEARCH_WORD_TEXT}</span>
                </Col>

                <Col xs={12} lg={3} className={"teams-and-players__content__teams-and-players__players__search-bar__search-field"}>
                    <FormTextFieldComponent
                        fieldValue={searchLeaguePlayersQuery}
                        fieldValueHandler={searchLeaguePlayersQueryHandler}
                        fieldPlaceholder={`${SEARCH_WORD_TEXT}...`}
                    />
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersPlayersSearchBarComponent;
