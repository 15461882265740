import React, {useEffect, useState} from "react";


import {UseLoggedUser, UseLanguage, UseLoggedUserToken} from "@States";

import { UseSetPopupMessageAction } from "@StatesActions";


import { UseRoute } from "@Hooks/Routes";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { UseErrorMessage } from "@Hooks/Texts";


import { FormalsDictionary } from "@Dictionary";


import { Redirect } from "react-router-dom";


import {
    ContainerSectionComponent,
    DefaultMessageComponent
} from "@Elements";


import UserFormalsFormalAgreementComponent from "@Pages/User/Authenticated/Profile/Formals/Formal";
import {API_LOGGED_PLAYER_UPDATE_PROFILE_DATA_ENDPOINT_URL} from "@Services/API/Endpoints";


export interface UserFormalsAgreementsComponentProps {
    userRegulationAcceptedDate?: Date | string | null;
    userPrivacyAcceptedDate?: Date | string | null;
    updateUserTrigger?: (status: boolean) => void;
}


export const UserFormalsAgreementsComponent: React.FC<UserFormalsAgreementsComponentProps> = ({
    updateUserTrigger,
    userRegulationAcceptedDate, userPrivacyAcceptedDate
}) => {


    const playerApiToken = UseLoggedUserToken();


    const ActiveLanguage = UseLanguage();


    const RegulationsRoute = UseRoute("REGULATIONS");
    const PrivacyPolicyRoute = UseRoute("PRIVACY_POLICY");

    const LoginRoute = UseRoute("LOGIN");


    const {
        not_all_formals_accepted_message_text: NOT_ALL_FORMALS_ACCEPTED_MESSAGE_TEXT,
        regulations_acceptation_statement_message_text: REGULATIONS_ACCEPTATION_STATEMENT_MESSAGE_TEXT,
        regulations_accepted_message_text: REGULATIONS_ACCEPTED_MESSAGE_TEXT,
        regulations_i_accept_message_text: REGULATIONS_I_ACCEPT_MESSAGE_TEXT,
        privacy_policy_acceptation_statement_message_text: PRIVACY_POLICY_ACCEPTATION_STATEMENT_MESSAGE_TEXT,
        privacy_policy_accepted_message_text: PRIVACY_POLICY_ACCEPTED_MESSAGE_TEXT,
        privacy_policy_i_accept_message_text: PRIVACY_POLICY_I_ACCEPT_MESSAGE_TEXT
    } = FormalsDictionary[ActiveLanguage];


    const DEFAULT_ERROR_MESSAGE = UseErrorMessage("DEFAULT");


    const LoggedUser = UseLoggedUser();


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const [regulationAcceptedDate, setRegulationAcceptedDate] = useState(userRegulationAcceptedDate);
    const [privacyAcceptedDate, setPrivacyAcceptedDate] = useState(userPrivacyAcceptedDate);

    useEffect(() => {
        setRegulationAcceptedDate(userRegulationAcceptedDate);
        setPrivacyAcceptedDate(userPrivacyAcceptedDate);
    }, [userRegulationAcceptedDate, userPrivacyAcceptedDate]);


    if (!playerApiToken) {
        return <Redirect to={LoginRoute.routeURL} />;
    }


    const formalsAgreementAcceptationHandler = async (formalsType: "1" | "2") => {

        if (!playerApiToken) {
            return false;
        }


        const formData = new FormData();

        if (formalsType === "1") {
            formData.append("player_regulations_acceptation_date", (new Date()).toISOString());
        } else if (formalsType === "2") {
            formData.append("player_privacy_acceptation_date", (new Date()).toISOString());
        }


        try {

            const response = await _restApiRequest(
                API_LOGGED_PLAYER_UPDATE_PROFILE_DATA_ENDPOINT_URL,
                formData,
                "PATCH",
                playerApiToken
            );

            if (response.ok) {
                let message;
                switch (formalsType) {
                    case "1":
                        message = REGULATIONS_ACCEPTED_MESSAGE_TEXT;
                        setRegulationAcceptedDate(new Date());
                        break;
                    case "2":
                        message = PRIVACY_POLICY_ACCEPTED_MESSAGE_TEXT;
                        setPrivacyAcceptedDate(new Date());
                        break;
                }

                if (!!message) UseSetPopupMessage({ type: "SUCCESS", text: message });

                if (!!updateUserTrigger) updateUserTrigger(true);

            } else {
                UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE });
        }

    };


    return (
        <ContainerSectionComponent classes={"user-profile-formals"}>

            {(!regulationAcceptedDate || !privacyAcceptedDate) &&
            <DefaultMessageComponent
                messageText={NOT_ALL_FORMALS_ACCEPTED_MESSAGE_TEXT}
                cssClasses={"no-margin-top"}
            />
            }

            <UserFormalsFormalAgreementComponent
                formalAcceptationDate={regulationAcceptedDate}
                formalRoute={RegulationsRoute}
                formalAcceptationLabelText={REGULATIONS_ACCEPTATION_STATEMENT_MESSAGE_TEXT}
                formalAcceptationButtonText={REGULATIONS_I_ACCEPT_MESSAGE_TEXT}
                formalAcceptationHandler={() => formalsAgreementAcceptationHandler("1")}
            />

            <UserFormalsFormalAgreementComponent
                formalAcceptationDate={privacyAcceptedDate}
                formalRoute={PrivacyPolicyRoute}
                formalAcceptationLabelText={PRIVACY_POLICY_ACCEPTATION_STATEMENT_MESSAGE_TEXT}
                formalAcceptationButtonText={PRIVACY_POLICY_I_ACCEPT_MESSAGE_TEXT}
                formalAcceptationHandler={() => formalsAgreementAcceptationHandler("2")}
            />

        </ContainerSectionComponent>
    );

};


export default UserFormalsAgreementsComponent;
