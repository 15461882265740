import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TeamsAndPlayersLeaguesHeaderComponentProps {}


export const TeamsAndPlayersLeaguesHeaderComponent: React.FC<TeamsAndPlayersLeaguesHeaderComponentProps> = ({

}) : JSX.Element | null => {


    const LEAGUES_WORD_TEXT = UseCommonDictionaryPhrasesSet("LEAGUES_WORD_TEXT");


    return (
        <Col xs={12} lg={"auto"} className={"teams-and-players__content__seasons-and-leagues__leagues__header"}>
            <span className={"teams-and-players__content__seasons-and-leagues__leagues__header__text"}>{LEAGUES_WORD_TEXT}</span>
        </Col>
    );

};


export default TeamsAndPlayersLeaguesHeaderComponent;
