import React, {useEffect, useState} from "react";


import { Col, Row } from "react-bootstrap";


import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import TeamCaptainTeamComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team";


import { LeagueTeamInterface } from "@Interfaces/Api";
import {LoggedPlayerProfileDataInterface} from "@Interfaces/2.0";
import {UseCommonDictionaryPhrasesSet} from "@Hooks/Texts";
import {UsePlayerIdBySlug} from "@Hooks/Api/Requests";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import {SingleContentLoaderComponent} from "@LoadersElements";
import MessageComponent from "@MessagesElements/Content";
import _restApiRequest from "@Services/HTTP/Requests/Request";
import {API_TEAMS_ENDPOINT_URL} from "@Services/API/Endpoints";


export interface PlayerProfileTeamCaptainAreaComponentProps {
    Player: LoggedPlayerProfileDataInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const PlayerProfileTeamCaptainAreaComponent: React.FC<PlayerProfileTeamCaptainAreaComponentProps> = ({
    Player, updatePlayerTrigger
}): JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");

    const CAPTAIN_ZONE_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_ZONE_LABEL");


    const {
        player_slug: playerSlug, player_captained_teams_ids: playerCaptainedTeamsIDs
    } = Player;

    const [playerID, playerIDError] = UsePlayerIdBySlug(playerSlug);

    const [playerCaptainedTeams, setPlayerCaptainedTeams] = useState<LeagueTeamInterface[] | null>(null);
    const [playerCaptainedTeamsError, setPlayerCaptainedTeamsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!playerID || !playerCaptainedTeamsIDs) {
            return;
        }

        const getPlayerCaptainedTeams = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_TEAMS_ENDPOINT_URL}get-teams-by-ids/?teams_ids=${playerCaptainedTeamsIDs.toString()}`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setPlayerCaptainedTeams(responseContent);
                } else {
                    setPlayerCaptainedTeamsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setPlayerCaptainedTeamsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getPlayerCaptainedTeams();

    }, [playerID, playerCaptainedTeamsIDs]);

    useEffect(() => {
        return () => {
            setPlayerCaptainedTeams(null);
            setPlayerCaptainedTeamsError(null);
        };
    }, []);


    if (playerIDError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (playerCaptainedTeamsError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!playerID) {
        return null;
    }

    if (!playerCaptainedTeams) {
        return <SingleContentLoaderComponent />;
    }

    if (!playerCaptainedTeams?.length) {
        return <MessageComponent messageText={`Brak drużyn`} />;
    }


    const CaptainedTeams = playerCaptainedTeams.map((Team, i) =>
        <TeamCaptainTeamComponent
            key={i}
            Team={Team}
            playerID={playerID}
            updatePlayerTrigger={updatePlayerTrigger}
        />
    );


    return (
        <Col xs={12} className={"user-profile-page-data user-profile-captain-area"}>
            <Row className={"user-profile-page-data-content"}>

                <Col xs={12} className={"user-profile-page-data-header"}>
                    <span>{CAPTAIN_ZONE_LABEL}</span>
                </Col>

                {CaptainedTeams}

            </Row>
        </Col>
    );

};


export default PlayerProfileTeamCaptainAreaComponent;
