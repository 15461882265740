import React from "react";


import { UseLoggedUser } from "@States";


import { UseRoute } from "@Hooks/Routes";


import { Redirect } from "react-router-dom";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import { UseAuthenticatedUserDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import PlayerUserProfilePageComponent from "@Pages/User/Authenticated/Profile/Player";


import "./index.scss";
import UseLoggedUserToken from "../../../../../../Store/_Hooks/_States/Authentication/User/Token";
import UserProfilePageContentComponent from "@Pages/User/Authenticated/Profile/Content";


export interface UserProfilePageComponentProps {}


export const UserProfilePageComponent: React.FC<UserProfilePageComponentProps> = () : JSX.Element | null => {


    const userToken = UseLoggedUserToken();


    const Route = UseRoute("LOGIN");

    const { routeURL: LOGIN_URL } = Route;


    const USER_ZONE_LABEL = UseAuthenticatedUserDictionaryPhrasesSet("USER_ZONE_LABEL");


    if (!userToken) {
        return <Redirect to={`/${LOGIN_URL}`} />;
    }


    return (
        <PageContainerComponent  id={"user-profile-page"} cssClasses={"user-profile-page user-profile-page-component"}>

            <DefaultPageHeaderComponent headerText={USER_ZONE_LABEL} />

            <PageContentComponent>

                <UserProfilePageContentComponent userToken={userToken} />

            </PageContentComponent>

        </PageContainerComponent>
    )

};


export default UserProfilePageComponent;
