import React, {useEffect, useState} from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Redirect} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import PlayerProfileNavigationMenuComponent from "@Components/Views/Pages/User/Authenticated/Profile/Player/Menu";
import PlayerProfileContentComponent from "@Components/Views/Pages/User/Authenticated/Profile/Player/Content";
import UserFormalsAgreementsComponent from "@Components/Views/Pages/User/Authenticated/Profile/Formals";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


import PlayerProfileContentTypeType from "@Components/Views/Pages/User/Authenticated/Profile/Player/_ContentType";
import {LoggedPlayerProfileDataInterface} from "@Interfaces/2.0";


export interface PlayerUserProfilePageComponentProps {
    Player: LoggedPlayerProfileDataInterface;
    playerUpdateTriggerHandler: (status: boolean) => void;
}


export const PlayerUserProfilePageComponent: React.FC<PlayerUserProfilePageComponentProps> = ({
    Player, playerUpdateTriggerHandler
}) : JSX.Element | null => {


    const [playerProfileActiveSubPage, setPlayerProfileActiveSubPage] = useState<PlayerProfileContentTypeType>("MAIN_DATA");


    useEffect(() => {

        if (!Player) return;

        const {
            player_regulations_acceptation_date: regulationsAcceptationDate,
            player_privacy_acceptation_date: privacyAcceptationDate
        } = Player;

        if (!regulationsAcceptationDate || !privacyAcceptationDate) {
            setPlayerProfileActiveSubPage("FORMALS");
        }

    }, [Player]);


    if (!Player) {
        return null;
    }


    const {
        player_regulations_acceptation_date: regulationsAcceptationDate,
        player_privacy_acceptation_date: privacyAcceptationDate
    } = Player;


    if (!regulationsAcceptationDate || !privacyAcceptationDate) {
        return (
            <UserFormalsAgreementsComponent
                userRegulationAcceptedDate={regulationsAcceptationDate}
                userPrivacyAcceptedDate={privacyAcceptationDate}
                updateUserTrigger={playerUpdateTriggerHandler}
            />
        );
    }


    const {
        player_captained_teams_ids: captainedTeams
    } = Player;

    const playerCaptainStatus = captainedTeams && captainedTeams?.length > 0;


    return (
        <Col xs={12} className={"user-profile-page-content"}>
            <Row className={"user-profile-page-content-content"}>

                <PlayerProfileNavigationMenuComponent
                    selectedSubPage={playerProfileActiveSubPage}
                    selectedSubPageHandler={setPlayerProfileActiveSubPage}
                    playerCaptainStatus={playerCaptainStatus}
                />

                <PlayerProfileContentComponent
                    Player={Player}
                    activeContent={playerProfileActiveSubPage}
                    updatePlayerTrigger={playerUpdateTriggerHandler}
                />

            </Row>
        </Col>
    );

};


export default PlayerUserProfilePageComponent;
