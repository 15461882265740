import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamsAndPlayersSeasonsComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Seasons";
import TeamsAndPlayersLeaguesComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Leagues";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersSeasonsAndLeaguesComponentProps {
    SeasonsWithLeagues: SeasonWithLeaguesShortInformationType[];
    selectedSeasonID: number;
    selectedSeasonIDHandler: (seasonID: number) => void;
    selectedLeagueID: number;
    selectedLeagueIDHandler: (leagueID: number) => void;
}


export const TeamsAndPlayersSeasonsAndLeaguesComponent: React.FC<TeamsAndPlayersSeasonsAndLeaguesComponentProps> = ({
    SeasonsWithLeagues,
    selectedSeasonID, selectedSeasonIDHandler,
    selectedLeagueID, selectedLeagueIDHandler,
}) : JSX.Element | null => {


    const [SelectedSeason, setSelectedSeason] = useState<SeasonWithLeaguesShortInformationType | null>(null);


    useEffect(() => {
        const SelectedSeason = SeasonsWithLeagues.filter(
            (Season) => Season.id === selectedSeasonID
        )[0];
        if (SelectedSeason) {
            setSelectedSeason(SelectedSeason);
        }
    }, [selectedSeasonID]);

    useEffect(() => {
        return () => {
            setSelectedSeason(null);
        };
    }, []);


    return (
        <Col xs={12} className={"teams-and-players__content__seasons-and-leagues"}>
            <Row className={"teams-and-players__content__seasons-and-leagues__content"}>

                <TeamsAndPlayersSeasonsComponent
                    SeasonsWithLeagues={SeasonsWithLeagues}
                    SelectedSeason={SelectedSeason}
                    selectedSeasonID={selectedSeasonID}
                    selectedSeasonIDHandler={selectedSeasonIDHandler}
                />

                <TeamsAndPlayersLeaguesComponent
                    SelectedSeason={SelectedSeason}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={selectedLeagueIDHandler}
                />

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersSeasonsAndLeaguesComponent;
