import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface PlayerStatisticsTableLeaguesListHeaderComponentProps {}


export const PlayerStatisticsTableLeaguesListHeaderComponent: React.FC<PlayerStatisticsTableLeaguesListHeaderComponentProps> = ({}) : JSX.Element | null => {


    const SEASON_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEASON_WORD_TEXT");
    const TEAM_WORD_TEXT = UseCommonDictionaryPhrasesSet("TEAM_WORD_TEXT");

    const GAMES_WORD_TEXT = UseCommonDictionaryPhrasesSet("GAMES_WORD_TEXT");
    const GAME_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("GAME_WORD_SHORT_TEXT");
    const STATISTICS_START_5_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_START_5_PHRASE_TEXT");
    const STATISTICS_START_5_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_START_5_PHRASE_SHORT_TEXT");
    const STATISTICS_MINUTES_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MINUTES_WORD_TEXT");
    const STATISTICS_MINUTES_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MINUTES_WORD_SHORT_TEXT");

    const STATISTICS_POINTS_WORD_TEXT_PL = UseCommonDictionaryPhrasesSet("STATISTICS_POINTS_WORD_TEXT");
    const STATISTICS_POINTS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_POINTS_SHORT_TEXT");
    const STATISTICS_2PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_2PT_THROWS_PHRASE_TEXT");
    const STATISTICS_3PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_3PT_THROWS_PHRASE_TEXT");
    const STATISTICS_1PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_1PT_THROWS_PHRASE_TEXT");
    const STATISTICS_GAME_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_GAME_THROWS_PHRASE_TEXT");
    const STATISTICS_ACCURATE_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ACCURATE_THROWS_PHRASE_TEXT");
    const STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT = UseCommonDictionaryPhrasesSet("STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT");
    const STATISTICS_MADE_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MADE_THROWS_PHRASE_TEXT");
    const STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT");

    const STATISTICS_REBOUNDS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_WORD_TEXT");
    const STATISTICS_REBOUNDS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_SHORT_TEXT");
    const STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_TEXT");
    const STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT");
    const STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_TEXT");
    const STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT");
    const STATISTICS_TOTAL_REBOUNDS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TOTAL_REBOUNDS_PHRASE_TEXT");
    const STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT");

    const STATISTICS_ASSISTS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_WORD_TEXT");
    const STATISTICS_ASSISTS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_SHORT_TEXT");
    const STATISTICS_BLOCKS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BLOCKS_WORD_TEXT");
    const STATISTICS_BLOCKS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BLOCKS_SHORT_TEXT");
    const STATISTICS_STEALS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_STEALS_WORD_TEXT");
    const STATISTICS_STEALS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_STEALS_SHORT_TEXT");
    const STATISTICS_TURNOVERS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TURNOVERS_WORD_TEXT");
    const STATISTICS_TURNOVERS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TURNOVERS_WORD_SHORT_TEXT");
    const STATISTICS_FOULS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_FOULS_WORD_TEXT");
    const STATISTICS_FOULS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_FOULS_WORD_SHORT_TEXT");

    const STATISTICS_EFFECTIVITY_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EFFECTIVITY_WORD_TEXT");
    const STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT");
    const STATISTICS_EVAL_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EVAL_TEXT");




    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header"}>
            <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__content player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__content"}>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--season-name player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value--season-name"}>
                    <span>{SEASON_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--team-name player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value--team-name"}>
                    <span>{TEAM_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={GAMES_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--games-number"}>
                    <span>{GAME_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_START_5_PHRASE_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--start-5"}>
                    <span>{STATISTICS_START_5_PHRASE_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_MINUTES_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--minutes-number"}>
                    <span>{STATISTICS_MINUTES_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_POINTS_WORD_TEXT_PL} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--points-number"}>
                    <span>{STATISTICS_POINTS_SHORT_TEXT}</span>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt2-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={STATISTICS_2PT_THROWS_PHRASE_TEXT}>
                            <span>{STATISTICS_2PT_THROWS_PHRASE_TEXT}</span>
                        </Col>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={`${STATISTICS_ACCURATE_THROWS_PHRASE_TEXT}/${STATISTICS_MADE_THROWS_PHRASE_TEXT}`}>
                            <span>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                            <span className={"separator"}></span>
                            <span>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt3-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={STATISTICS_3PT_THROWS_PHRASE_TEXT}>
                            <span>{STATISTICS_3PT_THROWS_PHRASE_TEXT}</span>
                        </Col>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={`${STATISTICS_ACCURATE_THROWS_PHRASE_TEXT}/${STATISTICS_MADE_THROWS_PHRASE_TEXT}`}>
                            <span>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                            <span className={"separator"}></span>
                            <span>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
                    </Row>
                </Col>


                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt-game-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={STATISTICS_GAME_THROWS_PHRASE_TEXT}>
                            <span>{STATISTICS_GAME_THROWS_PHRASE_TEXT}</span>
                        </Col>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={`${STATISTICS_ACCURATE_THROWS_PHRASE_TEXT}/${STATISTICS_MADE_THROWS_PHRASE_TEXT}`}>
                            <span>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                            <span className={"separator"}></span>
                            <span>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--ft-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={STATISTICS_1PT_THROWS_PHRASE_TEXT}>
                            <span>{STATISTICS_1PT_THROWS_PHRASE_TEXT}</span>
                        </Col>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={`${STATISTICS_ACCURATE_THROWS_PHRASE_TEXT}/${STATISTICS_MADE_THROWS_PHRASE_TEXT}`}>
                            <span>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                            <span className={"separator"}></span>
                            <span>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span>{"%"}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--rebounds-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"} title={STATISTICS_REBOUNDS_WORD_TEXT}>
                            <span>{STATISTICS_REBOUNDS_SHORT_TEXT}</span>
                        </Col>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <span title={STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_TEXT}>{STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span title={STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_TEXT}>{STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                            <span className={"separator"}></span>
                            <span title={STATISTICS_TOTAL_REBOUNDS_PHRASE_TEXT}>{STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                        </Col>
                    </Row>
                </Col>

                <Col xs={"auto"} title={STATISTICS_ASSISTS_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--assists-number"}>
                    <span>{STATISTICS_ASSISTS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_BLOCKS_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--blocks-number"}>
                    <span>{STATISTICS_BLOCKS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_STEALS_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--steals-number"}>
                    <span>{STATISTICS_STEALS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_TURNOVERS_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--turnovers-number"}>
                    <span>{STATISTICS_TURNOVERS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_FOULS_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--fouls-number"}>
                    <span>{STATISTICS_FOULS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} title={STATISTICS_EFFECTIVITY_WORD_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--effectivity-value"}>
                    <span>{STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={1} title={STATISTICS_EVAL_TEXT} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__header__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--eval-value"}>
                    <span>{STATISTICS_EVAL_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerStatisticsTableLeaguesListHeaderComponent;
