import React, { useEffect, useState } from "react";


import {
    PageContentComponent
} from "@ContentElements";


import PlayersDetailsStatisticsDataSwitcherComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsSwitcher";
import PlayersDetailsStatisticsDataContentComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent";


import {
    UsePlayerCareerStatistics
} from "@Hooks/2.0";

import PlayerCareerStatisticsTypes from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/@Types/Statistics";


import "./index.scss";


export interface PlayersDetailsStatisticsDataComponentProps {
    playerID: number;
}


export const PlayersDetailsStatisticsDataComponent: React.FC<PlayersDetailsStatisticsDataComponentProps> = ({
    playerID
}) => {

    const [PlayerCareerStatistics, PlayerCareerStatisticsError] = UsePlayerCareerStatistics(playerID);


    const [selectedCareerStatisticsType, setSelectedCareerStatisticsType] = useState<PlayerCareerStatisticsTypes>(PlayerCareerStatisticsTypes.LEAGUES_STATISTICS);

    useEffect(() => {
        return () => {
            setSelectedCareerStatisticsType(PlayerCareerStatisticsTypes.LEAGUES_STATISTICS);
        };
    }, []);


    return (
        <PageContentComponent cssClasses={"player-details__details-data__statistics-data"}>

            <PlayersDetailsStatisticsDataSwitcherComponent
                selectedCareerStatisticsType={selectedCareerStatisticsType}
                selectedCareerStatisticsTypeHandler={setSelectedCareerStatisticsType}
            />

            <PlayersDetailsStatisticsDataContentComponent
                PlayerCareerStatistics={PlayerCareerStatistics}
                PlayerCareerStatisticsError={PlayerCareerStatisticsError}
                selectedCareerStatisticsType={selectedCareerStatisticsType}
            />

        </PageContentComponent>
    )

};


export default PlayersDetailsStatisticsDataComponent;
