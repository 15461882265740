import React from "react";


import {
    Col
} from "react-bootstrap";


import PlayerNameComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player/Information/Name";
import PlayerFieldInformationComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player/Information/Field";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerInformationComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerInformationComponent: React.FC<PlayerInformationComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    return (
        <Col xs={8} lg={9} className={"player-information"}>

            <PlayerFieldInformationComponent
                PlayerDescriptionData={PlayerDescriptionData}
            />

            <PlayerNameComponent
                PlayerDescriptionData={PlayerDescriptionData}
            />

        </Col>
    );

};


export default PlayerInformationComponent;
