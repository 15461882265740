import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayersListLetterPlayersPlayerComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/List/Letter/Players/Player";


import {
    PlayerPublicBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayersListLetterPlayersComponentProps {
    Players: PlayerPublicBasicInformationType[];
}


export const PlayersListLetterPlayersComponent: React.FC<PlayersListLetterPlayersComponentProps> = ({
    Players
}) : JSX.Element | null => {


    if (!Players?.length) {
        return null;
    }


    const PlayersList = Players.map((Player, i) =>
        <PlayersListLetterPlayersPlayerComponent
            key={i}
            Player={Player}
        />
    );


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__players__list__letter__players"}>
            <Row className={"teams-and-players__content__teams-and-players__players__list__letter__players__content"}>
                {PlayersList}
            </Row>
        </Col>
    );


};


export default PlayersListLetterPlayersComponent;
