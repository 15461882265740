import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamsAndPlayersPlayersSearchBarComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/Search";
import TeamsAndPlayersPlayersListComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/List";


import {
    TeamLeaguePlayerBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersPlayersContentComponentProps {
    LeaguePlayers: TeamLeaguePlayerBasicInformationType[];
}


export const TeamsAndPlayersPlayersContentComponent: React.FC<TeamsAndPlayersPlayersContentComponentProps> = ({
     LeaguePlayers
}) => {


    const [searchLeaguePlayersQuery, setSearchLeaguePlayersQuery] = useState("");

    const searchLeaguePlayersQueryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLeaguePlayersQuery(e.target.value);
    }

    useEffect(() => {
        return () => {
            setSearchLeaguePlayersQuery("");
        }
    }, []);


    if (!LeaguePlayers) {
        return null;
    }

    const FilteredLeaguePlayers = LeaguePlayers.filter((LeaguePlayer) => {

        const { league_team_player_player: TeamPlayer } = LeaguePlayer;

        if (!TeamPlayer) {
            return false;
        }

        const { team_player_player: Player } = TeamPlayer;

        if (!Player) {
            return false;
        }

        const { player_name: playerName } = Player;

        return playerName.toLowerCase().includes(searchLeaguePlayersQuery.toLowerCase());

    }, []);


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__players"}>
            <Row className={"teams-and-players__content__teams-and-players__players__content"}>

                <TeamsAndPlayersPlayersSearchBarComponent
                    searchLeaguePlayersQuery={searchLeaguePlayersQuery}
                    searchLeaguePlayersQueryHandler={searchLeaguePlayersQueryHandler}
                />

                <TeamsAndPlayersPlayersListComponent
                    LeaguePlayers={FilteredLeaguePlayers}
                    filtersActiveStatus={!!searchLeaguePlayersQuery.length}
                />

            </Row>
        </Col>
    );


};


export default TeamsAndPlayersPlayersContentComponent;
