import React, { useEffect, useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import PlayerBestScoresHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Header";
import PlayerBestScoresScoresListComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Scores";


import {
    PlayerCareerStatisticsSeasonInterface,
    PlayerBestScoresInterface,
    PlayerCareerStatisticsLeagueInterface,
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerDetailsStatisticsPlayerBestScoresComponentProps {
    PlayerSeasonsStatistics: PlayerCareerStatisticsSeasonInterface[];
    PlayerCareerBestScores: PlayerBestScoresInterface;
}


export const PlayerDetailsStatisticsPlayerBestScoresComponent: React.FC<PlayerDetailsStatisticsPlayerBestScoresComponentProps> = ({
    PlayerCareerBestScores, PlayerSeasonsStatistics
}) => {


    const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT");


    if (!PlayerSeasonsStatistics && !PlayerCareerBestScores) {
        return <MessageComponent messageText={DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT} cssClasses={"white"} />
    }

    const [selectedLeagueID, setSelectedLeagueID] = useState(0);
    const [SelectedLeague, setSelectedLeague] = useState<PlayerCareerStatisticsLeagueInterface | null>(null);

    useEffect(() => {

        if (!PlayerSeasonsStatistics?.length) {
            return;
        }

        const DefaultSeason = PlayerSeasonsStatistics[0];

        if (!DefaultSeason) {
            return;
        }

        const { league_season_leagues: DefaultSeasonLeagues } = DefaultSeason;

        if (!DefaultSeasonLeagues?.length) {
            return;
        }

        const DefaultLeague = DefaultSeasonLeagues[0];

        if (!DefaultLeague) {
            return;
        }

        setSelectedLeagueID(DefaultLeague.id);

    }, []);

    useEffect(() => {

        if (!selectedLeagueID || !PlayerSeasonsStatistics?.length) {
            return setSelectedLeague(null);
        }

        PlayerSeasonsStatistics.forEach((Season) => {
            const { league_season_leagues: SeasonLeagues } = Season;
            SeasonLeagues.forEach((League) => {
                if (League.id === selectedLeagueID) {
                    setSelectedLeague(League);
                }
            });
        });

    }, [selectedLeagueID]);

    useEffect(() => {
        return () => {
            setSelectedLeagueID(0);
            setSelectedLeague(null);
        };
    }, []);


    const LeaguesList: PlayerCareerStatisticsLeagueInterface[] = [];

    for (let Season of PlayerSeasonsStatistics) {
        const { league_season_leagues: SeasonLeagues } = Season;
        if (!SeasonLeagues) {
            continue;
        }
        LeaguesList.push(...SeasonLeagues);
    }


    const [PlayerBestScores, setPlayerBestScores] = useState<PlayerBestScoresInterface | null>(null);

    useEffect(() => {

        if (!SelectedLeague) {
            return setPlayerBestScores(null);
        }

        const {
            league_statistics: SelectedLeagueStatistics
        } = SelectedLeague;

        if (!SelectedLeagueStatistics) {
            return setPlayerBestScores(null);
        }

        const {
            player_best_scores: BestScores
        } = SelectedLeagueStatistics;

        setPlayerBestScores({...BestScores});

    }, [SelectedLeague]);

    useEffect(() => {
        return () => {
            setPlayerBestScores(null);
        };
    }, []);


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__best-scores"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__content"}>

                <PlayerBestScoresHeaderComponent
                    Leagues={LeaguesList}
                    SelectedLeague={SelectedLeague}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={setSelectedLeagueID}
                />

                <PlayerBestScoresScoresListComponent
                    PlayerBestScores={PlayerBestScores}
                />

            </Row>
        </Col>
    );

};


export default PlayerDetailsStatisticsPlayerBestScoresComponent;
