import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";

import {
    AiFillCaretDown
} from "react-icons/all";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerPlayedGamesHeaderSelectedLeagueComponentProps {
    SelectedLeague: PlayerCareerStatisticsLeagueInterface | null;
    showLeaguesListStatus: boolean;
    showLeaguesListStatusHandler: (status: boolean) => void;
    leaguesNumber: number;
}

export const PlayerPlayedGamesHeaderSelectedLeagueComponent: React.FC<PlayerPlayedGamesHeaderSelectedLeagueComponentProps> = ({
    SelectedLeague,
    showLeaguesListStatus, showLeaguesListStatusHandler,
    leaguesNumber
}) => {


    const CHOOSE_LEAGUE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CHOOSE_LEAGUE_PHRASE_TEXT");


    let leagueLabel = CHOOSE_LEAGUE_PHRASE_TEXT;

    if (SelectedLeague) {
        const {
            league_name: leagueName,
            league_season_name: leagueSeasonName
        } = SelectedLeague;
        leagueLabel = `${leagueName} (${leagueSeasonName})`;
    }


    return (
        <Col xs={12} onClick={() => showLeaguesListStatusHandler(!showLeaguesListStatus)} className={"player-details__details-data__statistics-data__content__played-games__header__leagues__selected-league"}>
            <span>{leagueLabel}</span>
            {leaguesNumber > 1  && <AiFillCaretDown />}
        </Col>
    );

};


export default PlayerPlayedGamesHeaderSelectedLeagueComponent;
