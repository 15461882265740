import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import QuarterPointsEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Points";
import QuarterThrowEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Throw";
import QuarterReboundEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Rebound";
import QuarterBlockEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Block";
import QuarterSubstitutionEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Substitution";
import QuarterFoulEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Foul";
import QuarterDefaultEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/@Default";
import QuarterTeamReboundEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/TeamRebound";
import QuarterTurnoverEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/Turnover";
import QuarterTimeOutEventDataComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/Data/TimeOut";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";

import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


import "./index.scss";


export interface GameProgressQuarterEventComponentProps {
    homeTeamID: number; awayTeamID: number;
    QuarterEvent: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
    gameQuarterDurationTime: number;
    gameExtraTimeDurationTime: number;
}

export const GameProgressQuarterEventComponent: React.FC<GameProgressQuarterEventComponentProps> = ({
    homeTeamID, awayTeamID,
    QuarterEvent, GamePlayers,
    gameQuarterDurationTime, gameExtraTimeDurationTime,
}) => {


    if (!QuarterEvent || !GamePlayers) {
        return null;
    }


    const {
        eventType, eventOrderNr
    } = QuarterEvent;


    const cssClassesList: string[] = []

    if (["1PT", "2PT", "3PT"].includes(eventType)) {
        cssClassesList.push("game-result-details__game-progress__quarters__quarter__events__event--points-event");
    }

    const cssClasses = cssClassesList.join(" ");


    let EventDataComponent: React.ReactNode = null;

    const EventDataComponentProps = {
        eventOrderNr, quarterNumber: QuarterEvent.eventQuarter,
        homeTeamID, awayTeamID,
        Event: QuarterEvent, GamePlayers,
        quarterDuration: gameQuarterDurationTime,
        extraTimeDuration: gameExtraTimeDurationTime
    };

    switch (eventType) {

        case GameProgressEventTypes.DEFAULT:
            EventDataComponent = <QuarterDefaultEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.POINTS:
            EventDataComponent = <QuarterPointsEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.THROW:
            EventDataComponent = <QuarterThrowEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.REBOUND:
            EventDataComponent = <QuarterReboundEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.BLOCK:
            EventDataComponent = <QuarterBlockEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.SUBSTITUTION:
            EventDataComponent = <QuarterSubstitutionEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.FOUL:
            EventDataComponent = <QuarterFoulEventDataComponent {...EventDataComponentProps} />;
            break;

        case GameProgressEventTypes.TEAM_REBOUND:
            EventDataComponent = <QuarterTeamReboundEventDataComponent {...EventDataComponentProps} />
            break;

        case GameProgressEventTypes.TURNOVER:
            EventDataComponent = <QuarterTurnoverEventDataComponent {...EventDataComponentProps} />
            break;

        case GameProgressEventTypes.TIME_OUT:
            EventDataComponent = <QuarterTimeOutEventDataComponent {...EventDataComponentProps} />
            break;

        default:
            return null;

    }

    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event" + (cssClasses ? ` ${cssClasses}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__content"}>

                {EventDataComponent}

            </Row>
        </Col>
    );

};


export default GameProgressQuarterEventComponent;
