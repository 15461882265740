import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_PLAYER_CAREER_STATISTICS_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    PlayerCareerStatisticsInterface
} from "@Interfaces/2.0";


export const UsePlayerCareerStatistics = (
    playerID: number
) : [PlayerCareerStatisticsInterface | null, ErrorMessageInterface | null] => {


    const [PlayerCareerStatistics, setPlayerCareerStatistics] = useState<PlayerCareerStatisticsInterface | null>(null);
    const [PlayerCareerStatisticsError, setPlayerCareerStatisticsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!playerID) {
            return setPlayerCareerStatisticsError({ errorCode: 400, errorMessage: "" });
        }

        const getPlayerCareerStatistics = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_PLAYER_CAREER_STATISTICS_PUBLIC_ENDPOINT_URL}?player_id=${playerID}&get-player-best-scores=1`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setPlayerCareerStatistics(responseContent);
                } else {
                    setPlayerCareerStatisticsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setPlayerCareerStatisticsError({ errorCode: 500, errorMessage: "" });
            }

        }

        getPlayerCareerStatistics();

    }, [playerID]);

    useEffect(() => {
        return () => {
            setPlayerCareerStatistics(null);
            setPlayerCareerStatisticsError(null);
        }
    }, []);


    return [PlayerCareerStatistics, PlayerCareerStatisticsError];


};


export default UsePlayerCareerStatistics;
