import FormFieldsErrorsDictionaryInterface from "@Dictionary/Sets/Forms/Messages/Errors/types";

import {
    NAME_PL, SHORT_NAME_PL, FIRST_NAME_PL, LAST_NAME_PL, NICK_NAME_PL,
    EMAIL_ADDRESS_PL, WEBSITE_PL, SOCIAL_ID_PL,
    CITY_PL,
    NAME_EN, SHORT_NAME_EN, FIRST_NAME_EN, LAST_NAME_EN, NICK_NAME_EN,
    EMAIL_ADDRESS_EN, WEBSITE_EN, SOCIAL_ID_EN,
    CITY_EN,
    DESCRIPTION_PL, BIRTHDATE_PL, WEIGHT_PL, HEIGHT_PL,
    DESCRIPTION_EN, BIRTHDATE_EN, WEIGHT_EN, HEIGHT_EN,
    COLOR_PL, FIRST_COLOR_PL, SECOND_COLOR_PL, NUMBER_PL,
    COLOR_EN, FIRST_COLOR_EN, SECOND_COLOR_EN, NUMBER_EN
} from "@Dictionary/Sets/Forms/Messages/Errors/consts";


export const FormFieldsErrorsDictionary: FormFieldsErrorsDictionaryInterface = {
    PL: {
        NAME: NAME_PL, SHORT_NAME: SHORT_NAME_PL, FIRST_NAME: FIRST_NAME_PL, LAST_NAME: LAST_NAME_PL, NICK_NAME: NICK_NAME_PL,
        EMAIL_ADDRESS: EMAIL_ADDRESS_PL, WEBSITE: WEBSITE_PL, SOCIAL_ID: SOCIAL_ID_PL,
        NATIONALITY: { errorMessage: "" }, DESCRIPTION: DESCRIPTION_PL, BIRTHDATE: BIRTHDATE_PL, WEIGHT: WEIGHT_PL, HEIGHT: HEIGHT_PL,
        POSITION: { errorMessage: "" },
        CITY: CITY_PL,
        COLOR: COLOR_PL, FIRST_COLOR: FIRST_COLOR_PL, SECOND_COLOR: SECOND_COLOR_PL, NUMBER: NUMBER_PL,
        USER_TYPE: { errorMessage: "Niepoprawny rodzaj użytkownika" },
        PASSWORD: { errorMessage: "Hasło jest nieprawidłowe" }, PASSWORD_NEW: { errorMessage: "Podane hasło nie spełnia kryteriów akceptacji" }, PASSWORD_REPEAT: { errorMessage: "Hasła różnią się" },
        EDIT: { errorMessage: "" }, SAVE: { errorMessage: "" }, ABORT: { errorMessage: "" },
        PROFILE_ADDRESS: { errorMessage: "" }, SHOWN_DATA: { errorMessage: "" },
    },
    EN: {
        NAME: NAME_EN, SHORT_NAME: SHORT_NAME_EN, FIRST_NAME: FIRST_NAME_EN, LAST_NAME: LAST_NAME_EN, NICK_NAME: NICK_NAME_EN,
        EMAIL_ADDRESS: EMAIL_ADDRESS_EN, WEBSITE: WEBSITE_EN, SOCIAL_ID: SOCIAL_ID_EN,
        NATIONALITY: { errorMessage: "" }, DESCRIPTION: DESCRIPTION_EN, BIRTHDATE: BIRTHDATE_EN, WEIGHT: WEIGHT_EN, HEIGHT: HEIGHT_EN,
        POSITION: { errorMessage: "" },
        CITY: CITY_EN,
        COLOR: COLOR_EN, FIRST_COLOR: FIRST_COLOR_EN, SECOND_COLOR: SECOND_COLOR_EN, NUMBER: NUMBER_EN,
        USER_TYPE: { errorMessage: "Incorrect user type" },
        PASSWORD: { errorMessage: "Password is incorrect" }, PASSWORD_NEW: { errorMessage: "Password doesn't match criteria" }, PASSWORD_REPEAT: { errorMessage: "Passwords are not identical" },
        EDIT: { errorMessage: "" }, SAVE: { errorMessage: "" }, ABORT: { errorMessage: "" },
        PROFILE_ADDRESS: { errorMessage: "" }, SHOWN_DATA: { errorMessage: "" },
    }
};


export default FormFieldsErrorsDictionary;
