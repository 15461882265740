import React, { useEffect, useRef } from "react";


import {
    UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    PageContainerComponent, DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import FilesGroupsComponent from "@Pages/Common/DownloadPage/FilesGroups";


import {
    UseFilesGroups
} from "@Hooks/2.0";


import scrollToDomElement from "@Utilities/Scrolling";


import "./index.scss";


export interface FilesForDownloadPageComponentProps {}


export const FilesForDownloadPageComponent: React.FC<FilesForDownloadPageComponentProps> = () : JSX.Element | null => {


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, 250);
    }, []);


    const [FilesGroups, FilesGroupsError] = UseFilesGroups();


    const FILES_FOR_DOWNLOAD_HEADER_TEXT = UseGlobalsDictionaryPhrasesSet("FILES_FOR_DOWNLOAD_HEADER_TEXT");


    return (
        <PageContainerComponent id={"files-for-download-page"} forwardRef={ComponentRef}>

            <DefaultPageHeaderComponent headerText={FILES_FOR_DOWNLOAD_HEADER_TEXT} />

            <PageContentComponent id={"files-for-download"} cssClasses={"files-for-download"}>

                <FilesGroupsComponent
                    FilesGroups={FilesGroups}
                    FilesGroupsError={FilesGroupsError}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );

}


export default FilesForDownloadPageComponent;
