import React from "react";


import { Container, Row } from "react-bootstrap";


import {
    UseSponsors
} from "@Hooks/Api";


import HeadSponsorComponent from "@Parts/Results/HeadSponsor";
import ResultsBarListComponent from "@Parts/Results/List";


import "./index.scss";


type ApplicationResultsBar = {};


export const ApplicationResultsBarComponent: React.FC<ApplicationResultsBar> = () : JSX.Element | null => {


    const [HeadSponsors,] = UseSponsors(true);


    return (
        <Container fluid={true} className={"results-bar-component results-bar main-section-component"}>
            <Container className={"main-section-container"}>
                <Row className={"results-bar-content"}>

                    {HeadSponsors &&
                    <HeadSponsorComponent Sponsor={HeadSponsors[0]} bannerLocation={"LEFT"}/>
                    }

                    <ResultsBarListComponent xs={12} />

                    {HeadSponsors &&
		                <HeadSponsorComponent Sponsor={HeadSponsors[1]} bannerLocation={"RIGHT"}/>
                    }

                </Row>
            </Container>
        </Container>
    );
};


export default ApplicationResultsBarComponent;
