import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TeamsAndPlayersLeaguesHeaderComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Leagues/Header";
import TeamsAndPlayersLeaguesLeagueComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Leagues/League";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface SeasonsAndLeaguesLeaguesComponentProps {
    SelectedSeason: SeasonWithLeaguesShortInformationType | null;
    selectedLeagueID: number;
    selectedLeagueIDHandler: (leagueID: number) => void;
}


export const TeamsAndPlayersLeaguesComponent: React.FC<SeasonsAndLeaguesLeaguesComponentProps> = ({
    SelectedSeason,
    selectedLeagueID, selectedLeagueIDHandler,
}) : JSX.Element | null => {


    const NO_SEASON_SELECTED_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("NO_SEASON_SELECTED_MESSAGE_TEXT");
    const NO_LEAGUES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUES_PHRASE_TEXT");


    if (!SelectedSeason) {
        return <MessageComponent messageText={NO_SEASON_SELECTED_MESSAGE_TEXT} cssClasses={"margin-top-20 bordered padding-15"} />;
    }


    const {
        league_season_leagues: SelectedSeasonLeagues
    } = SelectedSeason;


    if (!SelectedSeasonLeagues?.length) {
        return <MessageComponent messageText={NO_LEAGUES_PHRASE_TEXT} cssClasses={"margin-top-20 bordered padding-15"} />;
    }


    const LeaguesList = SelectedSeasonLeagues.map(
        (League) => <TeamsAndPlayersLeaguesLeagueComponent
            key={League.id}
            League={League}
            selectedLeagueID={selectedLeagueID}
            selectedLeagueIDHandler={selectedLeagueIDHandler}
        />
    );


    return (
        <Col xs={12} className={"teams-and-players__content__seasons-and-leagues__leagues"}>
            <Row className={"teams-and-players__content__seasons-and-leagues__leagues__content"}>

                <TeamsAndPlayersLeaguesHeaderComponent />

                <Col xs={12} className={"teams-and-players__content__seasons-and-leagues__leagues__list"}>
                    <Row className={"teams-and-players__content__seasons-and-leagues__leagues__list__content"}>
                        {LeaguesList}
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersLeaguesComponent;
