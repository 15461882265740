import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TeamsAndPlayersTeamsListTeamComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Teams/List/Team";


import {
    TeamLeagueBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersTeamsListComponentProps {
    LeagueTeams: TeamLeagueBasicInformationType[];
    filtersActive: boolean;
}


export const TeamsAndPlayersTeamsListComponent: React.FC<TeamsAndPlayersTeamsListComponentProps> = ({
    LeagueTeams, filtersActive
}) : JSX.Element | null => {


    const NO_TEAMS_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("NO_TEAMS_MESSAGE_TEXT");
    const NO_TEAMS_MATCHING_CRITERIA_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("NO_TEAMS_MATCHING_CRITERIA_MESSAGE_TEXT");


    if (!(LeagueTeams?.length)) {
        return <MessageComponent
            messageText={filtersActive ? NO_TEAMS_MATCHING_CRITERIA_MESSAGE_TEXT: NO_TEAMS_MESSAGE_TEXT}
            cssClasses={"margin-top-15"}
        />;
    }


    const LeagueTeamsList = LeagueTeams.map((Team) =>
        <TeamsAndPlayersTeamsListTeamComponent key={Team.id} LeagueTeam={Team} />
    );


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__teams__list"}>
            <Row className={"teams-and-players__content__teams-and-players__teams__list__content"}>
                {LeagueTeamsList}
            </Row>
        </Col>
    )

};


export default TeamsAndPlayersTeamsListComponent;
