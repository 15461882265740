import {
    useEffect, useState
} from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_GAME_DETAILS_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    GameInterface
} from "@Interfaces/2.0";


export const UseGameDetails = (gameID: number) : [GameInterface | null, ErrorMessageInterface | null] => {


    const [GameDetails, setGameDetails] = useState<GameInterface | null>(null);
    const [GameDetailsError, setGameDetailsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!gameID) {
            return setGameDetailsError({ errorCode: 406, errorMessage: "" });
        }

        const getGameDetails = async () => {

            try {

                const response = await _restApiRequest(
                    API_GAME_DETAILS_PUBLIC_ENDPOINT_URL.replace("{GAME_ID}", gameID.toString())
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setGameDetails(responseContent);
                } else {
                    setGameDetailsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setGameDetailsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getGameDetails();

    }, [gameID]);

    useEffect(() => {
        return () => {
            setGameDetails(null);
            setGameDetailsError(null);
        };
    }, []);


    return [GameDetails, GameDetailsError];


};


export default UseGameDetails;
