import React from "react";


import {Col} from "react-bootstrap";


import stringTruncate from "truncate";


type ResultFooterProps = {
    gameInformation?: string | null;
    extraTimeStatus: boolean;
};


export const ResultFooterComponent: React.FC<ResultFooterProps> = ({
    gameInformation = "", extraTimeStatus
}): JSX.Element | null => {


    return (
        <Col xs={{ span: 12 }} className={"results-bar-item-footer"}>
            {extraTimeStatus && <span title={"Dogrywka"} className={"extra-time"}>D</span>}
            {!!gameInformation &&
	        <span>{stringTruncate(gameInformation, 30)}</span>
            }
        </Col>
    );

};


export default ResultFooterComponent;
