import {
    UseLanguage
} from "@States";


import {
    PlayerGameStatisticsTypes
} from "@Interfaces/2.0"


import {
    StatisticsTypesNames
} from "@Services/Consts";


export const UseStatisticTypeNames = () => {

    const activeLanguageCode = UseLanguage();

    return StatisticsTypesNames[activeLanguageCode];

}


export const UseStatisticTypeName = (
    statisticType: PlayerGameStatisticsTypes
) => {

    if (!statisticType) {
        return null;
    }

    const StatisticsTypes = UseStatisticTypeNames();

    return StatisticsTypes[statisticType];

};


export default UseStatisticTypeNames;
