import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterReboundEventDataComponentProps {
    eventOrderNr: string, quarterNumber: number;
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterReboundEventDataComponent: React.FC<QuarterReboundEventDataComponentProps> = ({
    eventOrderNr, quarterNumber,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.REBOUND) {
        return null;
    }

    if (!Event.eventReboundPlayerID) {
        return null;
    }


    const OffensiveReboundEvent = UseProgressEvent("PLAYER_O_REB");
    const DefensiveReboundEvent = UseProgressEvent("PLAYER_D_REB");

    if (!OffensiveReboundEvent || !DefensiveReboundEvent) {
        return null;
    }


    const ReboundEventPlayer = GamePlayers[Event.eventReboundPlayerID];

    if (!ReboundEventPlayer) {
        return null;
    }

    const {
        player_first_name: reboundPlayerFirstName,
        player_last_name: reboundPlayerLastName,
        player_nick_name: reboundPlayerNickName,
        player_slug: reboundPlayerSlug,
        player_pseudonymization_status: reboundPlayerPseudonymizationStatus
    } = ReboundEventPlayer;


    let reboundPlayerName = "";

    if (Event.eventReboundPlayerNumber) {
        reboundPlayerName += `${Event.eventReboundPlayerNumber} `;
    }

    if (reboundPlayerPseudonymizationStatus) {
        reboundPlayerName += reboundPlayerNickName;
    } else {
        reboundPlayerName += `${reboundPlayerFirstName[0]}. ${reboundPlayerLastName}`
    }


    let reboundEventName = "";

    if (Event.eventReboundType === "O") {
        reboundEventName = OffensiveReboundEvent.eventName.singular;
    } else if (Event.eventReboundType === "D") {
        reboundEventName = DefensiveReboundEvent.eventName.singular;
    }


    const offensiveReboundEventLabel = OffensiveReboundEvent.eventName.label;
    const defensiveReboundEventLabel = DefensiveReboundEvent.eventName.label;


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--rebound-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventReboundPlayerTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        <NavLink to={`/${PlayersRoute.routeURL}/${reboundPlayerSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name"}>
                            <span className={"event-player-name blocking-player"}>{reboundPlayerName}</span>
                        </NavLink>
                        {reboundEventName && <span className={"event-label"}>{reboundEventName}</span>}
                        <span className={"event-number"}>({defensiveReboundEventLabel}: {Event.eventReboundPlayerDefensiveRebounds} {offensiveReboundEventLabel}: {Event.eventReboundPlayerOffensiveRebounds})</span>
                        {Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    quarterNumber={quarterNumber}
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={[homeTeamID === Event.eventReboundPlayerTeamID ? "" : "offset-4", "order-2"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterReboundEventDataComponent;
