import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import ContainerSectionComponent from "@ContainersElements/MainSection";


import PlayerTeamComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Team";
import PlayerDataComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerMainDataComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerMainDataComponent: React.FC<PlayerMainDataComponentProps> = ({
        PlayerDescriptionData
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_active_season_team_data: PlayerTeam
    } = PlayerDescriptionData;


    return (
        <ContainerSectionComponent id={"player-details-main-data-component"}>

            <Col xs={12} className={"player-main-data-component"}>
                <Row className={"main-data-component-content"}>

                    <PlayerTeamComponent
                        PlayerTeam={PlayerTeam}
                    />

                    <PlayerDataComponent
                        PlayerDescriptionData={PlayerDescriptionData}
                    />

                </Row>
            </Col>

        </ContainerSectionComponent>
    );

};


export default PlayerMainDataComponent;
