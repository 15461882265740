import React, {useEffect, useState} from "react";

import { UseRoute } from "@Hooks/Routes";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { Redirect } from "react-router-dom";


import { SingleContentLoaderComponent } from "@LoadersElements";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import GameSummaryComponent from "@GameResultPageSummary";


import "./index.scss";


import { LeagueGameInterface } from "@Interfaces/Api";

import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";
import {UseGameDetails} from "@Hooks/Api/Requests";


interface GameResultComponentProps {
    match: any;
}


export const GameResultComponent : React.FC<GameResultComponentProps> = ({
    match
}) : JSX.Element | null => {


    const GamesRouteURL = UseRoute("GAMES").routeURL;


    const GAME_ID = match?.params?.gameId;


    const [GameDetails, GameDetailsError] = UseGameDetails(GAME_ID);


    const [GameData, setGameData] = useState<LeagueGameInterface | null>(null);
    const [GameDataError, setGameDataError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!GAME_ID) return;

        const GAME_SUMMARY_API_URL: string = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const getGameData = async () : Promise<void> => {

            try {

                const response = await _restApiRequest(
                    `${GAME_SUMMARY_API_URL}/leagues/games/${GAME_ID}/`
                );

                if (response.status === 200) {

                    const responseContent: any = await response.json();
                    setGameData(responseContent);

                } else {
                    setGameDataError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setGameDataError({ errorCode: 500, errorMessage: "" });
            }

        };

        getGameData();

    }, [GAME_ID]);


    useEffect(() => {
        return () => {
            setGameData(null);
            setGameDataError(null);
        };
    }, []);


    const [GameSummaryData, setGameSummaryData] = useState<ApiGameSummaryInterface | null>(null);
    const [GameSummaryDataError, setGameSummaryDataError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!GAME_ID || !GameData) return;

        const { league_game_status: gameStatus } = GameData;

        if (["NS", "POST", "CANC", "FT_WAL"].includes(gameStatus)) return;

        const API_URL: string = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const getGameSummaryStatistics = async () => {

            try {

                const response = await _restApiRequest(`${API_URL}/leagues/games/${GAME_ID}/statistics-by-events/`);

                if (response.ok) {

                    const responseContent = await response.json();
                    setGameSummaryData(responseContent);

                } else {
                    setGameSummaryDataError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setGameSummaryDataError({ errorCode: 500, errorMessage: "" });
            }

        };

        getGameSummaryStatistics();

    }, [GAME_ID, GameData]);


    useEffect(() => {
        return () => {
            setGameSummaryData(null);
            setGameSummaryDataError(null);
        }
    }, []);


    if (!GAME_ID || isNaN(+GAME_ID)) return <Redirect to={`/${GamesRouteURL}`} />;


    if (!!GameDataError) {

        if (GameDataError.errorCode === 404) {
            return <Redirect to={`/${GamesRouteURL}`} />;
        }

        return <ErrorMessageComponent messageText={GameDataError.errorMessage} />;

    }


    if (!!GameSummaryDataError) return <ErrorMessageComponent messageText={GameSummaryDataError.errorMessage} />;


    if (!GameData) return <SingleContentLoaderComponent />;


    return (
        <GameSummaryComponent
            GameData={GameData}
            GameSummaryStatistics={GameSummaryData}
        />
    );

};


export default GameResultComponent;
