import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import MessageComponent from "@MessagesElements/Content";


import PlayerLeaguesStatisticsStatisticsTableComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics/@StatisticsTable";


import {
    PlayerCareerStatisticsSeasonInterface
} from "@Interfaces/2.0";


import "./index.scss";

export interface PlayerDetailsStatisticsLeaguesStatisticsComponentProps {
    PlayerCareerStatistics: PlayerCareerStatisticsSeasonInterface[];
}


export const PlayerDetailsStatisticsLeaguesStatisticsComponent: React.FC<PlayerDetailsStatisticsLeaguesStatisticsComponentProps> = ({
    PlayerCareerStatistics
}) : JSX.Element | null => {


    const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT");


    if (!PlayerCareerStatistics) {
        return <MessageComponent messageText={DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT} cssClasses={"white"} />;
    }


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics"}>
            <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__content"}>

                <PlayerLeaguesStatisticsStatisticsTableComponent
                    PlayerCareerStatistics={PlayerCareerStatistics}
                    statisticsType={"S"}
                />

                <PlayerLeaguesStatisticsStatisticsTableComponent
                    PlayerCareerStatistics={PlayerCareerStatistics}
                    statisticsType={"M"}
                />

            </Row>
        </Col>
    )

};


export default PlayerDetailsStatisticsLeaguesStatisticsComponent;
