import React, { useEffect, useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts"


import {
    UseLeagueTeamsAndPlayers
} from "@Hooks/2.0";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamsAndPlayersContentSwitcherComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/_Switcher";
import TeamsAndPlayersTeamsContentComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Teams";
import TeamsAndPlayersPlayersContentComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players";


import "./index.scss";


export interface TeamsAndPlayersTeamsAndPlayersComponentProps {
    selectedSeasonID: number;
    selectedLeagueID: number;
}

export const TeamsAndPlayersTeamsAndPlayersComponent: React.FC<TeamsAndPlayersTeamsAndPlayersComponentProps> = ({
    selectedSeasonID, selectedLeagueID
}) : JSX.Element | null => {


    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");
    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const [LeagueTeamsAndPlayers, LeagueTeamsAndPlayersError] = UseLeagueTeamsAndPlayers(selectedLeagueID)


    const [selectedLeagueTeamsAndPlayersTab, setSelectedLeagueTeamsAndPlayersTab] = useState<1 | 2>(1);

    useEffect(() => {
        return () => {
            setSelectedLeagueTeamsAndPlayersTab(1);
        };
    }, []);


    if (!selectedSeasonID) {
        return null;
    }


    if (!selectedLeagueID) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"margin-top-15"} />;
    }


    if (LeagueTeamsAndPlayersError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }


    if (!LeagueTeamsAndPlayers) {
        return <SingleContentLoaderComponent cssClasses={"margin-top-20"} />;
    }


    const {
        league_teams: LeagueTeams, league_players: LeaguePlayers
    } = LeagueTeamsAndPlayers;


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players"}>
            <Row className={"teams-and-players__content__teams-and-players__content"}>

                <TeamsAndPlayersContentSwitcherComponent
                    selectedLeagueTeamsAndPlayersTab={selectedLeagueTeamsAndPlayersTab}
                    selectedLeagueTeamsAndPlayersTabHandler={setSelectedLeagueTeamsAndPlayersTab}
                />

                {selectedLeagueTeamsAndPlayersTab === 1 &&
                <TeamsAndPlayersTeamsContentComponent
                    LeagueTeams={LeagueTeams}
                />
                }

                {selectedLeagueTeamsAndPlayersTab === 2 &&
                <TeamsAndPlayersPlayersContentComponent
                    LeaguePlayers={LeaguePlayers}
                />
                }

            </Row>
        </Col>
    )


};


export default TeamsAndPlayersTeamsAndPlayersComponent;
