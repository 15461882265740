import React from "react";


import {
    Col
} from "react-bootstrap";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersSeasonsSeasonComponentProps {
    Season: SeasonWithLeaguesShortInformationType;
    selectedSeasonIDHandler: (seasonID: number) => void;
}


export const TeamsAndPlayersSeasonsSeasonComponent: React.FC<TeamsAndPlayersSeasonsSeasonComponentProps> = ({
    Season, selectedSeasonIDHandler
}) : JSX.Element | null => {

    if (!Season) {
        return null;
    }

    const {
        id: leagueSeasonID, league_season_name: leagueSeasonName
    } = Season;

    return (
        <Col xs={12} onClick={() => selectedSeasonIDHandler(leagueSeasonID)} className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__seasons__season"}>
            <span className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__seasons__season__name"}>{leagueSeasonName}</span>
        </Col>
    );

};


export default TeamsAndPlayersSeasonsSeasonComponent;
