import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    IMAGE_PLACEHOLDER
} from "@Images";


import {
    TeamLeagueBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersTeamsListTeamComponentProps {
    LeagueTeam: TeamLeagueBasicInformationType;
}


export const TeamsAndPlayersTeamsListTeamComponent: React.FC<TeamsAndPlayersTeamsListTeamComponentProps> = ({
    LeagueTeam
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!LeagueTeam) {
        return null;
    }


    const {
        team_league_team_name: teamLeagueName, team_league_team: Team
    } = LeagueTeam;

    const {
        team_slug: teamSlug,
        team_name: teamMainName, team_logo: teamLogo
    } = Team;


    const teamName = teamLeagueName || teamMainName;
    const teamURL = "/" + TeamsRoute.routeURL + "/" + teamSlug;


    return (
        <Col xs={4} lg={2} className={"teams-and-players__content__teams-and-players__teams__list__team"}>
            <Row className={"teams-and-players__content__teams-and-players__teams__list__team__content"}>

                <Col xs={12} className={"teams-and-players__content__teams-and-players__teams__list__team__logo"}>
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle}>
                        <img src={teamLogo || IMAGE_PLACEHOLDER} alt={teamLeagueName} />
                    </NavLink>
                </Col>

                <Col xs={12} className={"teams-and-players__content__teams-and-players__teams__list__team__name"}>
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersTeamsListTeamComponent;
