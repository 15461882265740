import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayerPlayedGamesHeaderLeaguesListLeagueComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames/Header/LeaguesList/League";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerPlayedGamesHeaderLeaguesListComponentProps {
    Leagues: PlayerCareerStatisticsLeagueInterface[];
    selectedLeagueIDHandler: (leagueID: number) => void;
}

export const PlayerPlayedGamesHeaderLeaguesListComponent: React.FC<PlayerPlayedGamesHeaderLeaguesListComponentProps> = ({
    Leagues, selectedLeagueIDHandler
}) : JSX.Element | null => {


    if (!Leagues?.length) {
        return null;
    }

    const LeaguesList = Leagues.map((League) =>
        <PlayerPlayedGamesHeaderLeaguesListLeagueComponent
            League={League}
            selectedLeagueIDHandler={selectedLeagueIDHandler}
        />
    );

    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__played-games__header__leagues__list"}>
            <Row className={"player-details__details-data__statistics-data__content__played-games__header__leagues__list__content"}>
                {LeaguesList}
            </Row>
        </Col>
    );

};


export default PlayerPlayedGamesHeaderLeaguesListComponent;
