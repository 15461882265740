import React from "react";


import {
    Col
} from "react-bootstrap";

import {
    FaFacebookF, FaInstagram, FaTwitter, FaYoutube, IoLogoTiktok
} from "react-icons/all";


const SOCIALS_ICONS : any = {
    facebook: FaFacebookF,
    twitter: FaTwitter,
    youtube: FaYoutube,
    instagram: FaInstagram,
    tiktok: IoLogoTiktok
};


import {
    SocialInterface
} from "@Interfaces/2.0";


export interface SocialsItemComponentProps {
    Social: SocialInterface;
}


export const SocialsItemComponent: React.FC<SocialsItemComponentProps> = ({
    Social
}) : JSX.Element | null => {


    if (!Social) {
        return null;
    }


    const {
        social_media_type: socialType,
        social_media_description: socialDescription,
        social_media_url: socialUrl
    } = Social;


    const SocialIcon = SOCIALS_ICONS[socialType];


    if (!socialUrl) {
        return null;
    }


    return (
        <Col as={`li`} lg={"auto"} className={`socials-list-item`}>
            <a href={socialUrl} title={socialDescription || ""} target={`_blank`} rel={`noreferrer noopener`} className={"socials-list-link"}>
                <SocialIcon />
            </a>
        </Col>
    );

};


export default SocialsItemComponent;
