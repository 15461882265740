import React from "react";


import {
    UseCommonDictionaryPhrasesSet,
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import NumberFormat from "react-number-format";


import SingleContentLoaderComponent from "@LoadersElements/SingleContent";

import ErrorMessageComponent from "@MessagesElements/Message/Error";


import {
    UsePlayerStatisticsInActiveSeasonLeague
} from "@Hooks/Api/Requests";


export interface PlayerInformationStatisticsComponentProps {
    playerID: number;
}


export const PlayerInformationStatisticsComponent: React.FC<PlayerInformationStatisticsComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const [PlayerStatistics, PlayerStatisticsError] = UsePlayerStatisticsInActiveSeasonLeague(playerID, "M");


    if (!playerID) {
        return null;
    }

    if (PlayerStatisticsError) {
        return <ErrorMessageComponent lg={{span: 4, offset: 1}} messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!PlayerStatistics) {
        return <SingleContentLoaderComponent loaderColor={"#FFF"} xs={12} lg={{span: 4, offset: 1}} cssClasses={"bordered white"} />;
    }


    const {
        league_game_team_player_points: playerMediumPoints,
        league_game_team_player_rebounds: playerMediumRebounds,
        league_game_team_player_ast: playerMediumAssists,
        league_game_team_player_eval: playerMediumEval
    } = PlayerStatistics;


    return (
        <Col xs={6} lg={{span: 4, offset: 1}} className={"player-information-statistics-component player-information-statistics"}>
            <Row className={"player-information-statistics-content"}>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`PPG`}</span>
                        <span className={"information-value"}>
                            {(!!playerMediumPoints || playerMediumPoints === 0) && <NumberFormat value={playerMediumPoints} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`RPG`}</span>
                        <span className={"information-value"}>
                            {(!!playerMediumRebounds || playerMediumRebounds === 0) && <NumberFormat value={playerMediumRebounds} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`APG`}</span>
                        <span className={"information-value"}>
                            {(!!playerMediumAssists || playerMediumAssists === 0)&& <NumberFormat value={playerMediumAssists} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

                <Col xs={6} lg={3} className={"player-information-component player-information player-statistic"}>
                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{`EVAL`}</span>
                        <span className={"information-value"}>
                            {(!!playerMediumEval || playerMediumEval === 0) && <NumberFormat value={playerMediumEval} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />}
                        </span>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerInformationStatisticsComponent;
