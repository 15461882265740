import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";

import {
    UsePlayerGlobalStatusName
} from "@Services/Consts";


export interface PlayerNameComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerNameComponent: React.FC<PlayerNameComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_name, player_league_status: playerLeagueStatus
    } = PlayerDescriptionData;


    const playerLeagueStatusName = UsePlayerGlobalStatusName(playerLeagueStatus);


    return (
        <Row className={"player-name"}>
            <Col xs={12} className={"player-full-name"}>
                {playerLeagueStatus !== 1 && <span className={"player-league-status"}>{playerLeagueStatusName}</span>}
                <span>{player_name}</span>
            </Col>
        </Row>
    );

};


export default PlayerNameComponent;
