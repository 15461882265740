import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_PLAYER_DESCRIPTION_ACTIVE_LEAGUE_STATISTICS_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    PlayerActiveSeasonLeagueStatisticsInterface
} from "@Interfaces/2.0";


export const UsePlayerStatisticsInActiveSeasonLeague = (
    playerID: number, dataType: "T" | "M" = "T"
) : [PlayerActiveSeasonLeagueStatisticsInterface | null, ErrorMessageInterface | null] => {


    const [PlayerStatistics, setPlayerStatistics] = useState<PlayerActiveSeasonLeagueStatisticsInterface | null>(null);
    const [PlayerStatisticsError, setPlayerStatisticsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!playerID) {
            return setPlayerStatisticsError({ errorCode: 400, errorMessage: "" });
        }

        const getPlayerStatistics = async () => {

            const API_URL = API_PLAYER_DESCRIPTION_ACTIVE_LEAGUE_STATISTICS_PUBLIC_ENDPOINT_URL.replace("{PLAYER_ID}", playerID.toString());

            try {

                const response = await _restApiRequest(
                    `${API_URL}?data_type=${dataType}`
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setPlayerStatistics(responseContent);
                } else {
                    setPlayerStatisticsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setPlayerStatisticsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getPlayerStatistics();

    }, [playerID]);


    useEffect(() => {
        return () => {
            setPlayerStatistics(null);
            setPlayerStatisticsError(null);
        };
    }, []);


    return [PlayerStatistics, PlayerStatisticsError];

};


export default UsePlayerStatisticsInActiveSeasonLeague;
