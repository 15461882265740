import { useState, useEffect } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_SOCIALS_LIST_PUBLIC_ENDPOINT_URL,
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    SocialInterface
} from "@Interfaces/2.0";


export const UseSocials = () : [SocialInterface[] | null, ErrorMessageInterface | null] => {


    const [Socials, setSocials] = useState<SocialInterface[] | null>(null);
    const [SocialsError, setSocialsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const getSocials = async () => {

            try {

                const response = await _restApiRequest(API_SOCIALS_LIST_PUBLIC_ENDPOINT_URL);

                if (response.ok) {
                    const responseContent = await response.json();
                    setSocials(responseContent);
                } else {
                    setSocialsError({ errorCode: 500, errorMessage: "" });
                }

            } catch (e) {
                setSocialsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getSocials();

    }, []);

    useEffect(() => {
        return () => {
            setSocials(null);
            setSocialsError(null);
        };
    }, []);


    return [Socials, SocialsError];

};


export default UseSocials;
