import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_HOME_PAGE_SLIDES_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    HomePageSlideInterface
} from "@Interfaces/2.0";


export const UseHomePageSlides = () : [HomePageSlideInterface[] | null, ErrorMessageInterface | null] => {


    const [HomePageSlides, setHomePageSlides] = useState<HomePageSlideInterface[] | null>(null);
    const [HomePageSlidesError, setHomePageSlidesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        const getHomePageSlides = async () => {

            try {

                const response = await _restApiRequest(
                    `${API_HOME_PAGE_SLIDES_PUBLIC_ENDPOINT_URL}?get_only_active=1`
                )

                if (response.ok) {
                    const responseContent = await response.json();
                    setHomePageSlides(responseContent);
                } else {
                    setHomePageSlidesError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setHomePageSlidesError({ errorCode: 500, errorMessage: "" });
            }

        };

        getHomePageSlides();

    }, []);

    useEffect(() => {
        return () => {
            setHomePageSlides(null);
            setHomePageSlidesError(null);
        };
    }, []);


    return [HomePageSlides, HomePageSlidesError];

};


export default UseHomePageSlides;
