import React from "react";


import {
    UseErrorMessage, UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    PageContentComponent
} from "@ContentElements";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import {
    UseSponsors
} from "@Hooks/Api";

import PartnersListPartnerComponent from "@Pages/Common/PartnersPage/PartnersList/Partner";


import "./index.scss";


export interface PartnersListComponentProps {}


export const PartnersListComponent: React.FC<PartnersListComponentProps> = () => {


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT = UseGlobalsDictionaryPhrasesSet("PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT");


    const [Sponsors, SponsorsError] = UseSponsors();


    if (SponsorsError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR_MESSAGE} />;
    }

    if (!Sponsors) {
        return <SingleContentLoaderComponent cssClasses={"margin-top-45"} />;
    }


    if (!Sponsors?.length) {
        return <MessageComponent messageText={PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;
    }


    const PartnersList = Sponsors.map((Sponsor, i) =>
        <PartnersListPartnerComponent key={i} Sponsor={Sponsor} />
    );


    return (
        <PageContentComponent cssClasses={"partners-page__partners-list"}>

            {PartnersList}

        </PageContentComponent>
    )

};


export default PartnersListComponent;
