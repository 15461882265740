import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface QuarterEventTeamPointsComponentPoints {
    pointsNumber: number;
    showPoints?: boolean;
    isThrowingStatus?: boolean;
    cssClasses?: string | string[];
}


export const QuarterEventTeamPointsComponent: React.FC<QuarterEventTeamPointsComponentPoints> = ({
    pointsNumber, showPoints = false, isThrowingStatus, cssClasses = ""
}) => {


    if (cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }


    return (
        <Col xs={4} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__points" + (isThrowingStatus ? " throwing" : "") + (cssClasses ? ` ${cssClasses}` : "")}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__points-and-time__points__content"}>
                {showPoints && <span className={"points-number"}>{pointsNumber}</span>}
            </Row>
        </Col>
    );

};


export default QuarterEventTeamPointsComponent;
