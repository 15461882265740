import React from "react";


import {
    PlayerCareerStatisticsSeasonInterface
} from "@Interfaces/2.0";


import {
    Col, Row
} from "react-bootstrap";


import PlayerStatisticsTableLeaguesListHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics/@StatisticsTable/List/Header";
import PlayerStatisticsTableLeaguesListLeagueComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics/@StatisticsTable/List/League";


import "./index.scss";


export interface PlayerStatisticsTableLeaguesListComponentProps {
    PlayerCareerStatistics: PlayerCareerStatisticsSeasonInterface[];
    statisticsType: "M" | "S";
}


export const PlayerStatisticsTableLeaguesListComponent: React.FC<PlayerStatisticsTableLeaguesListComponentProps> = ({
    PlayerCareerStatistics, statisticsType
}) : JSX.Element | null => {


    if (!PlayerCareerStatistics) {
        return null;
    }


    const LeaguesList = [];

    for (let Season of PlayerCareerStatistics) {

        const {
            league_season_name: seasonName, league_season_leagues: SeasonLeagues
        } = Season;

        if (!SeasonLeagues) {
            continue;
        }

        for (let League of SeasonLeagues) {
            LeaguesList.push(
                <PlayerStatisticsTableLeaguesListLeagueComponent
                    League={League}
                    seasonName={seasonName}
                    statisticsType={statisticsType}
                />
            );
        }

    }


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list"}>
            <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__content"}>

                <PlayerStatisticsTableLeaguesListHeaderComponent />

                {LeaguesList}

            </Row>
        </Col>
    );

};


export default PlayerStatisticsTableLeaguesListComponent;
