import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import NumberFormat from "react-number-format";


import countAgeByDate from "@Utilities/Counting/Dates/AgeByDate";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerInformationDescriptionComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerInformationDescriptionComponent: React.FC<PlayerInformationDescriptionComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    const HEIGHT_WORD_TEXT = UseCommonDictionaryPhrasesSet("HEIGHT_WORD_TEXT");
    const WEIGHT_WORD_TEXT = UseCommonDictionaryPhrasesSet("WEIGHT_WORD_TEXT");
    const AGE_WORD_TEXT = UseCommonDictionaryPhrasesSet("AGE_WORD_TEXT");
    const NATIONALITY_WORD_TEXT = UseCommonDictionaryPhrasesSet("NATIONALITY_WORD_TEXT");


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_height: playerHeight, player_weight: playerWeight,
        player_birthdate: playerBirthdate, player_nationality: playerNationality,
    } = PlayerDescriptionData;

    const playerAge = countAgeByDate(playerBirthdate);


    return (
        <Col xs={6} lg={6} className={"player-information-description-component player-information-description"}>
            <Row className={"player-information-description-content"}>

                <Col xs={6} className={"player-information-component player-information player-description"}>

                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{HEIGHT_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerHeight && <span>{playerHeight} cm</span>
                            }
                        </span>
                    </Row>

                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{WEIGHT_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerWeight && <>
                            <NumberFormat value={playerWeight} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                            <span> kg</span>
                            </>}
                        </span>
                    </Row>

                </Col>

                <Col xs={6} className={"player-information-component player-information player-description"}>

                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{AGE_WORD_TEXT}</span>
                        <span className={"information-value"}>
                            {!!playerAge && <span>{playerAge} lat</span>}
                        </span>
                    </Row>

                    <Row className={"player-information-content"}>
                        <span className={"information-label"}>{NATIONALITY_WORD_TEXT}</span>
                        <span className={"information-value"}>{playerNationality || ""}</span>
                    </Row>

                </Col>

            </Row>
        </Col>
    );

};


export default PlayerInformationDescriptionComponent;
