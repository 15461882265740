import React, { useEffect, useState } from "react";


import TeamsAndPlayersSeasonsAndLeaguesComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues";
import TeamsAndPlayersTeamsAndPlayersComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersPageDataComponentProps {
    SeasonsWithLeagues: SeasonWithLeaguesShortInformationType[];
}


export const TeamsAndPlayersPageDataComponent: React.FC<TeamsAndPlayersPageDataComponentProps> = ({
    SeasonsWithLeagues
}) : JSX.Element | null => {


    const SeasonsByIDs = Object.fromEntries(
        SeasonsWithLeagues.map((season) => [season.id, season])
    );


    const _storedSelectedSeasonID = localStorage.getItem("_storedSelectedSeasonID");
    const _storedSelectedLeagueID = localStorage.getItem("_storedSelectedLeagueID");


    const [selectedSeasonID, setSelectedSeasonID] = useState<number>(Number(_storedSelectedSeasonID));
    const [selectedLeagueID, setSelectedLeagueID] = useState<number>(Number(_storedSelectedLeagueID));


    const selectedSeasonIDHandler = (selectedSeasonID: number) => {
        localStorage.setItem("_storedSelectedSeasonID", selectedSeasonID.toString());
        setSelectedSeasonID(selectedSeasonID);
    };

    const  selectedLeagueIDHandler = (selectedLeagueID: number) => {
        localStorage.setItem("_storedSelectedLeagueID", selectedLeagueID.toString());
        setSelectedLeagueID(selectedLeagueID);
    };


    /**
     * Set Season to not selected if Season ID not in available Seasons - every Season ID change
     * */
    useEffect(() => {
        if (selectedSeasonID === 0) {
            return;
        }
        if (!SeasonsByIDs || !(selectedSeasonID in SeasonsByIDs)) {
            localStorage.removeItem("_storedSelectedSeasonID");
            localStorage.removeItem("_storedSelectedLeagueID");
            setSelectedSeasonID(0);
            setSelectedLeagueID(0);
        }
    }, [selectedSeasonID]);

    /**
     * Set First available Season if not selected or stored - only at first render
     * */
    useEffect(() => {
        if (selectedSeasonID !== 0 || !SeasonsWithLeagues?.length) {
            return
        }
        const {
            id: seasonID, league_season_leagues: SeasonLeagues
        } = SeasonsWithLeagues[0];
        setSelectedSeasonID(seasonID);
        localStorage.setItem("_storedSelectedSeasonID", seasonID.toString());
        if (SeasonLeagues && SeasonLeagues.length) {
            setSelectedLeagueID(SeasonLeagues[0].id);
        }
    }, []);

    useEffect(() => {
        if (selectedLeagueID === 0) {
            return;
        }
        if (!SeasonsByIDs || !selectedSeasonID || !SeasonsByIDs[selectedSeasonID]) {
            localStorage.removeItem("_storedSelectedLeagueID");
            setSelectedLeagueID(0);
        }
        const {
            league_season_leagues: SelectedSeasonLeagues
        } = SeasonsByIDs[selectedSeasonID];
        if (!SelectedSeasonLeagues || !SelectedSeasonLeagues.filter((League) => League.id === selectedLeagueID).length) {
            localStorage.removeItem("_storedSelectedLeagueID");
            setSelectedLeagueID(0);
        }
    }, [selectedLeagueID]);


    return (
        <>

            <TeamsAndPlayersSeasonsAndLeaguesComponent
                SeasonsWithLeagues={SeasonsWithLeagues}
                selectedSeasonID={selectedSeasonID}
                selectedSeasonIDHandler={selectedSeasonIDHandler}
                selectedLeagueID={selectedLeagueID}
                selectedLeagueIDHandler={selectedLeagueIDHandler}
            />

            <TeamsAndPlayersTeamsAndPlayersComponent
                selectedSeasonID={selectedSeasonID}
                selectedLeagueID={selectedLeagueID}
            />

        </>
    );

};


export default TeamsAndPlayersPageDataComponent;
