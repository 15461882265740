import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayerStatisticsTableLeaguesListComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics/@StatisticsTable/List";
import PlayerStatisticsTableLeaguesHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics/@StatisticsTable/Header";


import {
    PlayerCareerStatisticsSeasonInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerLeaguesStatisticsStatisticsTableComponentProps {
    PlayerCareerStatistics: PlayerCareerStatisticsSeasonInterface[];
    statisticsType: "M" | "S";
}


export const PlayerLeaguesStatisticsStatisticsTableComponent: React.FC<PlayerLeaguesStatisticsStatisticsTableComponentProps> = ({
    PlayerCareerStatistics, statisticsType
}) => {


    if (!PlayerCareerStatistics) {
        return null;
    }


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table"}>
            <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__content"}>

                <PlayerStatisticsTableLeaguesHeaderComponent
                    statisticsType={statisticsType}
                />

                <PlayerStatisticsTableLeaguesListComponent
                    PlayerCareerStatistics={PlayerCareerStatistics}
                    statisticsType={statisticsType}
                />

            </Row>
        </Col>
    );


};


export default PlayerLeaguesStatisticsStatisticsTableComponent;
