import React, {useEffect, useState} from "react";


import {UseLoggedPlayerProfileData} from "@Hooks/Api/Requests";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import PlayerUserProfilePageComponent from "@Pages/User/Authenticated/Profile/Player";
import MainContentLoaderComponent from "@LoadersElements/MainContent";


export interface UserProfilePageContentComponentProps {
    userToken: string;
}


export const UserProfilePageContentComponent: React.FC<UserProfilePageContentComponentProps> = ({
    userToken
}) : JSX.Element | null => {


    const [updatePlayerDataTrigger, setUpdatePlayerDataTrigger] = useState(false);

    const [PlayerProfileData, PlayerProfileDataError] = UseLoggedPlayerProfileData(userToken, updatePlayerDataTrigger);

    useEffect(() => {

        if (updatePlayerDataTrigger) {
            return setUpdatePlayerDataTrigger(false);
        }

    }, [updatePlayerDataTrigger]);


    if (!userToken) {
        return null;
    }


    if (PlayerProfileDataError) {
        return <ErrorMessageComponent messageText={PlayerProfileDataError.errorMessage} cssClasses={"no-margin-top"} />;
    }


    if (!PlayerProfileData) {
        return <MainContentLoaderComponent />;
    }

    return (
        <PlayerUserProfilePageComponent
            Player={PlayerProfileData}
            playerUpdateTriggerHandler={setUpdatePlayerDataTrigger}
        />
    )

};


export default UserProfilePageContentComponent;
