import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface TeamsAndPlayersDataSwitcherComponentProps {
    selectedLeagueTeamsAndPlayersTab: 1 | 2;
    selectedLeagueTeamsAndPlayersTabHandler: (tab: 1 | 2) => void;
}


export const TeamsAndPlayersContentSwitcherComponent: React.FC<TeamsAndPlayersDataSwitcherComponentProps> = ({
    selectedLeagueTeamsAndPlayersTab, selectedLeagueTeamsAndPlayersTabHandler
}) : JSX.Element | null => {


    const TEAMS_WORD_TEXT = UseCommonDictionaryPhrasesSet("TEAMS_WORD_TEXT");
    const PLAYERS_WORD_TEXT = UseCommonDictionaryPhrasesSet("PLAYERS_WORD_TEXT");


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__content-switcher"}>
            <Row className={"teams-and-players__content__teams-and-players__content-switcher__content"}>

                <Col
                    xs={6} lg={"auto"}
                    className={"teams-and-players__content__teams-and-players__content-switcher__button" + (selectedLeagueTeamsAndPlayersTab === 1 ? " selected" : "")}
                    onClick={() => selectedLeagueTeamsAndPlayersTabHandler(1)}
                >
                    <span>{TEAMS_WORD_TEXT}</span>
                </Col>

                <Col
                    xs={6} lg={"auto"}
                    className={"teams-and-players__content__teams-and-players__content-switcher__button" + (selectedLeagueTeamsAndPlayersTab === 2 ? " selected" : "")}
                    onClick={() => selectedLeagueTeamsAndPlayersTabHandler(2)}
                >
                    <span>{PLAYERS_WORD_TEXT}</span>
                </Col>

            </Row>
        </Col>
    )

};


export default TeamsAndPlayersContentSwitcherComponent;
