import {
    UseLoggedUser
} from "@States";


export const UseLoggedUserToken = () : string | null => {

    const LoggedUser = UseLoggedUser();

    if (!LoggedUser) {
        return null;
    }

    return LoggedUser.userToken;

};


export default UseLoggedUserToken;
