import {
    UseLanguage
} from "@States";

import {
    PlayerSystemStatusType
} from "@Interfaces/2.0";

import {
    PlayerGlobalStatusNames
} from "@Services/Consts";


export const UsePlayerGlobalStatusName = (
    status: PlayerSystemStatusType
) : string | null => {

    const activeLanguageCode = UseLanguage();

    if (!status || !activeLanguageCode) {
        return null;
    }

    return PlayerGlobalStatusNames[activeLanguageCode][status];

}


export default UsePlayerGlobalStatusName;
