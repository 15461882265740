import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_FILES_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    LeagueTeamFileInterface
} from "@Interfaces/Api";


export const UseTeamFiles = (teamID: number) : [LeagueTeamFileInterface[] | null, ErrorMessageInterface | null] => {

    const [TeamFiles, setTeamFiles] = useState<LeagueTeamFileInterface[] | null>(null);
    const [TeamFilesError, setTeamFilesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!teamID) {
            return setTeamFilesError({ errorCode: 400, errorMessage: "" });
        }

        const getTeamFiles = async () => {

            const Headers: HeadersInit = { "X-user-id": "122", "X-api-token": "aaa" };

            try {

                // const response = await _restApiRequest(
                //     `${API_TEAM_FILES_LIST_ENDPOINT_URL}?team_id=${teamID}`,
                //     null,
                //     "GET",
                //     true,
                // );
                // console.log(response);
                // if (response.ok) {
                //     console.log(await response.json());
                // } else {
                //     setTeamFilesError({ errorCode: response.status, errorMessage: "" });
                // }

            } catch (e) {
                setTeamFilesError({ errorCode: 500, errorMessage: "" });
                console.log(e);
            }
        };

        getTeamFiles();

    }, [teamID]);

    useEffect(() => {
        return () => {
            setTeamFiles(null);
            setTeamFilesError(null);
        };
    }, []);

    return [TeamFiles, TeamFilesError];

};


export default UseTeamFiles;
