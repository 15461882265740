import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";

import {
    UseSeasonsWithLeagues
} from "@Hooks/2.0";


import {
    PageContentComponent
} from "@ContentElements";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import ErrorMessageComponent from "@MessagesElements/Message/Error";
import MessageComponent from "@MessagesElements/Content";


import TeamsAndPlayersPageDataComponent from "@TeamsPages/List/Content/Data";


import "./index.scss";


export interface TeamsAndPlayersPageContentComponentProps {}


export const TeamsAndPlayersPageContentComponent: React.FC<TeamsAndPlayersPageContentComponentProps> = ({}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const NO_LEAGUES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUES_PHRASE_TEXT");


    const [SeasonsWithLeagues, SeasonsWithLeaguesError] = UseSeasonsWithLeagues();


    if (SeasonsWithLeaguesError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!SeasonsWithLeagues) {
        return <SingleContentLoaderComponent cssClasses={"margin-top-40"} />;
    }

    if (!SeasonsWithLeagues?.length) {
        return <MessageComponent messageText={NO_LEAGUES_PHRASE_TEXT} cssClasses={"margin-top-40"} />;
    }


    return (
        <PageContentComponent cssClasses={"teams-and-players__content"}>

            <TeamsAndPlayersPageDataComponent
                SeasonsWithLeagues={SeasonsWithLeagues}
            />

        </PageContentComponent>
    );

};


export default TeamsAndPlayersPageContentComponent;
