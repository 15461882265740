import _ from "lodash";


import {
    PlayerPublicBasicInformationType,
    TeamLeaguePlayerBasicInformationType
} from "@Interfaces/2.0";


export interface PlayerPublicBasicInformationTypeWithSortField extends PlayerPublicBasicInformationType {
    player_sort_field: string;
}


export interface LeaguePlayersSortedByLettersInterface {
    [key: string]: PlayerPublicBasicInformationTypeWithSortField[];
}

export const sortLeaguePlayersListByPlayerName = (
    LeaguePlayers: TeamLeaguePlayerBasicInformationType[]
) : LeaguePlayersSortedByLettersInterface => {

    const SortedLeaguePlayers: LeaguePlayersSortedByLettersInterface = {};

    for (let LeaguePlayer of LeaguePlayers) {

        const {
            league_team_player_player: TeamPlayer
        } = LeaguePlayer;

        if (!TeamPlayer) {
            continue;
        }

        const {
            team_player_player: Player
        } = TeamPlayer;

        if (!Player) {
            continue;
        }

        const {
            player_name: playerName
        } = Player;

        let sortLetter = playerName[0];

        if (!sortLetter) {
            continue;
        }

        sortLetter = sortLetter.toUpperCase();

        switch (sortLetter) {
            case "Ą":
                sortLetter = "A"; break;
            case "Ć":
                sortLetter = "C"; break;
            case "Ę":
                sortLetter = "E"; break;
            case "Ł":
                sortLetter = "L"; break;
            case "Ó":
                sortLetter = "O"; break;
            case "Ś":
                sortLetter = "S"; break;
            case "Ź":
                sortLetter = "Z"; break;
            case "Ż":
                sortLetter = "Z"; break;
        }

        if (!SortedLeaguePlayers[sortLetter]) {
            // @ts-ignore
            SortedLeaguePlayers[sortLetter] = [];
        }

        const playerSortField = playerName.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, '')
            .replaceAll("ł", "l")

        SortedLeaguePlayers[sortLetter].push({ ...Player, player_sort_field: playerSortField });

    }

    for (let [L, SortedPlayers] of Object.entries(SortedLeaguePlayers)) {
        SortedLeaguePlayers[L] = _.sortBy(SortedPlayers, "player_sort_field");
    }

    return SortedLeaguePlayers;

};
