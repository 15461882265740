import ApplicationStore, {
    AuthenticationActionTypes,
    ApplicationActionTypes,
} from "@Store";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import UserModel from "@Models/Users/User";


export type RestApiRequestType = (
    requestURL: string,
    requestData?: FormData | null,
    requestMethod?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    userToken?: string,
    showLoader?: boolean,
    requestHeaders?: HeadersInit,
) => Promise<Response>;


export const _restApiRequest: RestApiRequestType = async (
    requestURL, requestData, requestMethod = "GET", userToken, showLoader = true, requestHeaders
) => {


    let ApiResponse: Response;


    if (showLoader) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: true
        });
    }


    const RequestHeaders: HeadersInit = new Headers();

    const ApiRequestInit: RequestInit = {
        method: requestMethod,
        headers: RequestHeaders
    };


    if (!!requestMethod && requestMethod !== "GET") ApiRequestInit.body = requestData;

    if (userToken) {
        RequestHeaders.set("X-PLAYER-API-TOKEN", userToken);
    }

    try {

        ApiResponse = await fetch(requestURL, ApiRequestInit);

        if (userToken) {

            const userApiToken = ApiResponse.headers.get("X-Player-Api-Token");

            if (userApiToken) {
                ApplicationStore.dispatch({
                    type: AuthenticationActionTypes.LOG_USER_IN,
                    user: { userToken: userApiToken },
                });
            } else {
                ApplicationStore.dispatch({
                    type: AuthenticationActionTypes.LOG_USER_OUT,
                });
            }

        }

    } catch (e) {

        const ApiResponseContent: ErrorMessageInterface = {
            errorCode: 500, errorMessage: String(e)
        };

        ApiResponse = new Response(
            JSON.stringify(ApiResponseContent),
            {status: 500, statusText: String(e)}
        );

    }

    if (showLoader) {
        ApplicationStore.dispatch({
            type: ApplicationActionTypes.API_REQUEST_STATUS_ACTION,
            status: false
        });
    }

    return ApiResponse;

};


export default _restApiRequest;
