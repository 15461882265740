import { useEffect, useState } from "react";


import {
    API_PLAYER_BASIC_INFORMATION_PUBLIC_ENDPOINT_URL,
    API_PLAYER_DESCRIPTION_INFORMATION_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _restApiRequest from "@Services/HTTP/Requests/Request";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    PlayerPublicProfileBasicDescriptionType,
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export const UsePlayerDescriptionInformation = (
    playerID: number, basicInformation = false
) : [PlayerPublicProfileFullDescriptionType | null, ErrorMessageInterface | null] => {


    const [playerDescription, setPlayerDescription] = useState<PlayerPublicProfileFullDescriptionType | null>(null);
    const [playerDescriptionError, setPlayerDescriptionError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!playerID) {
            return setPlayerDescriptionError({ errorCode: 400, errorMessage: "" });
        }

        const API_URL = basicInformation ? API_PLAYER_BASIC_INFORMATION_PUBLIC_ENDPOINT_URL : API_PLAYER_DESCRIPTION_INFORMATION_PUBLIC_ENDPOINT_URL;

        const getPlayerDescription = async () => {

            try {

                const response = await _restApiRequest(
                    API_URL.replace("{PLAYER_ID}", playerID.toString())
                );

                if (response.ok) {
                    const responseContent: PlayerPublicProfileFullDescriptionType = await response.json();
                    setPlayerDescription(responseContent);
                } else {
                    setPlayerDescriptionError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setPlayerDescriptionError({ errorCode: 500, errorMessage: "" });
            }

        };

        getPlayerDescription();

    }, [playerID]);


    useEffect(() => {
        return () => {
            setPlayerDescription(null);
            setPlayerDescriptionError(null);
        };
    }, []);


    return [playerDescription, playerDescriptionError];

};


export default UsePlayerDescriptionInformation;
