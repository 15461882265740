import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamsAndPlayersTeamsSearchBarComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Teams/Search";
import TeamsAndPlayersTeamsListComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Teams/List";


import {
    TeamLeagueBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersTeamsContentComponentProps {
    LeagueTeams: TeamLeagueBasicInformationType[];
}

export const TeamsAndPlayersTeamsContentComponent: React.FC<TeamsAndPlayersTeamsContentComponentProps> = ({
    LeagueTeams
}) => {


    const [searchLeagueTeamsQuery, setSearchLeagueTeamsQuery] = useState("");

    const searchLeagueTeamsQueryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLeagueTeamsQuery(e.target.value);
    };

    useEffect(() => {
        return () => {
            setSearchLeagueTeamsQuery("");
        };
    }, []);


    const FilteredLeagueTeams = LeagueTeams.filter((Team) => {
         const {
             team_league_team_name: teamName, team_league_team_short_name: teamShortName
         } = Team;
         return (
             teamName.toLowerCase().includes(searchLeagueTeamsQuery.toLocaleLowerCase())
             ||
             (teamShortName && teamShortName.toLowerCase().includes(searchLeagueTeamsQuery.toLowerCase()))
         );
    });


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__teams"}>
            <Row className={"teams-and-players__content__teams-and-players__teams__content"}>

                <TeamsAndPlayersTeamsSearchBarComponent
                    searchLeagueTeamsQuery={searchLeagueTeamsQuery}
                    searchLeagueTeamsQueryHandler={searchLeagueTeamsQueryHandler}
                />

                <TeamsAndPlayersTeamsListComponent
                    LeagueTeams={FilteredLeagueTeams}
                    filtersActive={!!searchLeagueTeamsQuery}
                />

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersTeamsContentComponent;
