import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import PlayerCareerStatisticsTypes from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/@Types/Statistics";


import "./index.scss";


export interface PlayersDetailsStatisticsDataSwitcherComponentProps {
    selectedCareerStatisticsType: PlayerCareerStatisticsTypes;
    selectedCareerStatisticsTypeHandler: (statisticsType: PlayerCareerStatisticsTypes) => void;
}


export const PlayersDetailsStatisticsDataSwitcherComponent: React.FC<PlayersDetailsStatisticsDataSwitcherComponentProps> = ({
    selectedCareerStatisticsType, selectedCareerStatisticsTypeHandler
}) : JSX.Element | null => {


    const STATISTICS_WORD_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_WORD_TEXT");
    const PLAYED_GAMES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYED_GAMES_PHRASE_TEXT");
    const BEST_SCORES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("BEST_SCORES_PHRASE_TEXT");


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__statistics-switcher"}>
            <Row className={"player-details__details-data__statistics-data__statistics-switcher__content"}>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__statistics-switcher__button" + (selectedCareerStatisticsType === PlayerCareerStatisticsTypes.LEAGUES_STATISTICS ? " selected" : "")}>
                    <span onClick={() => selectedCareerStatisticsTypeHandler(PlayerCareerStatisticsTypes.LEAGUES_STATISTICS)}>{STATISTICS_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__statistics-switcher__button" + (selectedCareerStatisticsType === PlayerCareerStatisticsTypes.PLAYER_PLAYED_GAMES ? " selected" : "")}>
                    <span onClick={() => selectedCareerStatisticsTypeHandler(PlayerCareerStatisticsTypes.PLAYER_PLAYED_GAMES)}>{PLAYED_GAMES_PHRASE_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__statistics-switcher__button" + (selectedCareerStatisticsType === PlayerCareerStatisticsTypes.PLAYER_BEST_SCORES ? " selected" : "")}>
                    <span onClick={() => selectedCareerStatisticsTypeHandler(PlayerCareerStatisticsTypes.PLAYER_BEST_SCORES)}>{BEST_SCORES_PHRASE_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayersDetailsStatisticsDataSwitcherComponent;
