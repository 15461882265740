import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import PlayerDetailsStatisticsLeaguesStatisticsComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/LeaguesStatistics";
import PlayerDetailsStatisticsPlayedGamesComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames";
import PlayerDetailsStatisticsPlayerBestScoresComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores";


import {
    PlayerCareerStatisticsTypes
} from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/@Types/Statistics";


import {
    PlayerCareerStatisticsInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayersDetailsStatisticsDataContentComponentProps {
    PlayerCareerStatistics: PlayerCareerStatisticsInterface | null;
    PlayerCareerStatisticsError: ErrorMessageInterface | null;
    selectedCareerStatisticsType: PlayerCareerStatisticsTypes;
}


export const PlayersDetailsStatisticsDataContentComponent: React.FC<PlayersDetailsStatisticsDataContentComponentProps> = ({
    PlayerCareerStatistics, PlayerCareerStatisticsError,
    selectedCareerStatisticsType
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    if (PlayerCareerStatisticsError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }


    if (!PlayerCareerStatistics) {
        return <SingleContentLoaderComponent loaderColor={"#FFF"} />;
    }


    const {
        seasons: PlayerCareerSeasonsStatistics,
        best_scores: PlayerCareerBestScores,
    } = PlayerCareerStatistics;


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content"}>
            <Row className={"player-details__details-data__statistics-data__content__content"}>

                {selectedCareerStatisticsType === PlayerCareerStatisticsTypes.LEAGUES_STATISTICS &&
                <PlayerDetailsStatisticsLeaguesStatisticsComponent
                    PlayerCareerStatistics={PlayerCareerSeasonsStatistics}
                />
                }

                {selectedCareerStatisticsType === PlayerCareerStatisticsTypes.PLAYER_PLAYED_GAMES &&
                <PlayerDetailsStatisticsPlayedGamesComponent
                    PlayerCareerStatistics={PlayerCareerSeasonsStatistics}
                />
                }

                {selectedCareerStatisticsType === PlayerCareerStatisticsTypes.PLAYER_BEST_SCORES &&
                <PlayerDetailsStatisticsPlayerBestScoresComponent
                    PlayerSeasonsStatistics={PlayerCareerSeasonsStatistics}
                    PlayerCareerBestScores={PlayerCareerBestScores}
                />
                }

            </Row>
        </Col>
    );

};


export default PlayersDetailsStatisticsDataContentComponent;
