import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";

import NumberFormat from "react-number-format";


import {
    UseStatisticTypeName
} from "@Services/Consts";


import dateFormat from "dateformat";

import {
    formatMinutesFromSeconds
} from "@Services/Utilities/Tools";


import {
    PlayerBestScoreInterface,
    PlayerGameStatisticsTypes
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerBestScoresScoresListScoreComponentProps {
    statisticType: PlayerGameStatisticsTypes;
    PlayerScore: PlayerBestScoreInterface;
}

export const PlayerBestScoresScoresListScoreComponent: React.FC<PlayerBestScoresScoresListScoreComponentProps> = ({
    statisticType, PlayerScore
}) : JSX.Element | null => {


    const GamesRoute = UseRoute("GAMES");
    const TeamsRoute = UseRoute("TEAMS");


    const StatisticTypeName = UseStatisticTypeName(statisticType);


    if (!PlayerScore || !StatisticTypeName) {
        return null;
    }


    const {
        score_value: playerScoreValue, game_information: GameInformation,
    } = PlayerScore;

    if (!playerScoreValue || !GameInformation) {
        return null;
    }

    const {
        id: leagueGameID, league_game_date: leagueGameDate, league_game_result: LeagueGameResult
    } = GameInformation;

    if (!LeagueGameResult) {
        return null;
    }

    const {
        league_game_player_player_team_points: playerTeamGamePoints,
        league_game_player_opponent_team_points: opponentTeamGamePoints,
        league_game_player_opponent_team: OpponentTeam
    } = LeagueGameResult;

    if (!OpponentTeam) {
        return null;
    }

    const {
        team_league_team_name: opponentTeamLeagueName, team_league_team: OpponentTeamTeam
    } = OpponentTeam;

    if (!OpponentTeamTeam) {
        return null;
    }

    const {
        team_slug: opponentTeamSlug, team_name: opponentTeamMainName
    } = OpponentTeamTeam;


    const leagueGameURL = "/" + GamesRoute.routeURL + "/" + leagueGameID;
    const opponentTeamURL = "/" + TeamsRoute.routeURL + "/" + opponentTeamSlug;

    const opponentTeamName = opponentTeamLeagueName || opponentTeamMainName;


    const percentageValueType = [
        "league_game_team_player_2pt_per",
        "league_game_team_player_3pt_per",
        "league_game_team_player_ft_per",
        "league_game_team_player_throws_per",
    ].includes(statisticType);


    let scoreValue: number | string = playerScoreValue;

    if (statisticType === "league_game_team_player_seconds") {
        scoreValue = formatMinutesFromSeconds(scoreValue);
    }


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__best-scores__list__item player-details__details-data__statistics-data__content__best-scores__list__score"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__list__item__content player-details__details-data__statistics-data__content__best-scores__content__list__score__content"}>

                <Col xs={3} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__score__value player-details__details-data__statistics-data__content__best-scores__list__item__value--value-label player-details__details-data__statistics-data__content__best-scores__list__score__value--value-label"}>
                    <span>{StatisticTypeName.mainPhraseSingular}</span>
                </Col>

                <Col xs={2}
                     className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__score__value player-details__details-data__statistics-data__content__best-scores__list__item__value--score-value player-details__details-data__statistics-data__content__best-scores__list__score__value--score-value"}>
                    {percentageValueType ?
                    <>
                        <NumberFormat value={scoreValue} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."}/>
                        %
                    </>
                    :
                    <span>{scoreValue}</span>
                    }

                </Col>

                <Col xs={2} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__score__value player-details__details-data__statistics-data__content__best-scores__list__item__value--game-date player-details__details-data__statistics-data__content__best-scores__list__score__value--game-date"}>
                    <NavLink to={leagueGameURL} title={GamesRoute.routeTitle}>
                        <span>{dateFormat(leagueGameDate, "yyyy-mm-dd")}</span>
                    </NavLink>
                </Col>

                <Col xs={3} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__score__value player-details__details-data__statistics-data__content__best-scores__list__item__value--game-opponent player-details__details-data__statistics-data__content__best-scores__list__score__value--game-opponent"}>
                    <NavLink to={opponentTeamURL} title={TeamsRoute.routeTitle}>
                        <span>{opponentTeamName}</span>
                    </NavLink>
                </Col>

                <Col xs={2} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__score__value player-details__details-data__statistics-data__content__best-scores__list__item__value--game-result player-details__details-data__statistics-data__content__best-scores__list__score__value--game-result"}>
                    <NavLink to={leagueGameURL} title={GamesRoute.routeTitle}>
                        <span>{playerTeamGamePoints} : {opponentTeamGamePoints}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerBestScoresScoresListScoreComponent;
