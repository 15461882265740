import React, { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_ADD_PLAYER_TO_TEAM_LEAGUE_SQUAD_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";
import ErrorMessageComponent from "@MessagesElements/Message/Error";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import {
    FormSelectFieldComponent, FormSubmitFieldComponent, FormTextFieldComponent
} from "@FormsElements";


import AddTeamSquadPlayerFormLegendComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayerAdd/Form/Legend";


import UseTeamPlayers from "@Hooks/Api/Requests/Games/Teams/Players";

import {
    generatePlayerName
} from "@Services/Utilities/Games";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface AddTeamSquadPlayerFormComponentProps {
    teamID: number;
    teamLeagueID: number;
    TeamLeagueSquad: TeamLeaguePlayerInterface[] | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const AddTeamSquadPlayerFormComponent: React.FC<AddTeamSquadPlayerFormComponentProps> = ({
    teamID, teamLeagueID, TeamLeagueSquad = [], updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    const UsePopupMessage = UseSetPopupMessageAction();

    const DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE = UseCommonDictionaryPhrasesSet("DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE");
    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const NO_PLAYERS_AVAILABLE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_PLAYERS_AVAILABLE_PHRASE_TEXT");

    const PLAYER_WORD_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_WORD_TEXT");
    const CHOOSE_PLAYER_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CHOOSE_PLAYER_PHRASE_TEXT");
    const NUMBER_WORD_TEXT = UseCommonDictionaryPhrasesSet("NUMBER_WORD_TEXT");
    const ADD_WORD_TEXT = UseCommonDictionaryPhrasesSet("ADD_WORD");


    const [TeamPlayers, TeamPlayersError] = UseTeamPlayers(teamID);

    const [teamSquadTeamPlayerID, setTeamSquadTeamPlayerID] = useState("0");
    const [teamSquadTeamPlayerNumber, setTeamSquadTeamPlayerNumber] = useState("");

    useEffect(() => {
        return () => {
            setTeamSquadTeamPlayerID("0");
            setTeamSquadTeamPlayerNumber("");
        }
    }, []);


    if (TeamPlayersError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />
    }


    if (!TeamPlayers) {
        return <SingleContentLoaderComponent />;
    }


    const ActiveTeamPlayers = TeamPlayers.filter((TeamPlayer) => {

        const {
            team_player_status: teamPlayerStatus, team_player_data: TeamPlayerData
        } = TeamPlayer;

        if (!TeamPlayerData) {
            return false;
        }

        const {
            player_activated_status: activatedStatus,
            player_regulations_acceptation_date: regulationsAccepted, player_privacy_acceptation_date: privacyAccepted
        } = TeamPlayerData;

        return teamPlayerStatus === 1 && activatedStatus && regulationsAccepted && privacyAccepted;

    });

    const TeamLeagueSquadIDs = TeamLeagueSquad?.map(
        (TeamSquadPlayer) => {
            const { league_team_player_player_id: teamSquadPlayerTeamPlayerID } = TeamSquadPlayer;
            return teamSquadPlayerTeamPlayerID;
        }) ?? []
    ;

    const FilteredTeamPlayers = ActiveTeamPlayers.filter(
        (TeamPlayer) => !TeamLeagueSquadIDs.includes(TeamPlayer.id)
    );


    if (!FilteredTeamPlayers.length) {
        return <MessageComponent messageText={NO_PLAYERS_AVAILABLE_PHRASE_TEXT} cssClasses={"bordered padding-10"} />;
    }


    const TeamPlayersList = FilteredTeamPlayers.map((TeamPlayer) => {

        const { id, team_player_data: TeamPlayerData } = TeamPlayer;

        const {
            // @ts-ignore
            player_name: playerName
        } = TeamPlayerData;

        return { value: id, label: playerName }

    });


    const submitButtonEnabledStatus = teamSquadTeamPlayerID !== "0";


    const addPlayerSubmitHandler = async () => {

        if (!teamSquadTeamPlayerID) {
            return;
        }

        await addPlayerRequestHandler();

    };

    const addPlayerRequestHandler = async () => {

        const API_EP_URL = API_ADD_PLAYER_TO_TEAM_LEAGUE_SQUAD_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString());

        const formData = new FormData();
        formData.append("team_player_id", teamSquadTeamPlayerID.toString());
        formData.append("team_player_number", teamSquadTeamPlayerNumber);
        formData.append("team_league_id", teamLeagueID.toString());

        try {

            const response = await _restApiRequest(
                API_EP_URL, formData, "POST"
            );

            if (response.ok) {
                UsePopupMessage({ type: "SUCCESS", text: DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE });
                updateTeamLeaguesTriggerHandler(true);
            } else {
                UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
            }

        } catch (e) {
            UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
        }

    };


    const TeamPlayersListOptions = [ { value: 0, label: `- ${CHOOSE_PLAYER_PHRASE_TEXT} -` },  ...TeamPlayersList ];


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__content"}>

                <AddTeamSquadPlayerFormLegendComponent />

                <Col xs={8} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__field"}>
                    <FormSelectFieldComponent
                        fieldLabel={PLAYER_WORD_TEXT}
                        fieldOptions={TeamPlayersListOptions}
                        fieldValue={teamSquadTeamPlayerID}
                        fieldValueHandler={(e) => setTeamSquadTeamPlayerID(e.target.value)}
                    />
                </Col>

                <Col xs={2} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__field"}>
                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldLabel={NUMBER_WORD_TEXT}
                        fieldValue={teamSquadTeamPlayerNumber}
                        fieldValueHandler={(e) => setTeamSquadTeamPlayerNumber(e.target.value)}
                    />
                </Col>

                <Col xs={2} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__form__submit"}>
                    <FormSubmitFieldComponent
                        buttonClickHandler={addPlayerSubmitHandler}
                        buttonText={ADD_WORD_TEXT}
                        buttonDisabledStatus={!submitButtonEnabledStatus}
                    />
                </Col>

            </Row>
        </Col>
    );

};


export default AddTeamSquadPlayerFormComponent;
