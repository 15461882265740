import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayersListLetterPlayersComponent from "@TeamsPages/List/Content/Data/TeamsAndPlayers/Players/List/Letter/Players";


import {
    PlayerPublicBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersPlayersListLetterComponentProps {
    letter: string;
    Players: PlayerPublicBasicInformationType[];
}

export const TeamsAndPlayersPlayersListLetterComponent: React.FC<TeamsAndPlayersPlayersListLetterComponentProps> = ({
    letter, Players
}) : JSX.Element | null => {


    if (!letter || !Players.length) {
        return null;
    }

    return (
        <Col xs={6} lg={3} className={"teams-and-players__content__teams-and-players__players__list__letter"}>
            <Row className={"teams-and-players__content__teams-and-players__players__list__letter__content"}>

                <Col xs={12} className={"teams-and-players__content__teams-and-players__players__list__letter__header"}>
                    <span>{letter}</span>
                </Col>

                <PlayersListLetterPlayersComponent
                    Players={Players}
                />

            </Row>
        </Col>
    )

};


export default TeamsAndPlayersPlayersListLetterComponent;
