import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";

import NumberFormat from "react-number-format";


import {
    PlayerCareerStatisticsLeagueInterface,
    TeamLeaguePlayerGameStatisticsInterface
} from "@Interfaces/2.0";


export interface PlayerStatisticsTableLeaguesListLeagueComponentProps {
    League: PlayerCareerStatisticsLeagueInterface;
    seasonName: string;
    statisticsType: "M" | "S";
}


export const PlayerStatisticsTableLeaguesListLeagueComponent: React.FC<PlayerStatisticsTableLeaguesListLeagueComponentProps> = ({
    League,
    seasonName, statisticsType
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!League) {
        return null;
    }


    const {
        league_statistics: LeagueStatistics, league_team: LeagueTeam
    } = League;

    if (!LeagueStatistics) {
        return null;
    }

    const {
        league_summary_statistics: LeagueSummaryStatistics,
        league_medium_statistics: LeagueMediumStatistics,
        league_games_number: playerGamesNumber
    } = LeagueStatistics;


    let LeagueStatisticsValues: TeamLeaguePlayerGameStatisticsInterface | null = null;

    if (statisticsType === "S") {
        LeagueStatisticsValues = LeagueSummaryStatistics;
    } else if (statisticsType === "M") {
        LeagueStatisticsValues = LeagueMediumStatistics
    }

    if (!LeagueStatisticsValues) {
        return null;
    }


    const {
        league_game_team_player_first_squad: firstSquadGames,
        league_game_team_player_seconds: playedSeconds,
        league_game_team_player_pts: playerPoints,
        league_game_team_player_2pt: player2ptAccurateThrows, league_game_team_player_2pta: player2ptMadeThrows, league_game_team_player_2pt_per: player2ptThrowsEffectivity,
        league_game_team_player_3pt: player3ptAccurateThrows, league_game_team_player_3pta: player3ptMadeThrows, league_game_team_player_3pt_per: player3ptThrowsEffectivity,
        league_game_team_player_throws_acc: playerGameAccurateThrows, league_game_team_player_throws_tot: playerGameMadeThrows, league_game_team_player_throws_per: playerGameThrowsEffectivity,
        league_game_team_player_ft: player1ptAccurateThrows, league_game_team_player_fta: player1ptMadeThrows, league_game_team_player_ft_per: player1ptThrowsEffectivity,
        league_game_team_player_rebound_off: playerOffensiveRebounds, league_game_team_player_rebound_def: playerDefensiveRebounds, league_game_team_player_rebound_tot: playerTotalRebounds,
        league_game_team_player_ast: playerAssists, league_game_team_player_blk: playerBlocks, league_game_team_player_stl: playerSteals, league_game_team_player_to: playerTurnovers, league_game_team_player_foul: playerFouls, league_game_team_player_eff: playerEffectivity, league_game_team_player_eval: playerEval,
    } = LeagueStatisticsValues;


    let playerMinutes = Math.floor(playedSeconds / 60);
    let playerSeconds = Math.floor(playedSeconds % 60);

    let playerPlayedMinutes = (playerMinutes < 10 ? "0" : "") + playerMinutes.toString();
    let playerPlayedSeconds = (playerSeconds < 10 ? "0" : "") + playerSeconds.toString();


    const {
        team_name: teamName, team_slug: teamSlug
    } = LeagueTeam;

    const teamURL = "/" + TeamsRoute.routeURL + "/" + teamSlug;


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league"}>
            <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__content player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__content"}>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--season-name player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value--season-name"}>
                    <span>{seasonName}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--team-name player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value--team-name"}>
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--games-number"}>
                    <span>{playerGamesNumber}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--start-5"}>
                    <NumberFormat value={firstSquadGames} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--minutes-number"}>
                    <span>{playerPlayedMinutes}:{playerPlayedSeconds}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--points-number"}>
                    <NumberFormat value={playerPoints} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt2-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <NumberFormat value={player2ptAccurateThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player2ptMadeThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player2ptThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt3-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <NumberFormat value={player3ptAccurateThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player3ptMadeThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player3ptThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--pt-game-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <NumberFormat value={playerGameAccurateThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={playerGameMadeThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={playerGameThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--ft-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <NumberFormat value={player1ptAccurateThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player1ptMadeThrows} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={player1ptThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                        </Col>
                    </Row>
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--rebounds-number"}>
                    <Row className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content"}>
                        <Col xs={12} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value__content__sub-value"}>
                            <NumberFormat value={playerDefensiveRebounds} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={playerOffensiveRebounds} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                            <span className={"separator"}></span>
                            <NumberFormat value={playerTotalRebounds} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                        </Col>
                    </Row>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--assists-number"}>
                    <NumberFormat value={playerAssists} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--blocks-number"}>
                    <NumberFormat value={playerBlocks} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--steals-number"}>
                    <NumberFormat value={playerSteals} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--turnovers-number"}>
                    <NumberFormat value={playerTurnovers} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--fouls-number"}>
                    <NumberFormat value={playerFouls} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--effectivity-value"}>
                    <NumberFormat value={playerEffectivity} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

                <Col xs={1} className={"player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__league__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value player-details__details-data__statistics-data__content__leagues-statistics__statistics-table__leagues-list__row__value--eval-value"}>
                    <NumberFormat value={playerEval} decimalScale={statisticsType === "M" ? 1 : 0} fixedDecimalScale={statisticsType === "M"} displayType={"text"} decimalSeparator={"."} />
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerStatisticsTableLeaguesListLeagueComponent;
