import React from "react";


import {
    Col
} from "react-bootstrap";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerBestScoresLeaguesListLeagueComponentProps {
    League: PlayerCareerStatisticsLeagueInterface;
    selectedLeagueIDHandler: (leagueID: number) => void;
}

export const PlayerBestScoresLeaguesListLeagueComponent: React.FC<PlayerBestScoresLeaguesListLeagueComponentProps> = ({
    League, selectedLeagueIDHandler
}) : JSX.Element | null => {


    if (!League) {
        return null;
    }


    const {
        league_name: leagueName, league_season_name: leagueSeasonName
    } = League;


    return (
        <Col xs={12} onClick={() => selectedLeagueIDHandler(League.id)} className={"player-details__details-data__statistics-data__content__best-scores__header__leagues__list__league"}>
            <span>{leagueName} ({leagueSeasonName})</span>
        </Col>
    );

};


export default PlayerBestScoresLeaguesListLeagueComponent;
