import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import PlayerFieldTeamInformationComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/MainData/Player/Information/Field/Team";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerFieldInformationComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerFieldInformationComponent: React.FC<PlayerFieldInformationComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    const NUMBER_WORD_TEXT = UseCommonDictionaryPhrasesSet("NUMBER_WORD_TEXT");


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_position: playerFieldPosition, player_number: playerFieldNumber,
        player_active_season_team_data: PlayerTeam
    } = PlayerDescriptionData;


    return (
        <Row className={"player-field-information"}>
            <Col xs={12} className={"player-information"}>

                {PlayerTeam &&
                <PlayerFieldTeamInformationComponent
                    PlayerTeam={PlayerTeam}
                />
                }

                {(!!playerFieldPosition || playerFieldPosition === 0) &&
			    <span className={"player-field-position"}>{playerFieldPosition}</span>
                }

                {(!!playerFieldNumber || playerFieldNumber === 0) &&
			    <span className={"player-field-number"}>
                    <span>{NUMBER_WORD_TEXT}</span>
                    <span className={"field-number"}>{playerFieldNumber}</span>
                </span>
                }

            </Col>
        </Row>
    );

};


export default PlayerFieldInformationComponent;
