import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterSubstitutionEventDataComponentProps {
    quarterNumber: number;
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterSubstitutionEventDataComponent: React.FC<QuarterSubstitutionEventDataComponentProps> = ({
    eventOrderNr, quarterNumber,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.SUBSTITUTION) {
        return null;
    }

    if (!Event.eventPlayerInID || !Event.eventPlayerOutID) {
        return null;
    }

    const EventPlayerIn = GamePlayers[Event.eventPlayerInID];
    const EventPlayerOut = GamePlayers[Event.eventPlayerOutID];

    if (!EventPlayerIn || !EventPlayerOut) {
        return null;
    }

    let eventPlayerInName = "";
    let eventPlayerOutName = "";

    if (Event.eventPlayerInNumber) {
        eventPlayerInName += Event.eventPlayerInNumber + " ";
    }

    if (Event.eventPlayerOutNumber) {
        eventPlayerOutName += Event.eventPlayerOutNumber + " ";
    }

    const {
        player_first_name: eventPlayerInFirstName,
        player_last_name: eventPlayerInLastName,
        player_slug: eventPlayerInSlug,
        player_pseudonymization_status: eventPlayerInPseudonymizationStatus,
        player_nick_name: eventPlayerInNIckName
    } = EventPlayerIn;

    if (eventPlayerInPseudonymizationStatus) {
        if (eventPlayerInNIckName) {
            eventPlayerInName += eventPlayerInNIckName;
        } else {
            eventPlayerInName += "**********"
        }
    } else {
        eventPlayerInName += `${eventPlayerInFirstName[0]}. ${eventPlayerInLastName}`;
    }

    const {
        player_first_name: eventPlayerOutFirstName,
        player_last_name: eventPlayerOutLastName,
        player_slug: eventPlayerOutSlug,
        player_pseudonymization_status: eventPlayerOutPseudonymizationStatus,
        player_nick_name: eventPlayerOutNIckName
    } = EventPlayerOut;

    if (eventPlayerOutPseudonymizationStatus) {
        if (eventPlayerOutNIckName) {
            eventPlayerOutName += eventPlayerOutNIckName;
        } else {
            eventPlayerOutName += "**********"
        }
    } else {
        eventPlayerOutName += `${eventPlayerOutFirstName[0]}. ${eventPlayerOutLastName}`;
    }


    const SubstitutionEvent = UseProgressEvent("SUBSTITUTION");


    if (!SubstitutionEvent) {
        return null;
    }


    const {
        eventIcon: substitutionEventIcon, eventName: { label: substitutionEventLabel }
    } = SubstitutionEvent;


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--substitution-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} lg={4} style={{ order: homeTeamID === Event.eventTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {substitutionEventIcon && <img src={substitutionEventIcon} alt={""} className={"event-icon"} />}
                        <NavLink to={`/${PlayersRoute.routeURL}/${eventPlayerOutSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name substitution-player-out"}>
                            <span>{eventPlayerOutName}</span>
                        </NavLink>
                        <span className={"event-label"}>{substitutionEventLabel}</span>
                        <NavLink to={`/${PlayersRoute.routeURL}/${eventPlayerInSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name substitution-player-in"}>
                            <span>{eventPlayerInName}</span>
                        </NavLink>
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    quarterNumber={quarterNumber}
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={[homeTeamID === Event.eventTeamID ? "" : "offset-4", "order-2"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterSubstitutionEventDataComponent;
