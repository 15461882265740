import { useState, useEffect } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_SPONSORS_LIST_PUBLIC_ENDPOINT_URL,
    API_PROMOTED_SPONSORS_LIST_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    SponsorInterface
} from "@Interfaces/2.0";


export const UseSponsors = (
    promotedSponsors = false
) : [SponsorInterface[] | null, ErrorMessageInterface | null] => {


    const [Sponsors, setSponsors] = useState<SponsorInterface[] | null>(null);
    const [SponsorsError, setSponsorsError] = useState<ErrorMessageInterface | null>(null);


    const API_PUBLIC_ENDPOINT_URL = promotedSponsors ? API_PROMOTED_SPONSORS_LIST_PUBLIC_ENDPOINT_URL : API_SPONSORS_LIST_PUBLIC_ENDPOINT_URL;


    useEffect(() => {

        const getSponsors = async () => {

            try {

                const response = await _restApiRequest(API_PUBLIC_ENDPOINT_URL);

                if (response.ok) {
                    const responseContent = await response.json();
                    setSponsors(responseContent);
                } else {
                    setSponsorsError({ errorCode: 500, errorMessage: "" });
                }

            } catch (e) {
                setSponsorsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getSponsors();

    }, []);

    useEffect(() => {
        return () => {
            setSponsors(null);
            setSponsorsError(null);
        };
    }, []);


    return [Sponsors, SponsorsError];

};


export default UseSponsors;
