import React, { useEffect, useState } from "react";


import { NavLink } from "react-router-dom";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import GameSummaryMainInformationComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameMainInformation";
import GameArenaInformationComponent from "@GameResultPageSummary/GameArenaInformation";
import GameInformationCastComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameCastInformation";
import GameInformationSwitcherComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDataSectionSwitcher";
import GameInformationComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation";


import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";


import InformationSectionTypes from "@Components/Views/Pages/Games/Games/Game/_Types/Sections";

import prepareTeamsSummaryStatistics from "@Pages/Games/Games/Game/_Utitlities/Summary/_Main/Teams/PrepareTeamsSummaryStatistics";


import "./index.scss";


import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";

import TeamsSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams";
import {UseLoggedUser} from "@States";
import {Col} from "react-bootstrap";
import {UseRoute} from "@Hooks/Routes";


type GameSummaryProps = {
    GameData: LeagueGameInterface;
    GameSummaryStatistics: ApiGameSummaryInterface | null;
};


export const GameSummaryComponent: React.FC<GameSummaryProps> = ({
    GameData, GameSummaryStatistics
}) : JSX.Element | null => {


    const User = UseLoggedUser();

    const GamesRoute = UseRoute("GAMES");


    const [SelectedInformationSection, setSelectedInformationSection] = useState<InformationSectionTypes>(InformationSectionTypes.TABLES);


    const [SelectedQuarters, setSelectedQuarters] = useState<ApiGameSummaryQuarterType[]>([]);

    const changeSelectedQuarters = (selectedQuarter: ApiGameSummaryQuarterType) => {

        const Quarters: ApiGameSummaryQuarterType[] =
            SelectedQuarters.includes(selectedQuarter) ?
                SelectedQuarters.filter((quarter) => quarter !== selectedQuarter)
                :
                [...SelectedQuarters, selectedQuarter]
        ;

        setSelectedQuarters(Quarters);

    };


    useEffect(() => {
        return () => {
            setSelectedInformationSection(InformationSectionTypes.TABLES);
            setSelectedQuarters([]);
        };
    }, []);


    useEffect(() => {

        const {
            league_game_quarters: GameQuarters,
            league_game_status: gameStatus
        } = GameData;

        if (!GameQuarters || gameStatus === "FT_WAL") return;

        const Quarters: ApiGameSummaryQuarterType[] = [1, 2, 3, 4];

        if (GameQuarters[5]) Quarters.push(5);

        setSelectedQuarters(Quarters);

    }, [GameData]);


    const [TeamsSummaryStatistics, setTeamsSummaryStatistics] = useState<TeamsSummaryInterface | null>(null);

    useEffect(() => {

        if (!GameSummaryStatistics || !SelectedQuarters) return;

        setTeamsSummaryStatistics(prepareTeamsSummaryStatistics(GameSummaryStatistics, SelectedQuarters))

    }, [GameSummaryStatistics, SelectedQuarters]);


    if (!GameData) return null;


    const { league_game_id: gameID } = GameData;


    return (
        <ContainerSectionComponent id={`game-result-page`} classes={`game-result-page game-result-page-component`}>

            {/*{User && User.userAdminStatus === true &&*/}
            {/*<Col xs={12} className={"game-result-page__admin"}>*/}
            {/*    <NavLink to={`/${GamesRoute.routeURL}/${gameID}/game-admin`}>*/}
            {/*        <span>Zarządzanie meczem</span>*/}
            {/*    </NavLink>*/}
            {/*</Col>*/}
            {/*}*/}

            <GameSummaryMainInformationComponent
                GameData={GameData}
                TeamsSummaryData={TeamsSummaryStatistics}
                SelectedQuarters={SelectedQuarters}
                selectedQuartersHandler={changeSelectedQuarters}
            />

            <GameArenaInformationComponent
                GameData={GameData}
            />

            <GameInformationCastComponent
                GameData={GameData}
            />

            <GameInformationSwitcherComponent
                SelectedSection={SelectedInformationSection}
                SelectedSectionHandler={setSelectedInformationSection}
            />

            <GameInformationComponent
                GameData={GameData}
                SelectedSection={SelectedInformationSection}
                SelectedQuarters={SelectedQuarters}
                GameSummaryStatistics={GameSummaryStatistics}
                TeamsSummaryData={TeamsSummaryStatistics}
            />

        </ContainerSectionComponent>
    );

};


export default GameSummaryComponent;
