import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";



export interface QuarterTimeOutEventDataComponent {
    quarterNumber: number;
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterTimeOutEventDataComponent: React.FC<QuarterTimeOutEventDataComponent> = ({
    eventOrderNr, quarterNumber,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    if (!Event || Event.eventType !== GameProgressEventTypes.TIME_OUT) {
        return null;
    }

    if (!Event.eventTeamID) {
        return null;
    }


    const TimeOutEvent = UseProgressEvent(Event.eventType);

    if (!TimeOutEvent) {
        return null;
    }

    const {
        eventName: {
            singular: eventName,
        },
        eventIcon
    } = TimeOutEvent;


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--default-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {eventIcon && <img src={eventIcon} className={"event-icon"} alt={""} />}
                        <span className={"event-label"}>{eventName}</span>
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    quarterNumber={quarterNumber}
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={homeTeamID === Event.eventTeamID ? ["order-2"] : ["offset-4"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterTimeOutEventDataComponent;
