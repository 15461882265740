import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import {
    UseSponsors
} from "@Hooks/Api";

import SponsorsSponsorComponent from "@Components/Views/Parts/Sponsors/Sponsor";


import "./index.scss";


export interface ApplicationSponsorsComponentProps {}


export const ApplicationSponsorsComponent: React.FC<ApplicationSponsorsComponentProps> = () : JSX.Element | null => {


    const [Sponsors, SponsorsError] = UseSponsors();


    if (SponsorsError) {
        return null;
    }

    if (!Sponsors) {
        return <SingleContentLoaderComponent cssClasses={"margin-top-25 margin-bottom-25"} />;
    }

    if (!Sponsors?.length) {
        return null;
    }

    const CAROUSEL_SETTINGS = {
        dots: false, arrows: false, infinite: true, speed: 500,
        slidesToShow: 6, slidesToScroll: 1, initialSlide: 0,
        autoplay: true, autoplaySpeed: 4000, pauseOnHover: true,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 6 } },
            { breakpoint: 600, settings: { slidesToShow: 2} },
            { breakpoint: 480, settings: { slidesToShow: 1 } }
        ]
    };


    const SponsorsListComponent = Sponsors.map((Sponsor, i) =>
        <SponsorsSponsorComponent
            key={i} Sponsor={Sponsor}
        />
    );


    return (
        <ContainerSectionComponent classes={"service-sponsors"}>

            <Slider {...CAROUSEL_SETTINGS} className={"service-sponsors__carousel"}>
                {SponsorsListComponent}
            </Slider>

        </ContainerSectionComponent>
    );

};


export default ApplicationSponsorsComponent;
