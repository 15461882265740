import React, { useEffect, useState } from "react";


import {
    UseTeamsDictionaryPhrasesSet
} from "@Hooks/Texts/Pages";


import {
    Col, Row
} from "react-bootstrap";


import TeamCaptainTeamDataComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/TeamData";
import TeamCaptainTeamFilesComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files";
import TeamCaptainTeamPlayersComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players";
import TeamCaptainTeamLeaguesComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues";


import {
    LeagueTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamCaptainTeamComponentProps {
    playerID: number;
    Team: LeagueTeamInterface;
    updatePlayerTrigger: (update: boolean) => void;
}


export const TeamCaptainTeamComponent: React.FC<TeamCaptainTeamComponentProps> = ({
    playerID, Team, updatePlayerTrigger
}) : JSX.Element | null => {


    if (!Team) {
        return null;
    }


    const {
        team_id: teamID,
        url: teamApiURL
    } = Team;


    return (
        <Col xs={12} className={"user-profile-captain-team player-profile__captain-zone__team"}>
            <Row className={"user-profile-captain-team-content player-profile__captain-zone__team__content"}>

                <TeamCaptainTeamDataComponent
                    team={Team}
                    updatePlayerTrigger={updatePlayerTrigger}
                />

                <TeamCaptainTeamFilesComponent
                    teamID={teamID}
                    teamApiURL={teamApiURL}
                />

                <TeamCaptainTeamPlayersComponent
                    teamID={teamID}
                />

                <TeamCaptainTeamLeaguesComponent
                    teamID={teamID}
                />

            </Row>
        </Col>
    );

};


export default TeamCaptainTeamComponent;
