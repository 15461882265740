import { useState, useEffect } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_LOGGED_PLAYER_PROFILE_DATA_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    UseErrorMessage
} from "@Hooks/Texts";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    LoggedPlayerProfileDataInterface
} from "@Interfaces/2.0";


export const UseLoggedPlayerProfileData = (
    playerToken: string, updateTrigger: boolean = false
) : [LoggedPlayerProfileDataInterface | null, ErrorMessageInterface | null] => {


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");


    const [LoggedPlayerProfileData, setLoggedPlayerProfileData] = useState<LoggedPlayerProfileDataInterface | null>(null);
    const [LoggedPlayerProfileDatError, setLoggedPlayerProfileDataError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!playerToken) {
            return;
        }

        if (LoggedPlayerProfileData && !updateTrigger) {
            return;
        }

        const getPlayerProfileData = async () => {

            try {

                const response = await _restApiRequest(
                    API_LOGGED_PLAYER_PROFILE_DATA_ENDPOINT_URL,
                    null,
                    "GET",
                    playerToken
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setLoggedPlayerProfileData(responseContent);
                } else {
                    setLoggedPlayerProfileDataError({ errorCode: response.status, errorMessage: DEFAULT_DATA_REQUEST_ERROR_MESSAGE });
                }

            } catch (e) {
                setLoggedPlayerProfileDataError({ errorCode: 512, errorMessage: DEFAULT_DATA_REQUEST_ERROR_MESSAGE });
            }

        };

        getPlayerProfileData();

    }, [updateTrigger]);


    return [LoggedPlayerProfileData, LoggedPlayerProfileDatError];

};


export default UseLoggedPlayerProfileData;
