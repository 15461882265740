import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    TeamBasicInformationType
} from "@Interfaces/2.0";


export interface PlayerFieldTeamInformationComponentProps {
    PlayerTeam: TeamBasicInformationType;
}


export const PlayerFieldTeamInformationComponent: React.FC<PlayerFieldTeamInformationComponentProps> = ({
    PlayerTeam
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!PlayerTeam) {
        return null;
    }


    const {
        team_name: playerTeamName, team_slug: playerTeamSlug
    } = PlayerTeam;


    const playerTeamURL = "/" + TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle} className={"player-team-name"}>
            <span>{playerTeamName}</span>
        </NavLink>
    );

};


export default PlayerFieldTeamInformationComponent;
