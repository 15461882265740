import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface PlayerBestScoresScoresListHeaderComponentProps {}


export const PlayerBestScoresScoresListHeaderComponent: React.FC<PlayerBestScoresScoresListHeaderComponentProps> = ({}) : JSX.Element | null => {


    const BEST_SCORE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("BEST_SCORE_PHRASE_TEXT");
    const DATE_WORD_TEXT = UseCommonDictionaryPhrasesSet("DATE_WORD_TEXT");
    const AGAINST_WORD_TEXT = UseCommonDictionaryPhrasesSet("AGAINST_WORD_TEXT");
    const RESULT_WORD_TEXT = UseCommonDictionaryPhrasesSet("RESULT_WORD_TEXT");


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__best-scores__list__item player-details__details-data__statistics-data__content__best-scores__list__header"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__list__item__content player-details__details-data__statistics-data__content__best-scores__content__list__header__content"}>

                <Col xs={3} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__header__value player-details__details-data__statistics-data__content__best-scores__list__item__value-- player-details__details-data__statistics-data__content__best-scores__list__header__value--"}>
                    <span></span>
                </Col>

                <Col xs={2} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__header__value player-details__details-data__statistics-data__content__best-scores__list__item__value-- player-details__details-data__statistics-data__content__best-scores__list__header__value--"}>
                    <span>{BEST_SCORE_PHRASE_TEXT}</span>
                </Col>

                <Col xs={2} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__header__value player-details__details-data__statistics-data__content__best-scores__list__item__value-- player-details__details-data__statistics-data__content__best-scores__list__header__value--"}>
                    <span>{DATE_WORD_TEXT}</span>
                </Col>

                <Col xs={3} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__header__value player-details__details-data__statistics-data__content__best-scores__list__item__value-- player-details__details-data__statistics-data__content__best-scores__list__header__value--"}>
                    <span>{AGAINST_WORD_TEXT}</span>
                </Col>

                <Col xs={2} className={"player-details__details-data__statistics-data__content__best-scores__list__item__value player-details__details-data__statistics-data__content__best-scores__list__header__value player-details__details-data__statistics-data__content__best-scores__list__item__value-- player-details__details-data__statistics-data__content__best-scores__list__header__value--"}>
                    <span>{RESULT_WORD_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerBestScoresScoresListHeaderComponent;
