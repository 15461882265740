import { useState, useEffect } from "react";


import {
    API_LEAGUES_TEAMS_AND_PLAYERS_PUBLIC_ENDPOINT_URL
} from "@Services/API/Endpoints";

import _restApiRequest from "@Services/HTTP/Requests/Request";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    TeamLeagueBasicInformationType,
    TeamLeaguePlayerBasicInformationType
} from "@Interfaces/2.0";


export interface LeagueTeamsAndPlayersResponseType {
    "league_teams": TeamLeagueBasicInformationType[];
    "league_players": TeamLeaguePlayerBasicInformationType[];
}


export const UseLeagueTeamsAndPlayers = (
    leagueID: number
) : [LeagueTeamsAndPlayersResponseType | null, ErrorMessageInterface | null] => {


    const [LeagueTeamsAndPlayers, setLeagueTeamsAndPlayers] = useState<LeagueTeamsAndPlayersResponseType | null>(null);
    const [LeagueTeamsAndPlayersError, setLeagueTeamsAndPlayersError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!leagueID) {
            return setLeagueTeamsAndPlayersError({ errorCode: 400, errorMessage: "" });
        }

        API_LEAGUES_TEAMS_AND_PLAYERS_PUBLIC_ENDPOINT_URL

        const getLeagueTeamsAndPlayers = async () => {

            try {

                const response = await _restApiRequest(
                    API_LEAGUES_TEAMS_AND_PLAYERS_PUBLIC_ENDPOINT_URL.replace(
                        "{LEAGUE_ID}", leagueID.toString()
                    )
                );

                if (response.ok) {
                    const responseContent = await response.json();
                    setLeagueTeamsAndPlayers(responseContent);
                } else {
                    setLeagueTeamsAndPlayersError({ errorCode: 500, errorMessage: "" });
                }

            } catch (e) {
                setLeagueTeamsAndPlayersError({ errorCode: 500, errorMessage: "" });
            }

        };

        getLeagueTeamsAndPlayers();

    }, [leagueID]);


    useEffect(() => {
        setLeagueTeamsAndPlayers(null);
        setLeagueTeamsAndPlayersError(null)
    }, []);


    return [LeagueTeamsAndPlayers, LeagueTeamsAndPlayersError];

};


export default UseLeagueTeamsAndPlayers;
