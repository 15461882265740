import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import {
    AiFillCaretDown
} from "react-icons/all";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface TeamsAndPlayersSeasonsSelectedSeasonComponentProps {
    Season: SeasonWithLeaguesShortInformationType | null;
    seasonsListSwitcherActiveStatusHandler: () => void;
    seasonsNumber: number;
}

export const TeamsAndPlayersSeasonsSelectedSeasonComponent: React.FC<TeamsAndPlayersSeasonsSelectedSeasonComponentProps> = ({
    Season, seasonsListSwitcherActiveStatusHandler, seasonsNumber
}) : JSX.Element | null => {


    const CHOOSE_WORD_TEXT = UseCommonDictionaryPhrasesSet("CHOOSE_WORD_TEXT");
    const SEASON_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEASON_WORD_TEXT");


    let seasonLabel = `- ${CHOOSE_WORD_TEXT} ${SEASON_WORD_TEXT} -`;

    if (Season) {

        const {
            league_season_name: leagueSeasonName
        } = Season;

        seasonLabel = leagueSeasonName;

    }


    return (
        <Col xs={12} onClick={seasonsListSwitcherActiveStatusHandler} className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__selected-season"}>
            <span className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__selected-season__season-label"}>
                {seasonLabel}
            </span>
            {seasonsNumber > 1 && <AiFillCaretDown />}
        </Col>
    );

};


export default TeamsAndPlayersSeasonsSelectedSeasonComponent
