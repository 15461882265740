import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import PlayerBestScoresScoresListScoreComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Scores/Score";
import PlayerBestScoresScoresListHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Scores/Header";


import {
    PlayerBestScoresInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerBestScoresScoresListComponentProps {
    PlayerBestScores: PlayerBestScoresInterface | null;
}


export const PlayerBestScoresScoresListComponent: React.FC<PlayerBestScoresScoresListComponentProps> = ({
    PlayerBestScores
}) : JSX.Element | null => {


    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");


    if (!PlayerBestScores) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"margin-top-20 white"} />;
    }


    const ScoresList = Object.entries(PlayerBestScores).map(([statisticType, ScoreValue]) =>
        <PlayerBestScoresScoresListScoreComponent
            key={statisticType}
            // @ts-ignore
            statisticType={statisticType}
            PlayerScore={ScoreValue}
        />
    );



    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__best-scores__list"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__list__content"}>

                <PlayerBestScoresScoresListHeaderComponent />

                {ScoresList}

            </Row>
        </Col>
    );

};


export default PlayerBestScoresScoresListComponent;
