import React from "react";


import ContainerSectionComponent from "@ContainersElements/MainSection";


import PlayerInformationDescriptionComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/InformationData/Description";
import PlayerInformationStatisticsComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData/InformationData/Statistics";


import {
    PlayerPublicProfileFullDescriptionType
} from "@Interfaces/2.0";


export interface PlayerInformationDataComponentProps {
    PlayerDescriptionData: PlayerPublicProfileFullDescriptionType;
}


export const PlayerInformationDataComponent: React.FC<PlayerInformationDataComponentProps> = ({
    PlayerDescriptionData
}) : JSX.Element | null => {


    if (!PlayerDescriptionData) {
        return null;
    }


    const {
        player_id: playerID
    } = PlayerDescriptionData;


    return (
        <ContainerSectionComponent id={"player-information-data-component"} classes={"player-information-data-component player-information-data"}>

            <PlayerInformationStatisticsComponent
                playerID={playerID}
            />

            <PlayerInformationDescriptionComponent
                PlayerDescriptionData={PlayerDescriptionData}
            />

        </ContainerSectionComponent>
        );

};


export default PlayerInformationDataComponent;
