import {
    StatisticsTypesNamesInterface
} from "@Services/Consts";


export const StatisticsTypesNames: StatisticsTypesNamesInterface = {
    PL: {
        league_game_team_player_seconds: { mainPhraseSingular: "Czas Gry", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_pts: { mainPhraseSingular: "Punkty", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pt: { mainPhraseSingular: "Celny rzut za 2", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pta: { mainPhraseSingular: "Wykonywany rzut za 2", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pt_per: { mainPhraseSingular: "Skuteczność za 2", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pt: { mainPhraseSingular: "Celny rzut za 3", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pta: { mainPhraseSingular: "Wykonywany rzut za 3", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pt_per: { mainPhraseSingular: "Skuteczność za 3", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_acc: { mainPhraseSingular: "Celny rzut z gry", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_tot: { mainPhraseSingular: "Wykonywany rzut z gry", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_per: { mainPhraseSingular: "Skuteczność z gry", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ft: { mainPhraseSingular: "Celny rzut wolny", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_fta: { mainPhraseSingular: "Wykonywany rzut wolny", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ft_per: { mainPhraseSingular: "Skuteczność za 1", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_def: { mainPhraseSingular: "Zbiórki w ataku", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_off: { mainPhraseSingular: "Zbiórki w obronie", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_tot: { mainPhraseSingular: "Zbiórki", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ast: { mainPhraseSingular: "Asysty", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_blk: { mainPhraseSingular: "Bloki", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_stl: { mainPhraseSingular: "Przechwyty", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_to: { mainPhraseSingular: "Straty", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_foul: { mainPhraseSingular: "Faule", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_fouled: { mainPhraseSingular: "Faule wymuszone", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff_in: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff_out: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eval: { mainPhraseSingular: "Eval", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
    },
    EN: {
        league_game_team_player_seconds: { mainPhraseSingular: "Game time", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_pts: { mainPhraseSingular: "Points", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pt: { mainPhraseSingular: "2 Points Accurate", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pta: { mainPhraseSingular: "2 Points Attempts", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_2pt_per: { mainPhraseSingular: "2 Points Effectivity", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pt: { mainPhraseSingular: "3 Points Accurate", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pta: { mainPhraseSingular: "3 Points Attempts", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_3pt_per: { mainPhraseSingular: "3 Points Effectivity", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_acc: { mainPhraseSingular: "Game Throws Accurate", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_tot: { mainPhraseSingular: "Game Throws Attempts", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_throws_per: { mainPhraseSingular: "Game Throws Effectivity", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ft: { mainPhraseSingular: "1 Point Accurate", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_fta: { mainPhraseSingular: "1 Point Attempt", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ft_per: { mainPhraseSingular: "1 Point Effectivity", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_off: { mainPhraseSingular: "Offensive Rebounds", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_def: { mainPhraseSingular: "Defensive Rebounds", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_rebound_tot: { mainPhraseSingular: "Rebounds", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_ast: { mainPhraseSingular: "Assists", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_blk: { mainPhraseSingular: "Blocks", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_stl: { mainPhraseSingular: "Steals", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_to: { mainPhraseSingular: "Turnovers", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_foul: { mainPhraseSingular: "Fouls", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_fouled: { mainPhraseSingular: "Fouled", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff_in: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff_out: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eff: { mainPhraseSingular: "+/-", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
        league_game_team_player_eval: { mainPhraseSingular: "Eval", mainPhrasePlural: "", shortPhraseSingular: "", shortPhrasePlural: "", minPhraseSingular: "", minPhrasePlural: "", },
    }
};


export default StatisticsTypesNames;
