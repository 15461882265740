import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import PlayerBestScoresLeaguesListLeagueComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/BestScores/Header/LeaguesList/League";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerBestScoresLeaguesListComponentProps {
    Leagues: PlayerCareerStatisticsLeagueInterface[];
    selectedLeagueIDHandler: (leagueID: number) => void;
}

export const PlayerBestScoresLeaguesListComponent: React.FC<PlayerBestScoresLeaguesListComponentProps> = ({
    Leagues, selectedLeagueIDHandler
}) => {


    if (!Leagues?.length) {
        return null;
    }


    const LeaguesList = Leagues.map((League) =>
        <PlayerBestScoresLeaguesListLeagueComponent
            key={League.id}
            League={League}
            selectedLeagueIDHandler={selectedLeagueIDHandler}
        />
    );


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__best-scores__header__leagues__list"}>
            <Row className={"player-details__details-data__statistics-data__content__best-scores__header__leagues__list__content"}>
                {LeaguesList}
            </Row>
        </Col>
    );

};


export default PlayerBestScoresLeaguesListComponent;
