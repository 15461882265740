import React from "react";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import PlayerPlayedGamesLeagueGamesHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames/Games/Header";
import PlayerPlayedGamesLeagueGamesGameComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames/Games/Game";


import {
    PlayerCareerStatisticsLeagueInterface
} from "@Interfaces/2.0";


import "./index.scss";

export interface PlayerPlayedGamesLeagueGamesComponentProps {
    League: PlayerCareerStatisticsLeagueInterface | null;
}

export const PlayerPlayedGamesLeagueGamesComponent: React.FC<PlayerPlayedGamesLeagueGamesComponentProps> = ({
    League
}) => {


    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");
    const NO_GAMES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_GAMES_PHRASE_TEXT");


    if (!League) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"white bordered padding-10 margin-top-20"} />;
    }


    const {
        league_games: LeagueGames
    } = League;


    if (!LeagueGames?.length) {
        return <MessageComponent messageText={NO_GAMES_PHRASE_TEXT} cssClasses={"white bordered padding-10 margin-top-20"} />;
    }


    const LeagueGamesList = LeagueGames.map((Game) =>
        <PlayerPlayedGamesLeagueGamesGameComponent key={Game.id} LeagueGame={Game} />
    );


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__played-games__league-games"}>
            <Row className={"player-details__details-data__statistics-data__content__played-games__league-games__content"}>

                <PlayerPlayedGamesLeagueGamesHeaderComponent />

                {LeagueGamesList}

            </Row>
        </Col>
    );

};


export default PlayerPlayedGamesLeagueGamesComponent;
