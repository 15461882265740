import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";

import {
    AiOutlineCheck, FaTimes
} from "react-icons/all";

import NumberFormat from "react-number-format";

import dateFormat from "dateformat";


import {
    PlayerCareerStatisticsGameInterface
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerPlayedGamesLeagueGamesGameComponentProps {
    LeagueGame:  PlayerCareerStatisticsGameInterface;
}


export const PlayerPlayedGamesLeagueGamesGameComponent: React.FC<PlayerPlayedGamesLeagueGamesGameComponentProps> = ({
    LeagueGame
}) => {


    const GamesRoute = UseRoute("GAMES");
    const TeamsRoute = UseRoute("TEAMS");


    if (!LeagueGame) {
        return null;
    }


    const {
        id: leagueGameID,
        league_game_date: leagueGameDate,
        league_game_result: LeagueGameResult,
        league_game_player_statistics: LeagueGameStatistics
    } = LeagueGame;


    const leagueGameURL = "/" + GamesRoute.routeURL + "/" + leagueGameID;


    if (!LeagueGameResult || !LeagueGameStatistics) {
        return null;
    }


    const {
        league_game_player_opponent_team: LeagueGameOpponentTeam,
        league_game_player_player_team_result_type: leagueGameResultType,
        league_game_player_player_team_points: leagueGamePlayerTeamPoints,
        league_game_player_opponent_team_points: leagueGameOpponentTeamPoints,
    } = LeagueGameResult;


    if (!LeagueGameOpponentTeam) {
        return null;
    }


    const {
        team_league_team: OpponentTeamTeam,
        team_league_team_name: opponentTeamName,
    } = LeagueGameOpponentTeam;

    if (!OpponentTeamTeam) {
        return null;
    }

    const {
        team_name: opponentTeamMainName, team_slug: opponentTeamSlug
    } = OpponentTeamTeam;

    const leagueGameOpponentTeamURL = "/" + TeamsRoute.routeURL + "/" + opponentTeamSlug;
    const leagueGameOpponentTeamName = opponentTeamName || opponentTeamMainName;


    const {
        league_game_team_player_first_squad: playerStart5Status,
        league_game_team_player_seconds: playerFieldSeconds,
        league_game_team_player_pts: playerPoints,
        league_game_team_player_2pt: player2PointsAccThrows, league_game_team_player_2pta: player2PointsMadeThrows, league_game_team_player_2pt_per: player2PointsThrowsEffectivity,
        league_game_team_player_3pt: player3PointsAccThrows, league_game_team_player_3pta: player3PointsMadeThrows, league_game_team_player_3pt_per: player3PointsThrowsEffectivity,
        league_game_team_player_ft: player1PointsAccThrows, league_game_team_player_fta: player1PointsMadeThrows, league_game_team_player_ft_per: player1PointsThrowsEffectivity,
        league_game_team_player_throws_acc: playerGameAccThrows, league_game_team_player_throws_tot: playerGameMadeThrows, league_game_team_player_throws_per: playerGameThrowsEffectivity,
        league_game_team_player_rebound_def: playerDefensiveRebounds, league_game_team_player_rebound_off: playerOffensiveRebounds, league_game_team_player_rebound_tot: playerTotalRebounds,
        league_game_team_player_ast: playerAssists, league_game_team_player_stl: playerSteals, league_game_team_player_blk: playerBlocks,
        league_game_team_player_to: playerTurnovers, league_game_team_player_foul: playerFouls,
        league_game_team_player_eff: playerEffectivity, league_game_team_player_eval: playerEval,
    } = LeagueGameStatistics;


    let gameResultCssClass = "";

    if (leagueGamePlayerTeamPoints > leagueGameOpponentTeamPoints) {
        gameResultCssClass = "victory";
    } else if (leagueGamePlayerTeamPoints < leagueGameOpponentTeamPoints) {
        gameResultCssClass = "lost";
    } else if (leagueGamePlayerTeamPoints === leagueGameOpponentTeamPoints) {
        gameResultCssClass = "draw";
    }


    if (!playerFieldSeconds) {
        return null;
    }


    let playerMinutes = Math.floor(playerFieldSeconds / 60);
    let playerSeconds = Math.floor(playerFieldSeconds % 60);

    let playerPlayedMinutes = (playerMinutes < 10 ? "0" : "") + playerMinutes.toString();
    let playerPlayedSeconds = (playerSeconds < 10 ? "0" : "") + playerSeconds.toString();



    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__played-games__league-games__row player-details__details-data__statistics-data__content__played-games__league-games__game"}>
            <Row className={"player-details__details-data__statistics-data__content__played-games__league-games__row__content player-details__details-data__statistics-data__content__played-games__league-games__game__content"}>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-date"}>
                    <NavLink to={leagueGameURL} title={GamesRoute.routeTitle}>
                        <span>{dateFormat(leagueGameDate || "", "yyyy-mm-dd")}</span>
                    </NavLink>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-opponent"}>
                    <NavLink to={leagueGameOpponentTeamURL} title={TeamsRoute.routeTitle}>
                        <span>{leagueGameOpponentTeamName}</span>
                    </NavLink>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--game-result player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-result" + (gameResultCssClass ? ` ${gameResultCssClass}` : "")}>
                    <span className={"result-label"}>{leagueGameResultType}</span>
                    <span className={"result-value"}>{leagueGamePlayerTeamPoints} : {leagueGameOpponentTeamPoints}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--start-5 player-details__details-data__statistics-data__content__played-games__league-games__row__value--start-5" + (playerStart5Status ? " first-squad" : "")}>
                    {playerStart5Status ? <AiOutlineCheck /> : <FaTimes />}
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--minutes"}>
                    <span>{playerPlayedMinutes}:{playerPlayedSeconds}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--points"}>
                    <span>{playerPoints}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-2pt player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--throws-2pt"}>
                    <NumberFormat value={player2PointsAccThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player2PointsMadeThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player2PointsThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-3pt player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--throws-3pt"}>
                    <NumberFormat value={player3PointsAccThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player3PointsMadeThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player3PointsThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-game player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--throws-game"}>
                    <NumberFormat value={playerGameAccThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={playerGameMadeThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={playerGameThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-1pt player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--throws-1pt"}>
                    <NumberFormat value={player1PointsAccThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player1PointsMadeThrows} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={player1PointsThrowsEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--rebounds player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__game__value--rebounds"}>
                    <NumberFormat value={playerDefensiveRebounds} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={playerOffensiveRebounds} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    <span className={"separator"}></span>
                    <NumberFormat value={playerTotalRebounds} decimalScale={0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--assists"}>
                    <span>{playerAssists}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--steals"}>
                    <span>{playerSteals}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--blocks"}>
                    <span>{playerBlocks}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--turnovers"}>
                    <span>{playerTurnovers}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--fouls"}>
                    <span>{playerFouls}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--effectivity"}>
                    <span>{playerEffectivity}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__game__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--eval"}>
                    <span>{playerEval}</span>
                </Col>

            </Row>
        </Col>
    );


};


export default PlayerPlayedGamesLeagueGamesGameComponent;
