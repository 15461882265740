import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";



import {
    NavLink
} from "react-router-dom";


import {
    Col
} from "react-bootstrap";


import {
    TeamBasicInformationType
} from "@Interfaces/2.0";


export interface PlayerTeamComponentProps {
    PlayerTeam: TeamBasicInformationType | null;
}


export const PlayerTeamComponent: React.FC<PlayerTeamComponentProps> = ({
    PlayerTeam
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    if (!PlayerTeam) {
        return null;
    }


    const {
        team_slug: playerTeamSlug,
        team_logo: playerTeamLogo,
        team_name: playerTeamName,
    } = PlayerTeam;


    const playerTeamURL = "/" + TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <Col xs={12} lg={{ span: 2, offset: 1 }} className={"player-team-logo"}>
            {playerTeamSlug && playerTeamLogo &&
            <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle}>
                <img src={playerTeamLogo} alt={playerTeamName}/>
            </NavLink>
            }
        </Col>
    );

};


export default PlayerTeamComponent;
