import React, { useEffect, useState } from "react";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import TeamsAndPlayersSeasonsSelectedSeasonComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Seasons/SelectedSeason";
import TeamsAndPlayersSeasonsSeasonComponent from "@TeamsPages/List/Content/Data/SeasonsAndLeagues/Seasons/Season";


import {
    SeasonWithLeaguesShortInformationType
} from "@Interfaces/2.0";


import "./index.scss";



export interface TeamsAndPlayersSeasonsComponentProps {
    SeasonsWithLeagues: SeasonWithLeaguesShortInformationType[];
    SelectedSeason: SeasonWithLeaguesShortInformationType | null;
    selectedSeasonID: number;
    selectedSeasonIDHandler: (seasonID: number) => void;
}


export const TeamsAndPlayersSeasonsComponent: React.FC<TeamsAndPlayersSeasonsComponentProps> = ({
    SeasonsWithLeagues, SelectedSeason,
    selectedSeasonID, selectedSeasonIDHandler,
}) : JSX.Element | null => {


    const SEASON_WORD_TEXT = UseCommonDictionaryPhrasesSet("SEASON_WORD_TEXT");


    const SeasonsList = SeasonsWithLeagues.filter(
        (Season) => Season.id !== selectedSeasonID
    ).map(
        (Season) => <TeamsAndPlayersSeasonsSeasonComponent
            key={Season.id}
            Season={Season}
            selectedSeasonIDHandler={selectedSeasonIDHandler}
        />
    );


    const [seasonsListSwitcherActiveStatus, setSeasonsListSwitcherActiveStatus] = useState(false);

    useEffect(() => {
        return () => {
            setSeasonsListSwitcherActiveStatus(false);
        };
    }, []);

    const seasonsListSwitcherActiveStatusHandler = () => {
        setSeasonsListSwitcherActiveStatus(!seasonsListSwitcherActiveStatus);
    };

    useEffect(() => {

        const seasonsListStatusObserver = (e: MouseEvent) => {

            let targetParent = (e.target as Element).parentElement;

            if (targetParent?.tagName === "svg") {
                targetParent = targetParent.parentElement;
            }

            if (targetParent && targetParent.className.includes("selected-season")) {
                return;
            } else {
                setSeasonsListSwitcherActiveStatus(false);
            }
        }

        document.addEventListener("click", seasonsListStatusObserver);

        return () => {
            document.removeEventListener("click", seasonsListStatusObserver);
        };

    }, []);


    return (
        <Col xs={12} className={"teams-and-players__content__seasons-and-leagues__seasons"}>
            <Row className={"teams-and-players__content__seasons-and-leagues__seasons__content"}>

                <Col xs={3} lg={1} className={"teams-and-players__content__seasons-and-leagues__seasons__header"}>
                    <span>{SEASON_WORD_TEXT}:</span>
                </Col>

                <Col xs={9} lg={"auto"} className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list"}>
                    <Row className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__content"}>

                        <TeamsAndPlayersSeasonsSelectedSeasonComponent
                            Season={SelectedSeason}
                            seasonsListSwitcherActiveStatusHandler={seasonsListSwitcherActiveStatusHandler}
                            seasonsNumber={SeasonsWithLeagues.length}
                        />

                        {SeasonsList.length && seasonsListSwitcherActiveStatus &&
                        <Col xs={12} className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__seasons"}>
                            <Row className={"teams-and-players__content__seasons-and-leagues__seasons__seasons-list__seasons__content"}>
                                {SeasonsList}
                            </Row>
                        </Col>
                        }

                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersSeasonsComponent;
