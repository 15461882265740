import React, { useEffect, useRef } from "react";


import {
    Redirect
} from "react-router-dom";


import {
    Container
} from "react-bootstrap";


import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import PlayersDetailsDataComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData";


import scrollToDomElement from "@Utilities/Scrolling";


type PlayerDetailsProps = {
    match: any;
};


import "./index.scss";
import UsePlayerIdBySlug from "@Hooks/Api/Requests/Games/Players/Player/PublicProfile/PlayerIdBySlug";
import {UseCommonDictionaryPhrasesSet} from "@Hooks/Texts";
import PlayerDescriptionComponent from "@Components/Views/Pages/Games/Players/Player/DescriptionData";


export const PlayerDetailsComponent: React.FC<PlayerDetailsProps> = ({
    match
}) : JSX.Element | null => {


    const PLAYER_SLUG = match?.params?.playerSlug;


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef.current) {
            return;
        }
        scrollToDomElement(ComponentRef.current, 250);
    }, [PLAYER_SLUG]);


    const [playerID, playerIDError] = UsePlayerIdBySlug(PLAYER_SLUG);


    if (!PLAYER_SLUG) {
        return <Redirect to={"/"} />;
    }

    if (playerIDError) {
        if (playerIDError.errorCode === 404) {
            return <Redirect to={"/"} />;
        } else {
            return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
        }
    }

    if (!playerID) {
        return null;
    }


    return (
        <Container ref={ComponentRef} fluid={true} id={"players-details-page"} className={"player-page"}>

            <PlayerDescriptionComponent
                playerID={playerID}
            />

            <PlayersDetailsDataComponent
                playerID={playerID}
            />

        </Container>
    );

};


export default PlayerDetailsComponent;
