import React from "react";


import {
    PageContainerComponent
} from "@ContentElements";


import PlayersDetailsStatisticsDataComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData";


import "./index.scss";

interface PlayersDetailsDataComponentProps {
    playerID: number;
}


export const PlayersDetailsDataComponent: React.FC<PlayersDetailsDataComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    if (!playerID) {
        return null;
    }


    return (
        <PageContainerComponent cssClasses={"player-details__details-data"}>

            <PlayersDetailsStatisticsDataComponent
                playerID={playerID}
            />

        </PageContainerComponent>
    );


};


export default PlayersDetailsDataComponent;
