import React, { useEffect, useState } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import PlayerPlayedGamesHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames/Header";
import PlayerPlayedGamesLeagueGamesComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/StatisticsData/StatisticsContent/PlayedGames/Games";


import {
    PlayerCareerStatisticsSeasonInterface,
    PlayerCareerStatisticsLeagueInterface,
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayerDetailsStatisticsPlayedGamesComponentProps {
    PlayerCareerStatistics: PlayerCareerStatisticsSeasonInterface[];
}


export const PlayerDetailsStatisticsPlayedGamesComponent: React.FC<PlayerDetailsStatisticsPlayedGamesComponentProps> = ({
    PlayerCareerStatistics
}) : JSX.Element | null => {


    const NO_GAMES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_GAMES_PHRASE_TEXT");
    const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT");


    const [selectedLeagueID, setSelectedLeagueID] = useState(0);
    const [SelectedLeague, setSelectedLeague] = useState<PlayerCareerStatisticsLeagueInterface | null>(null);

    useEffect(() => {

        if (!PlayerCareerStatistics?.length) {
            return;
        }

        const DefaultSeason = PlayerCareerStatistics[0];

        if (!DefaultSeason) {
            return;
        }

        const { league_season_leagues: DefaultSeasonLeagues } = DefaultSeason;

        if (!DefaultSeasonLeagues?.length) {
            return;
        }

        const DefaultLeague = DefaultSeasonLeagues[0];

        if (!DefaultLeague) {
            return;
        }

        setSelectedLeagueID(DefaultLeague.id);

    }, []);

    useEffect(() => {

        if (!selectedLeagueID || !PlayerCareerStatistics?.length) {
            return setSelectedLeague(null);
        }

        PlayerCareerStatistics.forEach((Season) => {
            const { league_season_leagues: SeasonLeagues } = Season;
            SeasonLeagues.forEach((League) => {
                if (League.id === selectedLeagueID) {
                    setSelectedLeague(League);
                }
            });
        });

    }, [selectedLeagueID]);

    useEffect(() => {
        return () => {
            setSelectedLeagueID(0);
            setSelectedLeague(null);
        };
    }, []);


    if (!PlayerCareerStatistics) {
        return <MessageComponent messageText={DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT} cssClasses={"white"} />;
    }


    if (!PlayerCareerStatistics.length) {
        return <MessageComponent messageText={NO_GAMES_PHRASE_TEXT} cssClasses={"white"} />;
    }


    const LeaguesList: PlayerCareerStatisticsLeagueInterface[] = [];

    for (let Season of PlayerCareerStatistics) {
        const { league_season_leagues: SeasonLeagues } = Season;
        if (!SeasonLeagues) {
            continue;
        }
        LeaguesList.push(...SeasonLeagues);
    }


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__played-games"}>
            <Row className={"player-details__details-data__statistics-data__content__played-games__content"}>

                <PlayerPlayedGamesHeaderComponent
                    Leagues={LeaguesList}
                    SelectedLeague={SelectedLeague}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={setSelectedLeagueID}
                />

                <PlayerPlayedGamesLeagueGamesComponent
                    League={SelectedLeague}
                />

            </Row>
        </Col>
    );

};


export default PlayerDetailsStatisticsPlayedGamesComponent;
