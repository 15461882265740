import React from "react";


import {UseCommonDictionaryPhrasesSet} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";

export interface PlayerPlayedGamesLeagueGamesHeaderComponentProps {}

export const PlayerPlayedGamesLeagueGamesHeaderComponent: React.FC<PlayerPlayedGamesLeagueGamesHeaderComponentProps> = ({}) : JSX.Element | null => {


    const DATE_WORD_TEXT = UseCommonDictionaryPhrasesSet("DATE_WORD_TEXT");
    const AGAINST_WORD_TEXT = UseCommonDictionaryPhrasesSet("AGAINST_WORD_TEXT");
    const RESULT_WORD_TEXT = UseCommonDictionaryPhrasesSet("RESULT_WORD_TEXT");

    const STATISTICS_START_5_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_START_5_PHRASE_SHORT_TEXT");
    const STATISTICS_MINUTES_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MINUTES_WORD_SHORT_TEXT");
    const STATISTICS_POINTS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_POINTS_SHORT_TEXT");

    const STATISTICS_2PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_2PT_THROWS_PHRASE_TEXT");
    const STATISTICS_3PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_3PT_THROWS_PHRASE_TEXT");
    const STATISTICS_1PT_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_1PT_THROWS_PHRASE_TEXT");
    const STATISTICS_GAME_THROWS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_GAME_THROWS_PHRASE_TEXT");
    const STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT = UseCommonDictionaryPhrasesSet("STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT");
    const STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT");

    const STATISTICS_REBOUNDS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_REBOUNDS_SHORT_TEXT");
    const STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT");
    const STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT");
    const STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT");

    const STATISTICS_ASSISTS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_ASSISTS_SHORT_TEXT");
    const STATISTICS_STEALS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_STEALS_SHORT_TEXT");
    const STATISTICS_BLOCKS_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_BLOCKS_SHORT_TEXT");
    const STATISTICS_TURNOVERS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_TURNOVERS_WORD_SHORT_TEXT");
    const STATISTICS_FOULS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_FOULS_WORD_SHORT_TEXT");
    const STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT");
    const STATISTICS_EVAL_TEXT = UseCommonDictionaryPhrasesSet("STATISTICS_EVAL_TEXT");


    return (
        <Col xs={12} className={"player-details__details-data__statistics-data__content__played-games__league-games__row player-details__details-data__statistics-data__content__played-games__league-games__header"}>
            <Row className={"player-details__details-data__statistics-data__content__played-games__league-games__row__content player-details__details-data__statistics-data__content__played-games__league-games__header__content"}>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-date"}>
                    <span>{DATE_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-opponent player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__header__value--game-opponent"}>
                    <span>{AGAINST_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--game-result"}>
                    <span>{RESULT_WORD_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--start-5"}>
                    <span>{STATISTICS_START_5_PHRASE_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--minutes"}>
                    <span>{STATISTICS_MINUTES_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--points"}>
                    <span>{STATISTICS_POINTS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-2pt"}>
                    <span className={"statistic-label"}>{STATISTICS_2PT_THROWS_PHRASE_TEXT}</span>
                    <span className={"statistic-sub-label"}>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>%</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-3pt"}>
                    <span className={"statistic-label"}>{STATISTICS_3PT_THROWS_PHRASE_TEXT}</span>
                    <span className={"statistic-sub-label"}>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>%</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-game"}>
                    <span className={"statistic-label"}>{STATISTICS_GAME_THROWS_PHRASE_TEXT}</span>
                    <span className={"statistic-sub-label"}>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>%</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--throws-1pt"}>
                    <span className={"statistic-label"}>{STATISTICS_1PT_THROWS_PHRASE_TEXT}</span>
                    <span className={"statistic-sub-label"}>{STATISTICS_ACCURATE_THROWS_PHRASE_TEXT_SHORT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_MADE_THROWS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>%</span>
                </Col>

                <Col xs={"auto"}
                     className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--rebounds"}>
                    <span className={"statistic-label"}>{STATISTICS_REBOUNDS_SHORT_TEXT}</span>
                    <span className={"statistic-sub-label"}>{STATISTICS_DEFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_OFFENSIVE_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                    <span className={"separator"}></span>
                    <span className={"statistic-sub-label"}>{STATISTICS_TOTAL_REBOUNDS_PHRASE_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"}
                     className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--assists"}>
                    <span>{STATISTICS_ASSISTS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--steals"}>
                    <span>{STATISTICS_STEALS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--blocks"}>
                    <span>{STATISTICS_BLOCKS_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--turnovers"}>
                    <span>{STATISTICS_TURNOVERS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--fouls"}>
                    <span>{STATISTICS_FOULS_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--effectivity"}>
                    <span>{STATISTICS_EFFECTIVITY_WORD_SHORT_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"player-details__details-data__statistics-data__content__played-games__league-games__row__value player-details__details-data__statistics-data__content__played-games__league-games__header__value player-details__details-data__statistics-data__content__played-games__league-games__row__value--eval"}>
                    <span>{STATISTICS_EVAL_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default PlayerPlayedGamesLeagueGamesHeaderComponent;
