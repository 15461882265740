import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col
} from "react-bootstrap";


import {
    PlayerPublicBasicInformationType
} from "@Interfaces/2.0";


import "./index.scss";


export interface PlayersListLetterPlayersPlayerComponentProps {
    Player: PlayerPublicBasicInformationType;
}


export const PlayersListLetterPlayersPlayerComponent: React.FC<PlayersListLetterPlayersPlayerComponentProps> = ({
    Player
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }


    const {
        player_name: playerName, player_slug: playerSlug
    } = Player;

    const playerURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;


    return (
        <Col xs={12} className={"teams-and-players__content__teams-and-players__players__list__letter__players__player"}>
            <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                <span>{playerName}</span>
            </NavLink>
        </Col>
    );

};


export default PlayersListLetterPlayersPlayerComponent;
