import React from "react";

import {useDispatch} from "react-redux";
import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import ApplicationActionTypes from "@Action-Types/Application";


interface HeaderNavigationLinkComponentProps {
    linkData: any;
    listDisplayHandler: any;
}


export const HeaderNavigationLinkComponent: React.FC<HeaderNavigationLinkComponentProps> = ({
    linkData, listDisplayHandler
}) : JSX.Element | null => {


    const dispatcher = useDispatch();


    const {activeLanguage} = useTypedSelector((state) => state.loc);


    const {
        page_url: pageURL, page_title: pageTitle, page_css: pageCSS
    } = linkData;


    const pageLinkURL = pageURL[activeLanguage];
    const pageLinkTitle = pageTitle[activeLanguage];

    const outsideLink = pageLinkURL.includes("http");

    const pageClickHandler = () => {
        listDisplayHandler(false);
        dispatcher({
            type: ApplicationActionTypes.GLOBAL_BLACKOUT_STATUS_ACTION,
            status: false
        });
    };


    return (
        <Col as={"li"} className={"navigation-list-item" + (pageCSS ? ` ${pageCSS}` : "")}>

            {outsideLink ?

                <a href={pageLinkURL} title={pageLinkTitle} rel={"noreferrer noopener"} target={"_blank"}  className={"navigation-list-link"}>
                    <span>{pageLinkTitle}</span>
                </a>

                :

                <NavLink to={`/${pageLinkURL}`} title={pageLinkTitle} onClick={pageClickHandler} className={"navigation-list-link"}>
                    <span>{pageLinkTitle}</span>
                </NavLink>
            }


        </Col>
    );

};


export default HeaderNavigationLinkComponent;
